import React, { useEffect, useState } from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, ReactDataTable } from '../../../../components/Component'

import Content from '../../../../layout/content/Content'
import Head from '../../../../layout/head/Head'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getSingleUserDeliveryCollectionLogs, } from '../../../../redux/admin/report/action'
import { useParams } from 'react-router'



const SingleUserCollectionLog = (param) => {

    const { id } = useParams();
    const dispatch = useDispatch();

    // get data from redux state
    const getSelectors = (state) => ({
        logs: state.reports.single_user_logs,
    });

    const { logs } = useSelector(getSelectors);

    const [delivery_logs, setDeliveryLogs] = useState({})


    // get all delivery collection log  onload
    useEffect(() => {
        dispatch(getSingleUserDeliveryCollectionLogs(id)).then((response) => (
            setDeliveryLogs(response.payload)

        ))
    }, [])


    //headers of table
    const UserHeading = [
        {
            name: "Name",
            selector: (row) => row?.user?.name,
            sortable: true,
            hide: 370,
        },
        {
            name: "Email",
            selector: (row) => row?.user?.email,
            sortable: true,
            hide: 370,
        },
        {
            name: "Phone",
            selector: (row) => row?.user?.phone,
            sortable: true,
            hide: 370,
        },
        {
            name: "Amount",
            selector: (row) => row?.amount,
            sortable: true,
            hide: "sm",

        },
        {
            name: "Message",
            selector: (row) => row?.message,
            sortable: true,
            hide: "sm",

        },
        {
            name: "Date",
            selector: (row) => moment(row.created_at).format('DD-MM-yyyy'),
            sortable: true,
            hide: "sm",

        },

    ];

    return (
        <React.Fragment>
            <Head title="Delivery Collection Logs"></Head>

            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Delivery Collection Logs
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {delivery_logs?.count} Delivery Collection Logs .</p>


                            </BlockDes>
                        </BlockHeadContent>

                    </BlockBetween>
                </BlockHead>


                {/* datatable */}

                <ReactDataTable
                    data={delivery_logs?.logs}
                    columns={UserHeading}

                    pagination
                />

            </Content>

        </React.Fragment>
    )
}

export default SingleUserCollectionLog
