import React, { useEffect, useMemo, useState } from 'react'
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, Row, UncontrolledDropdown } from 'reactstrap'
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, ReactDataTable, RSelect } from '../../../../components/Component'
import { WEBSITE_API_URL } from '../../../../config'
import ToastExample from '../../../../helpers/toast'
import Content from '../../../../layout/content/Content'
import Head from '../../../../layout/head/Head'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getAllDeliveryCollectionLogs, } from '../../../../redux/admin/report/action'
import { nonActiveFormSubmissionStatus } from '../../../../redux/common/actions'
import DatePicker from "react-datepicker";
import subDays from "date-fns/subDays";
import { Controller, useForm } from 'react-hook-form'
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'

const DeliveryCollectionLogs = () => {

    const dispatch = useDispatch();

    // get data from redux state
    const getSelectors = (state) => ({
        logs: state.reports.collection_logs,
    });
    const { logs } = useSelector(getSelectors);
    const [modal, setModal] = useState(false);
    const [currentCollection, setCurrentCollection] = useState([]);
    const [cityId, setCityId] = useState("All");
    const [error, setError] = useState("");
    const [startDate, setStartDate] = useState(subDays(new Date(), 30));
    const [endDate, setEndDate] = useState(new Date());
    const formatedStart = moment(startDate).format('DD-MM-yyyy');
    const formatedEnd = moment(endDate).format('DD-MM-yyyy');

    // get all delivery collection log  onload
    useEffect(() => {
        dispatch(getAllDeliveryCollectionLogs(cityId, formatedStart, formatedEnd));
    }, [])

    // delivery collection log modal
    const openOrderModal = (delivery_guy) => {
        setCurrentCollection(delivery_guy)
        dispatch(nonActiveFormSubmissionStatus());
        setTimeout(() => {
            setModal(true);
        }, 100);
    };
    // on date change
    const handleDatePicker = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    // filter data according to date picker

    const filterPayout = () => {
        if (cityId) {
            setTimeout(() => {
                dispatch(getAllDeliveryCollectionLogs(cityId, formatedStart, formatedEnd));
            }, 100);
        } else {
            setError("City is Required");
        }
    }

    // export delivery collection report
    const onExport = () => {
        const token = localStorage.getItem('accessToken');
        if (cityId) {
            window.location = WEBSITE_API_URL + "/delivery_collection_log/export-log?token=" + token + "&from=" + formatedStart + "&to=" + formatedEnd + "&city=" + cityId;
        } else {
            setError("City is Required");
        }
    }

    //headers of table
    const UserHeading = [
        {
            name: "Name",
            selector: (row) => row?.user?.name,
            sortable: true,
            hide: 370,
        },
        {
            name: "Email",
            selector: (row) => row?.user?.email,
            sortable: true,
            hide: 370,
        },
        {
            name: "Phone",
            selector: (row) => row?.user?.phone,
            sortable: true,
            hide: 370,
        },
        {
            name: "Amount",
            selector: (row) => row?.amount,
            sortable: true,
            hide: "sm",

        },
        {
            name: "Message",
            selector: (row) => row?.message,
            sortable: true,
            hide: "sm",

        },
        {
            name: "Date",
            selector: (row) => moment(row.created_at).format('hh:mm A - DD/MM/yyyy'),
            sortable: true,
            hide: "sm",

        },
        // {
        //     name: "Actions",
        //     compact: true,
        //     grow: 1,
        //     style: { paddingRight: "20px" },
        //     cell: (row) => (
        //         <div className="user-card mt-2 mb-2">
        //             <DropdownTrans row={row} />

        //         </div>

        //     ),
        //     sortable: false,
        // },
    ];

    const DropdownTrans = ({ row }) => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu right>
                    <ul className="link-list-plain">
                        <li>
                            <DropdownItem tag="a" onClick={(e) => { openOrderModal(row) }}>
                                <Icon name="eye-fill" />
                                Delivery Collection
                            </DropdownItem>
                        </li>
                    </ul>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    const listCity = logs?.cities?.map((city) => ({ value: city.id, label: city.name }));

    const exportSchema = yup.object({
        status: yup.string().required(),
        city_id: yup.string().required(),
    });

    // schema to edit form data
    const defaultValues = useMemo(() => ({
        status: "",
        city_id: "",
    }), []);

    const methods = useForm({
        resolver: yupResolver(exportSchema),
        defaultValues: defaultValues,
    });

    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
        unregister,
        reset,
    } = methods;

    return (
        <React.Fragment>
            <Head title="Delivery Collection Logs"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Delivery Collection Logs
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {logs?.count} Delivery Collection Logs .</p>
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    <Row className='mb-3'>
                        <Col md="4">
                            <span className="form-label">Choose Date Range</span>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDatePicker}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                className="form-control date-picker"
                                required
                            />
                        </Col>
                        <Col md="4">
                            <span className="form-label">Select a City</span>
                            <Controller
                                name="city_id"
                                control={control}
                                render={({ onChange, value, ref }) => (
                                    <RSelect
                                        options={listCity}
                                        onChange={(val) => { setCityId(val?.value); onChange(val?.value); }}
                                        defaultValue={listCity?.find((c) => c?.value === cityId)}
                                    />
                                )}
                                ref={register({ required: "This field is required" })}
                            />
                            {!cityId && (
                                <span className="invalid text-danger text-italic">{error}</span>
                            )}
                        </Col>
                        <Col md="4" className='d-flex justify-content-end align-items-end mt-2'>
                            <Button outline color="success" className='mr-3' onClick={() => { filterPayout() }}>
                                <span>Filter</span>
                            </Button>
                            <Button outline color="primary" className='' onClick={() => { onExport() }}>
                                <Icon name="download-cloud"></Icon>
                                <span>Export</span>
                            </Button>
                        </Col>
                    </Row>

                </Block>


                {/* datatable */}

                <ReactDataTable
                    data={logs?.logs}
                    columns={UserHeading}

                    pagination
                />

            </Content>


            {/* view collection release modal */}
            {/* <Modal
                isOpen={modal}
                toggle={() => setModal(false)}
                className="modal-dialog-centered"
                size="lg"
            >
                <ModalBody className="pt-3 pb-2">
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false)
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className='p-2'>



                    </div>

                </ModalBody>
            </Modal> */}
        </React.Fragment>
    )
}

export default DeliveryCollectionLogs
