import { combineReducers } from "redux";
import commonReducer from "../redux/common/reducers";
import cityReducer from "../redux/admin/cities/reducers";
import warehouseReducer from "./admin/warehouse/reducer";
import itemReducer from "../redux/admin/item/reducer";
import inventoryReducer from "../redux/admin/inventory/reducer";
import addonReducer from "../redux/admin/addon/reducer";
import userReducer from "../redux/admin/user/reducer";
import promotionReducer from "../redux/admin/promotion/reducer";
import orderReducer from "../redux/admin/order/reducer";
import permissionReducer from "../redux/admin/permission/reducer";
import reportReducer from "../redux/admin/report/reducer";
import dashboardReducer from '../redux/admin/dashboard/reducer';

export default combineReducers({
    cities: cityReducer,
    common: commonReducer,
    warehouse: warehouseReducer,
    inventory: inventoryReducer,
    item: itemReducer,
    addons: addonReducer,
    users: userReducer,
    order: orderReducer,
    promotion: promotionReducer,
    permissions: permissionReducer,
    reports: reportReducer,
    dashboard: dashboardReducer,
});
