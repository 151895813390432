import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { BackTo, Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Button, Col, Icon, RSelect, ReactDataTable, Row } from '../../../components/Component';
import Content from '../../../layout/content/Content';
import Head from '../../../layout/head/Head';
import { getLiveOrders } from '../../../redux/admin/order/action';
import { getAllCities } from '../../../redux/admin/cities/actions';
import { Badge, Card, CardTitle, FormGroup, Modal, ModalBody, ModalHeader } from 'reactstrap';

const LiveOrders = () => {
    const dispatch = useDispatch(); //defining dispatch

    const [cityId, setCityId] = useState('All');  //isMultiple state defined
    const [scheduledOrder, setScheduledOrder] = useState(false);
    const [completedOrder, setCompletedOrder] = useState(false);
    const [completedScheduledOrder, setCompletedScheduledOrder] = useState(false);
    const [cancelledOrder, setCancelledOrder] = useState(false);
    const [cancelledScheduledOrder, setCancelledScheduledOrder] = useState(false);

    //function to get data from backend
    
    useEffect(() => {
        const new_data = new FormData();
        new_data.append('token', localStorage.getItem('accessToken'));
        new_data.append('city_id', cityId);
        dispatch(getLiveOrders(new_data));
        dispatch(getAllCities());
    }, [cityId]);
    const MINUTE_MS = 10000;
    useEffect(() => {
        const interval = setInterval(() => {
        const new_data = new FormData();
        new_data.append('token', localStorage.getItem('accessToken'));
        new_data.append('city_id', 'All');
        dispatch(getLiveOrders(new_data));
        dispatch(getAllCities());
    }, MINUTE_MS);
    return () => clearInterval(interval);
    }, []);

    const getSelectors = (state) => ({
        live_orders: state.order.live_orders,
        cities: state.cities.cities,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,

    });
    const { live_orders, cities, form_loading, form_submission_status, } = useSelector(getSelectors);
    const listCities = cities?.map((city) => (
        { value: city.id, label: city.name }
    ));
    listCities.unshift({ value: 'All', label: 'ALL' });
    const formatCreatedAt = (createdAt) => {
        const date = new Date(createdAt);
        const options = { year: '2-digit', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate;
    };
    const newOrders = live_orders?.newOrders ? Object.values(live_orders?.newOrders) : null;
    const acceptedOrders = live_orders?.acceptedOrders ? Object.values(live_orders?.acceptedOrders) : null;
    const ongoingOrders = live_orders?.ongoingOrders ? Object.values(live_orders?.ongoingOrders) : null;
    const newScheduledOrders = live_orders?.newScheduledOrders ? Object.values(live_orders?.newScheduledOrders) : null;
    const acceptedScheduledOrders = live_orders?.acceptedScheduledOrders ? Object.values(live_orders?.acceptedScheduledOrders) : null;
    const ongoingScheduledOrders = live_orders?.ongoingScheduledOrders ? Object.values(live_orders?.ongoingScheduledOrders) : null;

    return (
        <React.Fragment>
            <Head title="Live Orders"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Live Orders
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <BlockHead size="sm">
                    <BlockHeadContent>
                        <Col md="6">
                            <div className='d-flex align-items-center'>
                                <div style={{ margin: "0 5px 0 0" }}>Select City :</div>
                                <div className='flex-grow-1'>
                                    <RSelect
                                        options={listCities}
                                        onChange={(val) => {
                                            setCityId(val.value)
                                        }}
                                        defaultValue={listCities.find((city) => city.value === 'All')}
                                    />
                                </div>
                            </div>
                        </Col>
                    </BlockHeadContent>
                </BlockHead>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            {scheduledOrder ?
                                <h5>  Scheduled Orders</h5>
                                :
                                <h5>  Instant Orders</h5>
                            }
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <div className="toggle-expand-content">
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <Button color="success" className="btn btn-md btn-success" onClick={scheduledOrder ? () => setCompletedScheduledOrder(true) : () => setCompletedOrder(true)}>
                                                Completed Orders
                                            </Button>
                                        </li>
                                        <li>
                                            <Button color="danger" className="btn btn-md btn-danger" onClick={scheduledOrder ? () => setCancelledScheduledOrder(true) : () => setCancelledOrder(true)}>
                                                Cancelled Orders
                                            </Button>
                                        </li>
                                        <li>
                                            <Button color="dark" className="btn btn-md btn-dark" onClick={() => setScheduledOrder(!scheduledOrder)}>
                                                {scheduledOrder ?
                                                    <div style={{ margin: '0 5px 0 0' }}>Instant Orders <span className="badge badge-outline-dark badge-xs text-white">{live_orders.instant_count}</span></div>
                                                    :
                                                    <div style={{ margin: '0 5px 0 0' }}>Scheduled Orders <span className="badge badge-outline-dark badge-xs text-white">{live_orders.schedule_count}</span></div>
                                                }
                                            </Button>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            {/* {scheduledOrder ?
                                <h5>  Scheduled Orders</h5>
                                :
                                <h5>  Instant Orders</h5>
                            } */}
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <div className="toggle-expand-content">
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <Button color="warning" className="btn btn-md btn-warning"
                                            // onClick={scheduledOrder ? () => setCompletedScheduledOrder(true) : () => setCompletedOrder(true)}
                                            >
                                                <div style={{ margin: '0 5px 0 0' }}>   Cancellation Requests <span className="badge badge-outline-dark badge-xs text-white">{live_orders.pendingTransactionsCount}</span> </div>
                                            </Button>
                                        </li>
                                        <li>
                                            <Button color="info" className="btn btn-md btn-info"
                                            // onClick={scheduledOrder ? () => setCompletedScheduledOrder(true) : () => setCompletedOrder(true)}
                                            >
                                                <div style={{ margin: '0 5px 0 0' }}>   Pending Transactions <span className="badge badge-outline-dark badge-xs text-white">{live_orders.pendingTransactionsCount}</span> </div>
                                            </Button>
                                        </li>
                                        <li>
                                            <Button color="danger" className="btn btn-md btn-danger"
                                            // onClick={scheduledOrder ? () => setCompletedScheduledOrder(true) : () => setCompletedOrder(true)}
                                            >
                                                <div style={{ margin: '0 5px 0 0' }}>   Failed Transactions <span className="badge badge-outline-dark badge-xs text-white">{live_orders.failedTransactionsCount}</span> </div>
                                            </Button>
                                        </li>
                                        {/* <li>
                                            <Button color="success" className="btn btn-md btn-success" onClick={scheduledOrder ? () => setCompletedScheduledOrder(true) : () => setCompletedOrder(true)}>
                                                <div style={{ margin: '0 5px 0 0' }}>   Completed Orders <span className="badge badge-outline-dark badge-xs text-white">{live_orders.completedOrdersCount}</span> </div>
                                            </Button>
                                        </li>
                                        <li>
                                            <Button color="danger" className="btn btn-md btn-danger" onClick={scheduledOrder ? () => setCancelledScheduledOrder(true) : () => setCancelledOrder(true)}>
                                                <div style={{ margin: '0 5px 0 0' }}>   Cancelled Orders <span className="badge badge-outline-dark badge-xs text-white">{live_orders.cancelledOrdersCount}</span> </div>
                                            </Button>
                                        </li>
                                        <li>
                                            <Button color="dark" className="btn btn-md btn-dark" onClick={() => setScheduledOrder(!scheduledOrder)}>
                                                {scheduledOrder ?
                                                    <div style={{ margin: '0 5px 0 0' }}>Instant Orders <span className="badge badge-outline-dark badge-xs text-white">{live_orders.instant_count}</span></div>
                                                    :
                                                    <div style={{ margin: '0 5px 0 0' }}>Scheduled Orders <span className="badge badge-outline-dark badge-xs text-white">{live_orders.schedule_count}</span></div>
                                                }
                                            </Button>
                                        </li> */}
                                        {/* <li>
                                            <Button color="success" className="btn btn-md btn-success" onClick={scheduledOrder ? () => setCompletedScheduledOrder(true) : () => setCompletedOrder(true)}>
                                                <div style={{ margin: '0 5px 0 0' }}>   Cancelled Orders <span className="badge badge-outline-dark badge-xs text-white">{live_orders.cancelledOrdersCount}</span> </div>
                                            </Button>
                                        </li> */}
                                        <li>
                                            <Button color="gray" className="btn btn-md btn-gray"
                                            // onClick={scheduledOrder ? () => setCompletedScheduledOrder(true) : () => setCompletedOrder(true)}
                                            >
                                                Late Orders
                                            </Button>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                {scheduledOrder ?
                    <Row>
                        <Col lg="4" xxl="4">
                            <Card>
                                <div className="card-inner border-bottom">
                                    <div className="card-title-group">
                                        <CardTitle>
                                            <h6 className="title">New Orders</h6>
                                        </CardTitle>
                                    </div>
                                </div>
                                <ul className="nk-support">
                                    {live_orders && newScheduledOrders && newScheduledOrders?.length > 0 ? (
                                        newScheduledOrders?.map((order, index) => (
                                            <li className="nk-support-item" >
                                                <div className="nk-support-content">
                                                    <div className="title">
                                                        <span>{order?.user?.name}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span style={{ fontWeight: "700" }}>#{order?.unique_order_id}</span>
                                                        <Badge color="secondary"  >
                                                            {order?.order_status?.name}
                                                        </Badge>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span>₹ {order?.total}</span>
                                                        <span>{order?.warehouse?.name}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <Badge color={order?.is_schedule ? "info" : "info"}  >
                                                            {order?.is_schedule ? "Scheduled" : "Instant"}
                                                        </Badge>
                                                        <span>{moment.utc(order?.created_at).fromNow()}</span>
                                                    </div>
                                                    <div className="d-flex flex-row-reverse">
                                                        <a href={`view-order/${order.id}`} class="btn btn-info">View</a>
                                                    </div>
                                                </div>
                                            </li>
                                        ))) : (
                                        <div className='text-center py-4'></div>
                                    )}
                                </ul>
                            </Card>
                        </Col>
                        <Col lg="4" xxl="4">
                            <Card>
                                <div className="card-inner border-bottom">
                                    <div className="card-title-group">
                                        <CardTitle>
                                            <h6 className="title">Accepted Orders</h6>
                                        </CardTitle>
                                    </div>
                                </div>
                                <ul className="nk-support">
                                    {live_orders && acceptedScheduledOrders && acceptedScheduledOrders.length > 0 ?
                                        acceptedScheduledOrders.map((order, index) => (
                                            <li className="nk-support-item" >
                                                <div className="nk-support-content">
                                                    <div className="title">
                                                        <span>{order?.user?.name}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span style={{ fontWeight: "600" }}>#{order?.unique_order_id}</span>
                                                        <Badge color="warning"  >
                                                            {order?.order_status?.name}
                                                        </Badge>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span>₹ {order?.total}</span>
                                                        <span>{order?.warehouse?.name}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <Badge color={order?.is_schedule ? "info" : "info"}  >
                                                            {order?.is_schedule ? "Scheduled" : "Instant"}
                                                        </Badge>
                                                        <span>{moment.utc(order?.created_at).fromNow()}</span>
                                                    </div>
                                                    <div className="d-flex flex-row-reverse">
                                                        <a href={`view-order/${order.id}`} class="btn btn-info">View</a>
                                                    </div>
                                                </div>
                                            </li>
                                        )) : (
                                            <div className='text-center py-4'></div>
                                        )}
                                </ul>
                            </Card>
                        </Col>
                        <Col lg="4" xxl="4">
                            <Card>
                                <div className="card-inner border-bottom">
                                    <div className="card-title-group">
                                        <CardTitle>
                                            <h6 className="title">Ongoing Orders</h6>
                                        </CardTitle>
                                    </div>
                                </div>
                                <ul className="nk-support">
                                    {live_orders && ongoingScheduledOrders && ongoingScheduledOrders.length > 0 ?
                                        ongoingScheduledOrders.map((order, index) => (
                                            <li className="nk-support-item" >
                                                <div className="nk-support-content">
                                                    <div className="title">
                                                        <span>{order?.user?.name}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span style={{ fontWeight: "600" }}>#{order?.unique_order_id}</span>
                                                        <Badge color="warning"  >
                                                            {order?.order_status?.name}
                                                        </Badge>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span>₹ {order?.total}</span>
                                                        <span>{order?.warehouse?.name}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <Badge color={order?.is_schedule ? "info" : "info"}  >
                                                            {order?.is_schedule ? "Scheduled" : "Instant"}
                                                        </Badge>
                                                        <span>{moment.utc(order?.created_at).fromNow()}</span>
                                                    </div>
                                                    <div className="d-flex flex-row-reverse">
                                                        <a href={`view-order/${order.id}`} class="btn btn-info">View</a>
                                                    </div>
                                                </div>
                                            </li>
                                        )) : (
                                            <div className='text-center py-4'></div>
                                        )}
                                </ul>
                            </Card>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col lg="4" xxl="4">
                            <Card>
                                <div className="card-inner border-bottom">
                                    <div className="card-title-group">
                                        <CardTitle>
                                            <h6 className="title">New Orders</h6>
                                        </CardTitle>
                                    </div>
                                </div>
                                <ul className="nk-support">
                                    {live_orders && newOrders && Object.values(newOrders).length > 0 ? (
                                        newOrders.map((order, index) => (
                                            <li className="nk-support-item" >
                                                <div className="nk-support-content">
                                                    <div className="title">
                                                        <span>{order?.user?.name}</span>
                                                    </div>

                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span style={{ fontWeight: "600" }}>#{order?.unique_order_id}</span>
                                                        <Badge color="secondary"  >
                                                            {order?.order_status?.name}
                                                        </Badge>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span>₹ {order?.total}</span>
                                                        <span>{order?.warehouse?.name}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <Badge color={order?.is_schedule ? "info" : "info"}  >
                                                            {order?.is_schedule ? "Scheduled" : "Instant"}
                                                        </Badge>
                                                        <span>{moment.utc(order?.created_at).fromNow()}</span>
                                                    </div>
                                                    <div className="d-flex flex-row-reverse">
                                                        <a href={`view-order/${order.id}`} class="btn btn-info">View</a>
                                                    </div>
                                                </div>
                                            </li>
                                        ))) : (
                                        <div className='text-center py-4'></div>
                                    )}
                                </ul>
                            </Card>
                        </Col>
                        <Col lg="4" xxl="4">
                            <Card>
                                <div className="card-inner border-bottom">
                                    <div className="card-title-group">
                                        <CardTitle>
                                            <h6 className="title">Accepted Orders</h6>
                                        </CardTitle>
                                    </div>
                                </div>
                                <ul className="nk-support">
                                    {live_orders && acceptedOrders && acceptedOrders.length > 0 ?
                                        acceptedOrders.map((order, index) => (
                                            <li className="nk-support-item" >
                                                <div className="nk-support-content">
                                                    <div className="title">
                                                        <span>{order?.user?.name}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span style={{ fontWeight: "600" }}>#{order?.unique_order_id}</span>
                                                        <Badge color="warning"  >
                                                            {order?.order_status?.name}
                                                        </Badge>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span>₹ {order?.total}</span>
                                                        <span>{order?.warehouse?.name}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <Badge color={order?.is_schedule ? "info" : "info"}  >
                                                            {order?.is_schedule ? "Scheduled" : "Instant"}
                                                        </Badge>
                                                        <span>{moment.utc(order?.created_at).fromNow()}</span>
                                                    </div>
                                                    <div className="d-flex flex-row-reverse">
                                                        <a href={`view-order/${order.id}`} class="btn btn-info">View</a>
                                                    </div>
                                                </div>
                                            </li>
                                        )) : (
                                            <div className='text-center py-4'></div>
                                        )}
                                </ul>
                            </Card>
                        </Col>
                        <Col lg="4" xxl="4">
                            <Card>
                                <div className="card-inner border-bottom">
                                    <div className="card-title-group">
                                        <CardTitle>
                                            <h6 className="title">Ongoing Orders</h6>
                                        </CardTitle>
                                    </div>
                                </div>

                                <ul className="nk-support">
                                    {live_orders && ongoingOrders && ongoingOrders?.length > 0 ?
                                        ongoingOrders?.map((order, index) => (
                                            <li className="nk-support-item" >
                                                <div className="nk-support-content">
                                                    <div className="title">
                                                        <span>{order?.user?.name}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span style={{ fontWeight: "600" }}>#{order?.unique_order_id}</span>
                                                        <Badge color="warning"  >
                                                            {order?.order_status?.name}
                                                        </Badge>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span>₹ {order?.total}</span>
                                                        <span>{order?.warehouse?.name}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <Badge color={order?.is_schedule ? "info" : "info"}  >
                                                            {order?.is_schedule ? "Scheduled" : "Instant"}
                                                        </Badge>
                                                        <span>{moment.utc(order?.created_at).fromNow()}</span>
                                                    </div>
                                                    <div className="d-flex flex-row-reverse">
                                                        <a href={`view-order/${order.id}`} class="btn btn-info">View</a>
                                                    </div>
                                                </div>
                                            </li>
                                        )) : (
                                            <div className='text-center py-4'></div>
                                        )}
                                </ul>
                            </Card>
                        </Col>
                    </Row>
                }
                <Modal
                    isOpen={completedOrder}
                    toggle={() => setCompletedOrder(false)}
                    className="modal-dialog-top"
                    size="xl"
                >

                    <ModalHeader>
                        <h5 class="modal-title" id="exampleModalToggleLabel">Completed Instant Orders</h5>
                    </ModalHeader>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" onClick={(ev) => {
                        ev.preventDefault();
                        setCompletedOrder(false);
                    }}><Icon name="cross-sm"></Icon></button>
                    <ModalBody>
                        <div className="table-responsive">
                            <table id="completedSchedule" className="table align-middle table-row-dashed fs-6 text-center">
                                <thead>
                                    <tr className="text-center fw-bolder fs-7 text-uppercase gs-0">
                                        <th>Unique Order Id</th>
                                        <th>Customer</th>
                                        <th>Amount</th>
                                        <th>Warehouse</th>
                                        <th>Status</th>
                                        <th>Delivered At</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-600 fw-bold text-center">
                                    {live_orders.completedOrders && live_orders.completedOrders.map((order) => (
                                        <tr >
                                            <td>#{order.unique_order_id}</td>
                                            <td>{order?.user?.name}</td>
                                            <td>{order.total}</td>
                                            <td>{order.warehouse ? order?.warehouse?.name : ''}</td>
                                            <td>
                                                <Badge color='success'>
                                                    {order?.order_status?.name}
                                                </Badge>
                                            </td>
                                            <td>{formatCreatedAt(order.order_delivered_at)}</td>
                                            <td>
                                                <a href={`view-order/${order.id}`} class="btn btn-info">
                                                    <i className="bi bi-eye"></i>View
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={cancelledOrder}
                    toggle={() => setCancelledOrder(false)}
                    className="modal-dialog-top"
                    size="xl"
                >
                    <ModalHeader>
                        <h5 class="modal-title" id="exampleModalToggleLabel">Cancelled Instant Orders</h5>
                    </ModalHeader>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" onClick={(ev) => {
                        ev.preventDefault();
                        setCancelledOrder(false);
                    }}><Icon name="cross-sm"></Icon></button>
                    <ModalBody>
                        <div className="table-responsive">
                            <table id="completedSchedule" className="table align-middle fs-6 text-center">
                                <thead>
                                    <tr className="text-center fw-bolder fs-7 text-uppercase gs-0">
                                        <th>Unique Order Id</th>
                                        <th>Customer</th>
                                        <th>Amount</th>
                                        <th>Warehouse</th>
                                        <th>Status</th>
                                        <th>Cancelled At</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-600 fw-bold text-center">
                                    {live_orders.cancelledOrders && live_orders.cancelledOrders.map((order) => (
                                        <tr >
                                            <td>#{order.unique_order_id}</td>
                                            <td>{order?.user?.name}</td>
                                            <td>{order.total}</td>
                                            <td>{order.warehouse ? order?.warehouse?.name : ''}</td>
                                            <td>
                                                <Badge color='danger'>
                                                    {order?.order_status?.name}
                                                </Badge></td>
                                            <td>{formatCreatedAt(order.order_cancelled_at)}</td>
                                            <td>
                                                <a href={`view-order/${order.id}`} class="btn btn-info">
                                                    <i className="bi bi-eye"></i>View
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={completedScheduledOrder}
                    toggle={() => setCompletedScheduledOrder(false)}
                    className="modal-dialog-top"
                    size="xl"
                >
                    <ModalHeader>
                        <h5 class="modal-title" id="exampleModalToggleLabel">Completed Scheduled Orders</h5>
                    </ModalHeader>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" onClick={(ev) => {
                        ev.preventDefault();
                        setCompletedScheduledOrder(false);
                    }}><Icon name="cross-sm"></Icon></button>
                    <ModalBody>
                        <div className="table-responsive">
                            <table id="completedSchedule" className="table align-middle table-row-dashed fs-6 text-center">
                                <thead>
                                    <tr className="text-center fw-bolder fs-7 text-uppercase gs-0">
                                        <th>Unique Order Id</th>
                                        <th>Customer</th>
                                        <th>Amount</th>
                                        <th>Warehouse</th>
                                        <th>Status</th>
                                        <th>Delivered At</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-600 fw-bold text-center">
                                    {live_orders.completedScheduledOrders && live_orders.completedScheduledOrders.map((order) => (
                                        <tr >
                                            <td>#{order.unique_order_id}</td>
                                            <td>{order?.user?.name}</td>
                                            <td>{order.total}</td>
                                            <td>{order.warehouse ? order?.warehouse?.name : ''}</td>
                                            <td>
                                                <Badge color='success'>
                                                    {order?.order_status?.name}
                                                </Badge>
                                            </td>
                                            <td>{formatCreatedAt(order.order_delivered_at)}</td>
                                            <td>
                                                <a href={`view-order/${order.id}`} class="btn btn-info">
                                                    <i className="bi bi-eye"></i>View
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={cancelledScheduledOrder}
                    toggle={() => setCancelledScheduledOrder(false)}
                    className="modal-dialog-top"
                    size="xl"
                >
                    <ModalHeader>
                        <h5 class="modal-title" id="exampleModalToggleLabel">Cancelled Scheduled Orders</h5>
                    </ModalHeader>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" onClick={(ev) => {
                        ev.preventDefault();
                        setCancelledScheduledOrder(false);
                    }}><Icon name="cross-sm"></Icon></button>
                    <ModalBody>
                        <div className="table-responsive">
                            <table id="completedSchedule" className="table align-middle table-row-dashed fs-6 text-center">
                                <thead>
                                    <tr className="text-center fw-bolder fs-7 text-uppercase gs-0">
                                        <th>Unique Order Id</th>
                                        <th>Customer</th>
                                        <th>Amount</th>
                                        <th>Warehouse</th>
                                        <th>Status</th>
                                        <th>Cancelled At</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-600 fw-bold text-center">
                                    {live_orders.cancelledScheduledOrders && live_orders.cancelledScheduledOrders.map((order) => (
                                        <tr >
                                            <td>#{order.unique_order_id}</td>
                                            <td>{order?.user?.name}</td>
                                            <td>{order.total}</td>
                                            <td>{order.warehouse ? order?.warehouse?.name : ''}</td>
                                            <td>
                                                <Badge color='danger'>
                                                    {order?.order_status?.name}
                                                </Badge>
                                            </td>
                                            <td>{formatCreatedAt(order.order_cancelled_at)}</td>
                                            <td>
                                                <a href={`view-order/${order.id}`} class="btn btn-info">
                                                    <i className="bi bi-eye"></i>View
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
        </React.Fragment>
    );
}
export default LiveOrders
