import {
    OPEN_FORM_LOADING,
    CLOSE_FORM_LOADING,
    FORM_SUBMISSION_STATUS,
    GET_ALL_ROLES,
    GET_ALL_WAREHOUSES
} from "./actionTypes";

const initialState = {
    form_loading: false,
    form_submission_status: false,
    roles: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case OPEN_FORM_LOADING:
            return { ...state, form_loading: true };
        case CLOSE_FORM_LOADING:
            return { ...state, form_loading: false };
        case FORM_SUBMISSION_STATUS:
            return { ...state, form_submission_status: action.payload };
        case GET_ALL_ROLES:
            return { ...state, roles: action.payload };
        default:
            return state;
    }
}
