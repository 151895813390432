import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle } from '../../../../../components/Component';
import Head from '../../../../../layout/head/Head';
import { getAllUserReferal } from '../../../../../redux/admin/user/action';
import moment from "moment"
import { Badge, Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState } from 'react';

function ReferAndEarn(user_id) {

    const dispatch = useDispatch();


    // get data from redux state
    const getSelectors = (state) => ({
        user: state.users.single_user,
        refferals: state.users.refferals,


    });

    const { user, refferals } = useSelector(getSelectors);
    const [copy, setcopy] = useState(false);

    useEffect(() => {
        dispatch(getAllUserReferal(user_id))
    }, [])
    return (
        <React.Fragment>
            <Head title="Refer & Earning"></Head>
            <BlockHead size="lg">
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle tag="h4">Refer & Earning</BlockTitle>
                        <BlockDes>
                            <p>Your Refferal Earnings and Reffered Users on Howin are shown.</p>
                        </BlockDes>
                    </BlockHeadContent>

                </BlockBetween>
            </BlockHead>
            <Block>
                <Row >
                    <Col lg="4">
                        <Card className="card-bordered">
                            <CardHeader className="border-bottom">
                                Refferal Code
                            </CardHeader>
                            <CardBody className="card-inner">
                                <CardTitle tag="h5">{user?.refferal_code}</CardTitle>
                                <CopyToClipboard text={user?.refferal_code}
                                    onCopy={() => setcopy(true)}>
                                    <Button color="primary">Copy Code </Button>
                                </CopyToClipboard>
                                {copy && <Badge className=" badge-dot" color="success">Copied</Badge>}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card className="card-bordered" inverse color="indigo">
                            <CardHeader>Earnings</CardHeader>
                            <CardBody className="card-inner">
                                <CardTitle tag="h5">0</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card className="card-bordered" inverse color="dark">
                            <CardHeader>Refferal Sign Ups</CardHeader>
                            <CardBody className="card-inner">
                                <CardTitle tag="h5">{refferals?.length}</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Block>
            <Block>
                <div className="nk-data data-list">
                    <div className="data-head">
                        <h6 className="overline-title">Reffered Users </h6>
                    </div>
                    <table className={`table table-tranx is-compact`}>
                        <thead>
                            <tr className="tb-tnx-head">
                                <th className="tb-tnx-id">
                                    <span className="">User ID</span>
                                </th>
                                <th className="tb-tnx-info">
                                    <span className="tb-tnx-desc d-none d-sm-inline-block">
                                        <span>User Name</span>
                                    </span>

                                </th>
                                <th className="tb-tnx-amount is-alt">
                                    <span className="tb-tnx-total">Created At</span>

                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {refferals && refferals.length > 0
                                && refferals.map((user) => {
                                    return (
                                        <tr key={user.id} className="tb-tnx-item">
                                            <td className="tb-tnx-id">

                                                <span>{user?.id}</span>

                                            </td>
                                            <td className="tb-tnx-info">
                                                <div className="tb-tnx-desc">
                                                    <span className="title">{user?.name}</span>
                                                </div>

                                            </td>
                                            <td className="tb-tnx-amount is-alt">
                                                <div className="tb-tnx-total">
                                                    <span className="amount">{moment(user?.created_at).fromNow()}</span>
                                                </div>

                                            </td>


                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>

                </div>
            </Block>
        </React.Fragment>
    )
}

export default ReferAndEarn
