import {
    GET_ALL_CITIES_URL,
    CREATE_CITY_URL,
    UPDATE_CITY_URL,
    TOGGLE_CITY_URL,
    TOGGLE_CITY_SURGE_URL,
    GET_CITY_USERS_URL,
    UPDATE_CITY_USERS_URL,
    REMOVE_ALL_CITY_USERS_URL,
    GET_ALL_MESSAGES_URL,
    CREATE_MESSAGE_URL,
    UPDATE_MESSAGE_URL,
    TOGGLE_MESSAGE_URL,
    DELETE_MESSAGE_URL,
    GET_CITY_WAREHOUSE_URL
} from "../../../config";
import axiosClient from "../../../helpers/axios-client";
import { CLOSE_FORM_LOADING, FORM_SUBMISSION_STATUS } from "../../common/actionTypes";
import { GET_ALL_CITIES, GET_CITY_WAREHOUSE, EDIT_CITY, GET_CITY_USERS, GET_ALL_MESSAGES } from "./actionTypes";

export const getAllCities = () => (dispatch) => {
    return axiosClient
        .post(GET_ALL_CITIES_URL)
        .then((response) => {
            if (response && response.data.success) {
                const cities = response.data.data;
                return dispatch({ type: GET_ALL_CITIES, payload: cities });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const createCity = (data) => (dispatch) => {
    return axiosClient
        .post(CREATE_CITY_URL, {
            data,
        })
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });

            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
        });
};

export const editCity = (id) => (dispatch) => {
    return axiosClient
        .post(GET_ALL_CITIES_URL, {
            id,
        })
        .then((response) => {
            if (response && response.data.success) {
                const edit_city = response.data.data;
                return dispatch({ type: EDIT_CITY, payload: edit_city });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const updateCity = (data, id) => (dispatch) => {
    return axiosClient
        .post(UPDATE_CITY_URL, {
            data,
            id,
        })
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });

            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
        });
};

export const toggleCity = (id) => (dispatch) => {
    return axiosClient.post(TOGGLE_CITY_URL, {
        id,
    });
};

export const toggleCitySurger = (id) => (dispatch) => {
    return axiosClient.post(TOGGLE_CITY_SURGE_URL, {
        id,
    });
};

export const getCityUsers = (id) => (dispatch) => {
    return axiosClient
        .post(GET_CITY_USERS_URL, { id })
        .then((response) => {
            if (response && response.data.success) {
                const city_users = response.data;
                // console.log(city_users,'city_users');
                return dispatch({ type: GET_CITY_USERS, payload: city_users });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getCityWarehouses = () => (dispatch) => {
    return axiosClient.post(GET_CITY_WAREHOUSE_URL).then((response) => {
        if (response && response.data.success) {
            const city_warehouse = response.data.warehouse;
            return dispatch({ type: GET_CITY_WAREHOUSE, payload: city_warehouse });
        }
    })
        .catch(function (error) {
            console.log(error);
        });
};

export const updateCityUsers = (city_users, id) => (dispatch) => {
    return axiosClient
        .post(UPDATE_CITY_USERS_URL, { city_users, id })
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });

            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
        })
        .catch(function (error) {
            console.log(error);
        });
};

//messages
export const getAllMessages = () => (dispatch) => {
    return axiosClient
        .post(GET_ALL_MESSAGES_URL)
        .then((response) => {
            if (response && response.data.success) {
                const messages = response.data.data;
                return dispatch({ type: GET_ALL_MESSAGES, payload: messages });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const createMessage = (data) => (dispatch) => {
    return axiosClient
        .post(CREATE_MESSAGE_URL, data)
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });

            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
        });
};

export const updateMessage = (data) => (dispatch) => {
    return axiosClient
        .post(UPDATE_MESSAGE_URL, data)
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });

            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
        });
};

export const toggleMessage = (id) => (dispatch) => {
    return axiosClient.post(TOGGLE_MESSAGE_URL, { id });
};

export const deleteMessage = (id) => (dispatch) => {
    return axiosClient
        .post(DELETE_MESSAGE_URL, { id })
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });

            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
        });
};
