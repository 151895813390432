import {
    GET_ALL_WAREHOUSE,
} from "./actionType";

const initialState = {
    warehouse: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_WAREHOUSE:
            return { ...state, warehouse: action.payload };
        default:
            return state;
    }
}
