import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, Spinner, UncontrolledDropdown } from 'reactstrap';
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, ReactDataTable, RSelect } from '../../../../components/Component';
import { WEBSITE_BASE_URL } from '../../../../config';
import ToastExample from '../../../../helpers/toast';
import Content from '../../../../layout/content/Content';
import Head from '../../../../layout/head/Head';
import { nonActiveFormSubmissionStatus, openFormLoading } from '../../../../redux/common/actions';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { bulkUploadBrands, createBrand, deleteBrand, getAllBrands, toggleBrand,toggleBrandPopular, updateBrand } from '../../../../redux/admin/item/action';
import noimage from '../../../../assets/images/noimage.png'

const Brands = () => {
    const dispatch = useDispatch(); //defining dispatch
    useEffect(() => {
        dispatch(getAllBrands());
    }, []);
    const getSelectors = (state) => ({
        brands: state.item.brands,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,
    });
    const { brands, form_loading, form_submission_status, } = useSelector(getSelectors);

    const [editingBrand, setEditingBrand] = useState([]);   //edit Item state

    const [deletingBrand, setDeletingBrand] = useState([]);   //delete Item state

    const [formData, setFormData] = useState([]);   //form data state defined

    const [showToast, setShowToast] = useState(false);   //toast state
    const [data, setData] = useState(false);   //toast state
    const [image, setImage] = useState(null);  // state defined

    const [popular, setPopular] = useState(false);  // state defined
    const [bulkFile, setBulkFile] = useState([]);  // state defined


    //modal state
    const [modal, setModal] = useState({
        add: false,
        edit: false,
        schedule: false,
        delete: false,
        bulk: false
    });

    //to open add modal
    const openAddModal = () => {
        setEditingBrand(' ');
        setImage(null);
        setPopular(false)
        dispatch(nonActiveFormSubmissionStatus());
        setTimeout(() => {
            setModal({ add: true });
        }, 100);
    };

    //to open edit modal
    const openEditModal = (item) => {
        setModal({ edit: true });
        setImage(null);
        setPopular(item.is_popular === 1 ? true : false)
        dispatch(nonActiveFormSubmissionStatus());
        setEditingBrand(item);
    };

    //to open delete modal
    const openScheduleModal = (item) => {
        setModal({ schedule: true });
        dispatch(nonActiveFormSubmissionStatus());
        // setDeletingBrand(item);
    };


    //to open delete modal
    const openDeleteModal = (item) => {
        setModal({ delete: true });
        setPopular(item.is_popular === 1 ? true : false)
        dispatch(nonActiveFormSubmissionStatus());
        setDeletingBrand(item);
    };


    //to open delete modal
    const openBulkUploadModal = (item) => {
        setModal({ bulk: true });
        dispatch(nonActiveFormSubmissionStatus());
        // setDeletingItem(item);
    };


    //form validation with yup
    const itemSchema = yup.object({
        name: yup.string().required(),
    });

    //form values
    const defaultValues = useMemo(
        () => ({
            id: editingBrand?.id || "",
            name: editingBrand?.name || "",
        }),
        [editingBrand]
    );

    const methods = useForm({
        resolver: yupResolver(itemSchema),
        defaultValues: defaultValues,
    });

    //useform methods
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control
    } = methods;

    //submit function
    const onFormSubmit = (submitData) => {
        dispatch(openFormLoading());
        console.log(submitData, popular, 'submitdata');
        const new_data = new FormData();
        new_data.append('name', submitData?.name);
        new_data.append('is_popular', popular);
        if (image) {
            new_data.append('image', image);
        }
        if (modal.add) {
            dispatch(createBrand(new_data));
        } else if (modal.edit) {
            new_data.append('id', editingBrand?.id)
            dispatch(updateBrand(new_data));
        } else if (modal.delete) {
            dispatch(deleteBrand(deletingBrand?.id));
        }
        resetForm();
    };


    const onBulkSubmit = (e) => {
        e.preventDefault();
        console.log(bulkFile)
        if (bulkFile) {
            dispatch(openFormLoading());
            const new_data = new FormData();
            new_data.append('brand_csv', bulkFile);
            dispatch(bulkUploadBrands(new_data));
            setModal({ bulk: false })
            dispatch(getAllBrands());
        }
    };

    const resetForm = () => {
        setFormData({
            name: "",
        });
    };

    //to close modal
    const onFormCancel = () => {
        setModal({ add: false, edit: false, schedule: false, delete: false });
        document.getElementsByClassName("invalid").innerHTML = "";
        resetForm();
        setEditingBrand(" ")
    };

    // toggle
    const handleStatus = (e, id) => {
        dispatch(toggleBrand(id));
        setTimeout(() => {
            dispatch(getAllBrands());
            setShowToast(true);
        }, 500);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const handlePopular = (e, id) => {
        dispatch(toggleBrandPopular(id));
        setTimeout(() => {
            dispatch(getAllBrands());
            setShowToast(true);
        }, 500);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    useEffect(() => {
        if (modal.add == true && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllBrands());
                setData(brands);
                setModal({ add: false });
                setShowToast(true);
            }, 500);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }

        if (modal.edit == true && editingBrand && form_loading == false && form_submission_status == true) {
            dispatch(getAllBrands());
            setData(brands);
            setModal({ edit: false });
            setShowToast(true);
            reset(defaultValues);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        } else {
            reset(defaultValues);
        }

        if (modal.delete == true && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllBrands());
                setData(brands);
                setModal({ delete: false });
                setShowToast(true);
            }, 500);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    }, [createBrand, form_loading, modal, form_submission_status]);

    //headers of datatable
    const tableHeadings = [
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            // cell: (row) => {
            //     return (
            //         <div className="user-card flex items-center justify-between mt-2 mb-2 w-100" id={row.id}>
            //             <div > {row.name} </div>
            //             <div style={{ paddingRight: '2rem' }}>
            //                 {row.is_popular ?
            //                     <Badge pill color='warning'>Popular</Badge>
            //                     : null}
            //             </div>
            //         </div>
            //     )
            // }
        },
        {
            name: "Image",
            selector: (row) => row.image,
            compact: true,
            style: { paddingRight: "20px" },
            cell: (row) => {
                return (
                    <div className="user-card mt-2 mb-2" id={row.id}>
                        {row?.image ?
                            <img src={WEBSITE_BASE_URL + row.image} alt="item" style={{ width: '6rem', borderRadius: "5px" }} />
                            :
                            <div className='d-flex flex-column align-items-center ml-3'>
                                <img src={noimage} alt="item" style={{ height: '5vh', width: '5vh' }} />
                                <span className='m-1' style={{ color: '#330033', fontSize: '9px' }}>No image found</span>
                            </div>
                        }
                    </div>
                );
            },
        },
        {
            name: "Status",
            selector: (row) => row.is_active,
            compact: true,
            style: { paddingRight: "20px" },
            cell: (row) => {
                return (
                    <div className="user-card mt-2 mb-2" id={row.id}>
                        <Button color={row.is_active ? "success" : "danger"} onClick={(e) => handleStatus(e, row.id)}   >
                            {row.is_active ? <Icon name="stop-circle-fill" /> : <Icon name="play-circle-fill" />}
                        </Button>
                    </div>
                );
            },
        },

        {
            name: "Popluar Brands",
            selector: (row) => row.is_popular,
            compact: true,
            style: { paddingRight: "20px" },
            cell: (row) => {
                return (
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" checked={row.is_popular ? true : false} id={"is_popular"+row.id}
                            onClick={(e) => handlePopular(e, row.id)}
                        />
                        <label className="custom-control-label user-select-none" htmlFor={"is_popular"+row.id}> </label>
                    </div>
                );
            },
        },

        {
            name: "Actions",
            selector: (row) => row.is_surge,
            compact: true,
            grow: 1,
            style: { paddingRight: "20px" },
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <DropdownTrans row={row} />
                </div>
            ),
            sortable: false,
        },
    ];
    const [sm, updateSm] = useState(false);


    //action dropdown
    const DropdownTrans = ({ row }) => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu right>
                    <ul className="link-list-plain">
                        <li>
                            <DropdownItem tag="a"
                                onClick={() => openEditModal(row)}>
                                <Icon name="pen" />Edit
                            </DropdownItem>
                        </li>
                        <li>
                            <DropdownItem tag="a"
                                onClick={() => openDeleteModal(row)}>
                                <Icon name="trash" />Delete
                            </DropdownItem>
                        </li>
                    </ul>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    return (
        <React.Fragment>
            <Head title="Brands"></Head>
            {/* content */}
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Brand Lists
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {brands?.length} Brands.</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <ToastExample
                                    show={showToast}
                                />
                                <div className="toggle-expand-content">
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <Button color="dark" className=""
                                                onClick={() => openBulkUploadModal()}    >
                                                <span>Bulk CSV Upload</span> <Icon name="icon ni ni-upload"></Icon>
                                            </Button>
                                        </li>
                                        <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon"
                                                onClick={() => openAddModal()}
                                            >
                                                <Icon name="plus"></Icon>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <ReactDataTable
                    data={brands}
                    columns={tableHeadings}
                    expandableRows
                    pagination
                />
            </Content>


            <Modal
                isOpen={modal.bulk}
                toggle={() => setModal({ bulk: false })}
                className="modal-dialog-centered"
            >
                <ModalBody className='pt-3 pb-2'>
                    <button className="close" onClick={(ev) => { ev.preventDefault(); onFormCancel(); }}  >
                        <Icon name="cross-sm"></Icon>
                    </button>
                    <div className="p-2">
                        <h5 className="title">CSV BULK UPLOAD BRANDS</h5>
                        <div className='mt-2'>
                            <Form className="row gy-4 pt-4" onSubmit={(e) => onBulkSubmit(e)} encType="multipart/formData">
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">CSV FILE</label>
                                        <input
                                            class="form-control"
                                            type="file"
                                            accept=".csv"
                                            name="brand_csv"
                                            required
                                            onChange={(e) => {
                                                setBulkFile(e.target.files[0])
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <a href={WEBSITE_BASE_URL + "/assets/docs/brand-sample.csv"} className="btn btn-lg btn-dark w-100 justify-center" target="_blank" download>
                                        Download Sample CSV
                                    </a>
                                </Col>
                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 pt-4">
                                        <li>
                                            <Button type="submit" color="primary" size="lg" className="btn-block">
                                                {form_loading ? (
                                                    <>
                                                        <Spinner size="sm" color="light" />Uploading File
                                                    </>
                                                ) : (
                                                    <React.Fragment> Upload File </React.Fragment>
                                                )}
                                            </Button>
                                        </li>
                                        <li>
                                            <button onClick={(ev) => { ev.preventDefault(); onFormCancel(); }} className="link link-light"  >
                                                Cancel
                                            </button>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>

                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* add and edit modal */}
            <Modal
                isOpen={modal.add || modal.edit}
                toggle={() => setModal({ add: false })}
                className="modal-dialog-centered"
                size="lg"
            >
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">{modal.edit ? "Editing Brand - " + editingBrand?.name : "Add Brand"}</h5>
                        <div className="mt-4">
                            <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/formData">
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Brand Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            placeholder="Enter Brand Name"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Image </label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            name="image"
                                            onChange={(e) => {
                                                setImage(e.target.files[0])
                                            }}
                                        />
                                        {errors.image && <span className="invalid">{errors.image.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="12" style={modal.edit ? { display: 'block' } : { display: 'none' }}>
                                    <FormGroup>
                                        <label className="form-label">Current Image </label>
                                        <div className="" id={editingBrand.id}>
                                            {editingBrand?.image ?
                                                <img src={WEBSITE_BASE_URL + editingBrand?.image} alt="brand" style={{ width: '8rem', borderRadius: '5px' }} />
                                                :
                                                <>
                                                    <img src={noimage} alt="brand" style={{ height: '7vh', width: '7vh' }} />
                                                    <span className='m-1' style={{ color: '#330033', fontSize: '9px' }}>No image found</span>
                                                </>
                                            }
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup >
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" checked={popular} id="is_popular"
                                                onChange={() => {
                                                    setPopular(!popular);
                                                }}

                                            />
                                            <label className="custom-control-label user-select-none" htmlFor="is_popular"> Is Popular </label>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button type="submit" color="primary" size="lg" className="btn-block">
                                                {form_loading ? (
                                                    <>
                                                        <Spinner size="sm" color="light" /> {modal.edit ? "Updating Brand" : "Creating New Brand"}
                                                    </>
                                                ) : (
                                                    <React.Fragment>{modal.edit ? "Update Brand" : "Add New Brand"}</React.Fragment>
                                                )}
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    onFormCancel();
                                                }}
                                                className="link link-light"
                                            >
                                                Cancel
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* delete modal */}
            <Modal
                isOpen={modal.delete}
                toggle={() => setModal({ delete: false })}
                className="modal-dialog-centered"
            >
                <ModalBody className='pt-3 pb-2'>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Delete Brand{' '}
                            <Badge color="primary">
                                {deletingBrand?.name}
                            </Badge>
                            {' '}
                            ?
                        </h5>
                        <div className='mt-2'>
                            <span>This brand will be deleted. Do you really want to delete this Brand ?</span>
                        </div>
                        <div className="mt-3">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4">
                                <li>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        onFormSubmit();
                                    }} color="danger" className="px-4 btn-block">
                                        {form_loading ? (
                                            <>
                                                <Spinner size="sm" color="light" />Deleting
                                            </>
                                        ) : (
                                            <React.Fragment>Delete</React.Fragment>
                                        )}
                                    </Button>
                                </li>
                                <li>
                                    <a
                                        href="#cancel"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            onFormCancel();
                                        }}
                                        className="link link-light"
                                    >
                                        Cancel
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* schedule modal */}
            <Modal
                isOpen={modal.schedule}
                size='lg'
                toggle={() => setModal({ schedule: false })}
                className="modal-dialog-centered"
            >
                <ModalBody className='pt-3 pb-2'>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Schedule Brand{' '}
                            <Badge color="primary">
                                {/* {deletingBrand?.name} */}
                            </Badge>
                        </h5>
                        <div className='mt-2'>
                            <div className=''>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <Button
                                        className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                                        onClick={() => updateSm(!sm)}
                                    >
                                        <Icon name="more-v" />
                                    </Button>
                                    <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                                        <ul className="nk-block-tools g-3">
                                            <li>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="a" className="dropdown-toggle btn btn-white btn-dim btn-outline-light">
                                                        <Icon className="d-none d-sm-inline" name="calender-date" />
                                                        <span>
                                                            <span className="d-none d-md-inline"></span>Monday
                                                        </span>
                                                        <Icon className="dd-indc" name="chevron-right" />
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        <ul className="link-list-opt no-bdr">
                                                            <li>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                    }}
                                                                >
                                                                    <span>Last 30 days</span>
                                                                </DropdownItem>
                                                            </li>
                                                            <li>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                    }}
                                                                >
                                                                    <span>Last 6 months</span>
                                                                </DropdownItem>
                                                            </li>
                                                            <li>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                    }}
                                                                >
                                                                    <span>Last 3 weeks</span>
                                                                </DropdownItem>
                                                            </li>
                                                        </ul>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </li>
                                            <li className="nk-block-tools-opt">
                                                <Button color="primary">
                                                    <Icon name="reports" />
                                                    <span>Reports</span>
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4">
                                <li>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        onFormSubmit();
                                    }} color="primary" className="px-4 btn-block">
                                        {form_loading ? (
                                            <>
                                                <Spinner size="sm" color="light" />Updating
                                            </>
                                        ) : (
                                            <React.Fragment>Update</React.Fragment>
                                        )}
                                    </Button>
                                </li>
                                <li>
                                    <a
                                        href="#cancel"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            onFormCancel();
                                        }}
                                        className="link link-light"
                                    >
                                        Cancel
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default Brands
