import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2';

function SalesData({ salesData }) {

    var salesStatistics = {
        labels: [
            "01 Jan",
            "02 Jan",
            "03 Jan",
            "04 Jan",
            "05 Jan",
            "06 Jan",
            "07 Jan",
        ],
        dataUnit: "People",
        datasets: [
            {
                label: "Total orders",
                color: "#9d72ff",
                borderWidth: 2,
                borderColor: "#9d72ff",
                fill: true,
                dash: 0,
                pointRadius: 0,
                lineTension: 0.4,
                backgroundColor: "rgba(157, 114, 255, 0.15)",
                pointBorderColor: "transparent",
                pointBackgroundColor: "transparent",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "#9d72ff",
                pointBorderWidth: 2,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 2,
                pointHitRadius: 4,
                data: [
                    0, 0, 0, 0, 0, 0, 0,
                ],
            },
            {
                label: "Canceled orders",
                color: "#eb6459",
                borderWidth: 2,
                borderColor: "#eb6459",
                borderDash: [5],
                pointBorderColor: "transparent",
                pointBackgroundColor: "transparent",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "#eb6459",
                pointBorderWidth: 2,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 2,
                pointHitRadius: 4,
                backgroundColor: "transparent",
                data: [
                    0, 0, 0, 0, 0, 0, 0,
                ],
            },
        ],
    };


    useEffect(() => {
        setData(salesData)
    }, [salesData]);

    console.log(salesData, 'cancel oneeee')
    console.log(salesStatistics, 'cancel twoooo')
    const [data, setData] = useState(salesStatistics);

    return (
        <Line
            className="ecommerce-line-s4"
            data={data}
            options={{
                legend: {
                    display: false,
                },
                maintainAspectRatio: false,
                tooltips: {
                    enabled: true,
                    callbacks: {
                        title: function (tooltipItem, data) {
                            return data["labels"][tooltipItem[0]["index"]];
                        },
                        label: function (tooltipItem, data) {
                            return data.datasets[tooltipItem.datasetIndex]["data"][tooltipItem["index"]];
                        },
                    },
                    backgroundColor: "#1c2b46",
                    titleFontSize: 13,
                    titleFontColor: "#fff",
                    titleMarginBottom: 6,
                    bodyFontColor: "#fff",
                    bodyFontSize: 12,
                    bodySpacing: 4,
                    yPadding: 10,
                    xPadding: 10,
                    footerMarginTop: 0,
                    displayColors: false,
                },
                scales: {
                    yAxes: [
                        {
                            display: true,
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11,
                                fontColor: "#9eaecf",
                                padding: 10,
                                callback: function (value, index, values) {
                                    return value;
                                },
                                min: 0,
                                stepSize: 100,
                            },
                            gridLines: {
                                color: "rgba(82, 100, 132, 0.2)",
                                tickMarkLength: 0,
                                zeroLineColor: "rgba(82, 100, 132, 0.2)",
                            },
                        },
                    ],
                    xAxes: [
                        {
                            display: false,
                            ticks: {
                                fontSize: 9,
                                fontColor: "#9eaecf",
                                source: "auto",
                                padding: 10,
                            },
                            gridLines: {
                                color: "transparent",
                                tickMarkLength: 0,
                                zeroLineColor: "transparent",
                            },
                        },
                    ],
                },
            }}
        />
    );
}

export default SalesData