import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Spinner } from 'reactstrap';
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, RSelect } from '../../../../../components/Component';
import ToastExample from '../../../../../helpers/toast';
import Content from '../../../../../layout/content/Content';
import Head from '../../../../../layout/head/Head';
import { openFormLoading } from '../../../../../redux/common/actions';
import * as yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { getItemScheduleData, getSingleItem, updateItem, updateItemScheduleData } from '../../../../../redux/admin/item/action';
import { getAllStores } from '../../../../../redux/admin/warehouse/action';
import { useParams } from 'react-router';
import { ColorRing } from 'react-loader-spinner'
import moment from 'moment';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'

function ScheduleItem() {
    const dispatch = useDispatch(); //defining dispatch

    const { id } = useParams(); //to get route parameters
    const [loading, setLoading] = useState(false);

    //to store data from application state to a constant
    const getSelectors = (state) => ({
        schedule_data: state.item?.schedule_data,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,
    });
    const { schedule_data, form_loading, form_submission_status } = useSelector(getSelectors);

    //function to get data from backend
    const [scheduleData, setScheduleData] = useState({
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
    });

    useEffect(() => {
        setLoading(true);
        dispatch(getItemScheduleData(id)).then((response) => {
            setLoading(false);
            let days = {
                monday: [],
                tuesday: [],
                wednesday: [],
                thursday: [],
                friday: [],
                saturday: [],
                sunday: [],
            }
            if (schedule_data == null) {
                setScheduleData(days)
            } else {
                setScheduleData(schedule_data)
            }
        })
    }, []);

    const [update, setUpdate] = useState(false);

    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        setUpdate(true);
        setTimeout(() => {
            let days = {
                monday: [],
                tuesday: [],
                wednesday: [],
                thursday: [],
                friday: [],
                saturday: [],
                sunday: [],
            }
            if (schedule_data == null) {
                setScheduleData(days)
            } else {
                setScheduleData(schedule_data)
            }
            setUpdate(false);
        }, 50);
    }, [schedule_data]);


    const [items, setItems] = useState({});

    const addNewDate = (key, value, day, type) => {
        const single_schedule = scheduleData[day];
        const data = single_schedule[key]
        data[type] = value
        setScheduleData({ ...scheduleData, [day]: [...single_schedule] })
        // console.log(scheduleData, 'dataaa')
    }

    const deleteTime = (day, key) => {
        const data = scheduleData[day]
        data.splice(key, 1)
        setScheduleData({ ...scheduleData, [day]: [...data] })
    }

    const handleSchedule = (day) => {
        const data = scheduleData[day];
        data.push({ open: '', close: '' })
        setScheduleData({ ...scheduleData, [day]: [...data] })
    }

    const saveTimings = () => {
        dispatch(openFormLoading());
        console.log(scheduleData, 'scheduleeeee')
        dispatch(updateItemScheduleData(id, scheduleData));
    }

    useEffect(() => {
        if (form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                setLoading(true);
                dispatch(getItemScheduleData(id)).then((response) => {
                    setLoading(false);
                })
                setShowToast(true);
            }, 1000);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    }, [updateItemScheduleData, form_loading, form_submission_status]);

    return (
        <React.Fragment>
            {loading ? (
                <Content >
                    <div className='d-flex align-items-end justify-content-center'>
                        <ColorRing
                            visible={true}
                            height="120"
                            width="120"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        />
                    </div>
                </Content>
            ) : update ? (
                <Content>
                    <BlockHead size="sm">
                        <BlockBetween>
                            <BlockHeadContent>
                                <BlockTitle tag="h3" page>
                                    Schedule Item
                                </BlockTitle>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>
                    <Card className="card-bordered">
                        <CardHeader className="border-bottom">
                            <CardTitle tag="h6" className='mt-1'>Monday</CardTitle>
                        </CardHeader>
                        <CardBody className="card-inner">
                            <div className='px-3'>
                                <div className=''>
                                    <Button color="success" size='sm'> Add Slot<Icon name="plus" /></Button>
                                </div>
                                <div className='px-4'>
                                    <Button color="primary" size='lg'>Save Timings </Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Content>

            ) : (
                <>
                    {!loading &&
                        <>
                            <Head title="Schedule Item"></Head>
                            {/* content */}
                            <Content>
                                <BlockHead size="sm">
                                    <BlockBetween>
                                        <BlockHeadContent>
                                            <BlockTitle tag="h3" page>
                                                Schedule Item
                                            </BlockTitle>
                                        </BlockHeadContent>
                                        <BlockHeadContent>
                                            <div className="toggle-wrap nk-block-tools-toggle">
                                                <ToastExample
                                                    show={showToast}
                                                />
                                            </div>
                                        </BlockHeadContent>
                                    </BlockBetween>
                                </BlockHead>
                                {/* <Card className="card-bordered">
                                    <CardBody className="card-inner"> */}
                                <div className="">
                                    {/* Monday */}
                                    <Card className="card-bordered">
                                        <CardHeader className="border-bottom" >
                                            <CardTitle tag="h6" className='mt-1'>Monday</CardTitle>
                                        </CardHeader>
                                        <CardBody className="card-inner" >
                                            <div className='px-3'>
                                                <div className=''>
                                                    <Button color="success" onClick={() => handleSchedule('monday')} size='sm'> Add Slot<Icon name="plus" /></Button>
                                                </div>
                                                <div className="d-flex flex-column px-3 my-2">
                                                    {scheduleData?.monday?.map((item, index) => (
                                                        <div className='d-flex flex-row justify-content-around align-items-center py-2' key={index}>
                                                            <div className=''>
                                                                <TimePicker use12Hours format="h:mm a"
                                                                    defaultValue={item?.open ? dayjs(item?.open, 'HH:mm') : ""}
                                                                    onChange={(time, timeString) => {
                                                                        let output = moment(timeString, 'hh:mm A').format('HH mm');
                                                                        addNewDate(index, output, 'monday', "open")
                                                                    }} />
                                                            </div>
                                                            <div>
                                                                To
                                                            </div>
                                                            <div className=' ms-2'>
                                                                <TimePicker use12Hours format="h:mm a"
                                                                    defaultValue={item?.close ? dayjs(item?.close, 'HH:mm') : ''}
                                                                    onChange={(time, timeString) => {
                                                                        let output = moment(timeString, 'hh:mm A').format('HH mm');
                                                                        addNewDate(index, output, 'monday', "close")

                                                                    }} />
                                                            </div>
                                                            <Button className='ms-2' color='danger' size='sm'
                                                                onClick={() => deleteTime('monday', index)}
                                                            >
                                                                <Icon name="cross" />
                                                            </Button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    {/* Tuesday */}
                                    <Card className="card-bordered mt-3">
                                        <CardHeader className="border-bottom">
                                            <CardTitle tag="h6" className='mt-1'>Tuesday</CardTitle>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <div className='px-3'>
                                                <div className=''>
                                                    <Button color="success" onClick={() => handleSchedule('tuesday')} size='sm'> Add Slot<Icon name="plus" /></Button>
                                                </div>
                                                <div className="d-flex flex-column px-3 my-2">
                                                    {scheduleData?.tuesday?.map((item, index) => (
                                                        <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                            <div className=''>
                                                                <TimePicker use12Hours format="h:mm a"
                                                                    defaultValue={item?.open ? dayjs(item?.open, 'HH:mm') : ''}
                                                                    onChange={(time, timeString) => {
                                                                        let output = moment(timeString, 'hh:mm A').format('HH mm');
                                                                        addNewDate(index, output, 'tuesday', "open")

                                                                    }} />
                                                            </div>
                                                            <div>
                                                                To
                                                            </div>
                                                            <div className=' ms-2'>
                                                                <TimePicker use12Hours format="h:mm a"
                                                                    defaultValue={item?.close ? dayjs(item?.close, 'HH:mm') : ''}
                                                                    onChange={(time, timeString) => {
                                                                        let output = moment(timeString, 'hh:mm A').format('HH mm');
                                                                        addNewDate(index, output, 'tuesday', "close")

                                                                    }} />
                                                            </div>
                                                            <Button className='ms-2' color='danger' size='sm'
                                                                onClick={() => deleteTime('tuesday', index)}
                                                            >
                                                                <Icon name="cross" />
                                                            </Button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    {/* Wednesday */}
                                    <Card className="card-bordered mt-3">
                                        <CardHeader className="border-bottom">
                                            <CardTitle tag="h6" className='mt-1'>Wednesday</CardTitle>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <div className='px-3'>
                                                <div className=''>
                                                    <Button color="success" onClick={() => handleSchedule('wednesday')} size='sm'> Add Slot<Icon name="plus" /></Button>
                                                </div>
                                                <div className="d-flex flex-column px-3 my-2">
                                                    {scheduleData?.wednesday?.map((item, index) => (
                                                        <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                            <div className=''>
                                                                <TimePicker use12Hours format="h:mm a"
                                                                    defaultValue={item?.open ? dayjs(item?.open, 'HH:mm') : ''}
                                                                    onChange={(time, timeString) => {
                                                                        let output = moment(timeString, 'hh:mm A').format('HH mm');
                                                                        addNewDate(index, output, 'wednesday', "open")

                                                                    }} />
                                                            </div>
                                                            <div>
                                                                To
                                                            </div>
                                                            <div className=' ms-2'>
                                                                <TimePicker use12Hours format="h:mm a"
                                                                    defaultValue={item?.close ? dayjs(item?.close, 'HH:mm') : ''}
                                                                    onChange={(time, timeString) => {
                                                                        let output = moment(timeString, 'hh:mm A').format('HH mm');
                                                                        addNewDate(index, output, 'wednesday', "close")

                                                                    }} />
                                                            </div>
                                                            <Button className='ms-2' color='danger' size='sm'
                                                                onClick={() => deleteTime('wednesday', index)}
                                                            >
                                                                <Icon name="cross" />
                                                            </Button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    {/* Thursday */}
                                    <Card className="card-bordered mt-3">
                                        <CardHeader className="border-bottom">
                                            <CardTitle tag="h6" className='mt-1'>Thursday</CardTitle>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <div className='px-3'>
                                                <div className=''>
                                                    <Button color="success" onClick={() => handleSchedule('thursday')} size='sm'> Add Slot<Icon name="plus" /></Button>
                                                </div>
                                                <div className="d-flex flex-column px-3 my-2">
                                                    {scheduleData?.thursday?.map((item, index) => (
                                                        <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                            <div className=''>
                                                                <TimePicker use12Hours format="h:mm a"
                                                                    defaultValue={item?.open ? dayjs(item?.open, 'HH:mm') : ''}
                                                                    onChange={(time, timeString) => {
                                                                        let output = moment(timeString, 'hh:mm A').format('HH mm');
                                                                        addNewDate(index, output, 'thursday', "open")

                                                                    }} />
                                                            </div>
                                                            <div>
                                                                To
                                                            </div>
                                                            <div className=' ms-2'>
                                                                <TimePicker use12Hours format="h:mm a"
                                                                    defaultValue={item?.close ? dayjs(item?.close, 'HH:mm') : ''}
                                                                    onChange={(time, timeString) => {
                                                                        let output = moment(timeString, 'hh:mm A').format('HH mm');
                                                                        addNewDate(index, output, 'thursday', "close")

                                                                    }} />
                                                            </div>
                                                            <Button className='ms-2' color='danger' size='sm'
                                                                onClick={() => deleteTime('thursday', index)}
                                                            >
                                                                <Icon name="cross" />
                                                            </Button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    {/* Friday */}
                                    <Card className="card-bordered mt-3">
                                        <CardHeader className="border-bottom">
                                            <CardTitle tag="h6" className='mt-1'>Friday</CardTitle>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <div className='px-3'>
                                                <div className=''>
                                                    <Button color="success" onClick={() => handleSchedule('friday')} size='sm'> Add Slot<Icon name="plus" /></Button>
                                                </div>
                                                <div className="d-flex flex-column px-3 my-2">
                                                    {scheduleData?.friday?.map((item, index) => (
                                                        <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                            <div className=''>
                                                                <TimePicker use12Hours format="h:mm a"
                                                                    defaultValue={item?.open ? dayjs(item?.open, 'HH:mm') : ''}
                                                                    onChange={(time, timeString) => {
                                                                        let output = moment(timeString, 'hh:mm A').format('HH mm');
                                                                        addNewDate(index, output, 'friday', "open")

                                                                    }} />
                                                            </div>
                                                            <div>
                                                                To
                                                            </div>
                                                            <div className=' ms-2'>
                                                                <TimePicker use12Hours format="h:mm a"
                                                                    defaultValue={item?.close ? dayjs(item?.close, 'HH:mm') : ''}
                                                                    onChange={(time, timeString) => {
                                                                        let output = moment(timeString, 'hh:mm A').format('HH mm');
                                                                        addNewDate(index, output, 'friday', "close")

                                                                    }} />
                                                            </div>
                                                            <Button className='ms-2' color='danger' size='sm'
                                                                onClick={() => deleteTime('friday', index)}
                                                            >
                                                                <Icon name="cross" />
                                                            </Button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    {/* Saturday */}
                                    <Card className="card-bordered mt-3">
                                        <CardHeader className="border-bottom">
                                            <CardTitle tag="h6" className='mt-1'>Saturday</CardTitle>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <div className='px-3'>
                                                <div className=''>
                                                    <Button color="success" onClick={() => handleSchedule('saturday')} size='sm'> Add Slot<Icon name="plus" /></Button>
                                                </div>
                                                <div className="d-flex flex-column px-3 my-2">
                                                    {scheduleData?.saturday?.map((item, index) => (
                                                        <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                            <div className=''>
                                                                <TimePicker use12Hours format="h:mm a"
                                                                    defaultValue={item?.open ? dayjs(item?.open, 'HH:mm') : ''}
                                                                    onChange={(time, timeString) => {
                                                                        let output = moment(timeString, 'hh:mm A').format('HH mm');
                                                                        addNewDate(index, output, 'saturday', "open")

                                                                    }} />
                                                            </div>
                                                            <div>
                                                                To
                                                            </div>
                                                            <div className=' ms-2'>
                                                                <TimePicker use12Hours format="h:mm a"
                                                                    defaultValue={item?.close ? dayjs(item?.close, 'HH:mm') : ''}
                                                                    onChange={(time, timeString) => {
                                                                        let output = moment(timeString, 'hh:mm A').format('HH mm');
                                                                        addNewDate(index, output, 'saturday', "close")

                                                                    }} />
                                                            </div>
                                                            <Button className='ms-2' color='danger' size='sm'
                                                                onClick={() => deleteTime('saturday', index)}
                                                            >
                                                                <Icon name="cross" />
                                                            </Button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    {/* Sunday */}
                                    <Card className="card-bordered mt-3">
                                        <CardHeader className="border-bottom">
                                            <CardTitle tag="h6" className='mt-1'>Sunday</CardTitle>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <div className='px-3'>
                                                <div className=''>
                                                    <Button color="success" onClick={() => handleSchedule('sunday')} size='sm'> Add Slot<Icon name="plus" /></Button>
                                                </div>
                                                <div className="d-flex flex-column px-3 my-2">
                                                    {scheduleData?.sunday?.map((item, index) => (
                                                        <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                            <div className=''>
                                                                <TimePicker use12Hours format="h:mm a"
                                                                    defaultValue={item?.open ? dayjs(item?.open, 'HH:mm') : ''}
                                                                    onChange={(time, timeString) => {
                                                                        let output = moment(timeString, 'hh:mm A').format('HH mm');
                                                                        addNewDate(index, output, 'sunday', "open")

                                                                    }} />
                                                            </div>
                                                            <div>
                                                                To
                                                            </div>
                                                            <div className=' ms-2'>
                                                                <TimePicker use12Hours format="h:mm a"
                                                                    defaultValue={item?.close ? dayjs(item?.close, 'HH:mm') : ''}
                                                                    onChange={(time, timeString) => {
                                                                        let output = moment(timeString, 'hh:mm A').format('HH mm');
                                                                        addNewDate(index, output, 'sunday', "close")

                                                                    }} />
                                                            </div>
                                                            <Button className='ms-2' color='danger' size='sm'
                                                                onClick={() => deleteTime('sunday', index)}
                                                            >
                                                                <Icon name="cross" />
                                                            </Button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <div className='px-4 d-flex justify-content-end w-100 mt-2 p-2'>
                                        <Button color="primary" type="button" onClick={() => saveTimings()} size='lg' className='py-3 px-5'>
                                            {form_loading ? (
                                                <>
                                                    <Spinner size="sm" color="light" /> Updating Timings
                                                </>
                                            ) : (
                                                <React.Fragment>
                                                    <Icon name="upload-cloud" className="pe-1" />Update Timings
                                                </React.Fragment>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                                {/* </CardBody>
                                </Card> */}
                            </Content>
                        </>
                    }
                </>
            )}
        </React.Fragment>
    )
}

export default ScheduleItem
