import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Col, Row } from 'reactstrap';
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, ReactDataTable } from '../../../../components/Component';
import Content from '../../../../layout/content/Content';
import Head from '../../../../layout/head/Head';
import { getAllOrders } from '../../../../redux/admin/order/action';
import moment from "moment";
import DatePicker from "react-datepicker";
import subDays from "date-fns/subDays";
import { WEBSITE_API_URL } from '../../../../config';

const Orders = () => {
    const dispatch = useDispatch(); //defining dispatch

    // save date for datepicker
    const [startDate, setStartDate] = useState(subDays(new Date(), 30));
    const [endDate, setEndDate] = useState(new Date());

    //function to get data from backend
    useEffect(() => {
        dispatch(getAllOrders());
    }, []);

    // on date change
    const handleDatePicker = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const onExport = () => {
        const token = localStorage.getItem('accessToken');
        const formatedStart = moment(startDate).format('DD-MM-yyyy');
        const formatedEnd = moment(endDate).format('DD-MM-yyyy');

        window.location = WEBSITE_API_URL + "/export-sales-order-report?token=" + token + "&start_date=" + formatedStart + "&end_date=" + formatedEnd + "&type=ORDER";
    }

    //to store data from application state to a constant
    const getSelectors = (state) => ({
        orders: state.order.orders,
    });
    const { orders } = useSelector(getSelectors);

    //headers of datatable
    const tableHeadings = [
        {
            name: "ORDER ID",
            selector: (row) => row.unique_order_id,
            sortable: true,
        },
        {
            name: "City",
            selector: (row) => row?.city ? row?.city?.name : 'No City Found',
            sortable: true,
        },
        {
            name: "Warehouse",
            selector: (row) => row?.warehouse ? row?.warehouse?.name : 'No Warehouse Found',
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) => row?.order_status ? row?.order_status?.name : 'No Status Found',
            compact: true,
            cell: (row) => {
                return (
                    <Badge color={row.order_status_id == 7 ? "success" : row.order_status_id == 8 ? "danger" : row.order_status_id == 1 ? "secondary" : "warning"}>
                        {row?.order_status ? row?.order_status?.name : 'No Status Found'}
                    </Badge>
                )
            }
        },
        {
            name: "Created At",
            selector: (row) => row?.warehouse ? (moment.utc(row?.created_at).fromNow()) : 'No Warehouse Found',
            sortable: true,
        },
        {
            name: "Action",
            selector: (row) => row.is_active,
            compact: true,
            style: { paddingRight: "20px" },
            cell: (row) => {
                return (
                    <div className="user-card mt-2 mb-2" id={row.id}>
                        <Button color='info' size="sm" href={"/view-order/" + row.id}>
                            <Icon name="eye" />
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <React.Fragment>
            <Head title="Orders"></Head>

            {/* content */}
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Order Lists
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {orders?.length} orders.</p>
                            </BlockDes>
                        </BlockHeadContent>

                    </BlockBetween>

                </BlockHead>
                <Block>
                    <Row className='mb-3'>
                        <Col md="4">
                            <span className="form-label">Choose Date Range</span>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDatePicker}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                className="form-control date-picker"
                                required
                            />

                        </Col>
                        <Col md="3" className='d-flex align-items-end'>
                            <Button outline color="primary" onClick={() => { onExport() }} >
                                <Icon name="download-cloud"></Icon>
                                <span>Export</span>
                            </Button>
                        </Col>

                    </Row>
                </Block>
                <ReactDataTable
                    data={orders}
                    columns={tableHeadings}
                    expandableRows
                    pagination
                />
            </Content>
        </React.Fragment>
    )
}

export default Orders
