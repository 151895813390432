import {
    GET_ALL_MAIN_CATEGORIES,
    GET_ALL_CATEGORIES,
    GET_ALL_SUB_CATEGORIES
} from "./actionType";

const initialState = {
    main_categories: [],
    categories: [],
    sub_categories: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_MAIN_CATEGORIES:
            return { ...state, main_categories: action.payload };
        case GET_ALL_CATEGORIES:
            return { ...state, categories: action.payload };
        case GET_ALL_SUB_CATEGORIES:
            return { ...state, sub_categories: action.payload };
        default:
            return state;
    }
}
