import React, { useEffect, useMemo, useState } from 'react'
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, Spinner, UncontrolledDropdown } from 'reactstrap'
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, ReactDataTable, RSelect } from '../../../../components/Component'
import Content from '../../../../layout/content/Content'
import Head from '../../../../layout/head/Head'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { bulkUploadAddons, createAddon, deleteAddon, getAllAddonCategories, getAllAddons, toggleAddon, updateAddon } from '../../../../redux/admin/addon/action'
import { nonActiveFormSubmissionStatus, openFormLoading } from '../../../../redux/common/actions'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup";
import { string } from "yup";
import { Controller, useForm } from 'react-hook-form'
import ToastExample from '../../../../helpers/toast'
import { WEBSITE_BASE_URL } from '../../../../config'


const Addons = () => {
    const dispatch = useDispatch();

    // get data from redux state
    const getSelectors = (state) => ({
        addons: state.addons.addons,
        addonCategories: state.addons.addonCategories,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,
    });

    const { addons, addonCategories, form_loading, form_submission_status, } = useSelector(getSelectors);
    const [formData, setFormData] = useState([]);
    const [data, setData] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [editAddon, setEditAddon] = useState([]); // state to add and edit
    const [deleteingAddon, setDeleteingAddon] = useState([]); // for deleting addon
    const [bulkFile, setBulkFile] = useState([]);  // state defined

    // modal state
    const [modal, setModal] = useState({
        edit: false,
        add: false,
        delete: false,
        bulk: false

    });

    // function to close the form modal
    const onFormCancel = () => {
        setModal({ edit: false, add: false, delete: false });
        resetForm();
    };
    // function to reset the form
    const resetForm = () => {
        setFormData({
            name: "",
            price: "",
            addon_category_id: "",

        });
    };
    // form validation schema
    const addonSchema = yup.object({
        name: string().required(),
        price: string().required(),
        addon_category_id: string().required(),

    });

    // schema to edit form data
    const defaultValues = useMemo(
        () => ({
            id: editAddon?.id || "",
            name: editAddon?.name || "",
            price: editAddon?.price || "",
            addon_category_id: editAddon?.addon_category_id || "",

        }),
        [editAddon]
    );

    const methods = useForm({
        resolver: yupResolver(addonSchema),
        defaultValues: defaultValues,
    });

    const {
        register,
        handleSubmit,
        setError,
        watch,
        control,
        formState: { errors },
        unregister,
        reset,
    } = methods;

    // get all addons  onload
    useEffect(() => {
        dispatch(getAllAddons())
        dispatch(getAllAddonCategories());

    }, [])

    // add
    const openAddModal = () => {
        setEditAddon([]);
        dispatch(nonActiveFormSubmissionStatus());
        setTimeout(() => {
            setModal({ add: true });
        }, 100);
    };
    // edit
    const openEditModal = (addon) => {
        dispatch(nonActiveFormSubmissionStatus());
        setEditAddon(addon);
        setTimeout(() => {
            setModal({
                edit: true
            })
        }, 100);
    }
    // to open delete modal
    const openDeleteModal = (addon) => {
        dispatch(nonActiveFormSubmissionStatus());
        setDeleteingAddon(addon);
        setTimeout(() => {
            setModal({
                delete: true
            })
        }, 100);
    }

    // to handle status
    const handleAddonStatus = (e, id) => {
        dispatch(toggleAddon(id));
        setTimeout(() => {
            dispatch(getAllAddons());
            setShowToast(true);
        }, 500);

        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    //to open bulk modal
    const openBulkUploadModal = (item) => {
        setModal({ bulk: true });
        dispatch(nonActiveFormSubmissionStatus());
        // setDeletingItem(item);
    };

    // submit data to api
    const onFormSubmit = (submitData) => {
        dispatch(openFormLoading());

        if (modal.edit) {
            dispatch(updateAddon(submitData, editAddon?.id));

        }
        else if (modal.add) {
            dispatch(createAddon(submitData));
        }
        else if (modal.delete) {
            dispatch(deleteAddon(deleteingAddon.id));
        }
    };


    const onBulkSubmit = (e) => {
        e.preventDefault();
        if (bulkFile) {
            dispatch(openFormLoading());
            const new_data = new FormData();
            new_data.append('addon_csv', bulkFile);
            dispatch(bulkUploadAddons(new_data));
            setModal({ bulk: false })
            dispatch(getAllAddonCategories());
        }
    };

    // useEffect which watches the formsubmission of delete and create.
    useEffect(() => {
        if (modal.add == true && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllAddons());
                setData(addons);
                setModal({ add: false });
                setShowToast(true);
            }, 500);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
        if (modal.edit == true && editAddon && form_loading == false && form_submission_status == true) {
            dispatch(getAllAddons());
            setData(addons);
            setModal({ edit: false });
            setShowToast(true);
            reset(defaultValues);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        } else {
            reset(defaultValues);
        }
        if (modal.delete == true && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllAddons());
                setData(addons);
                setModal({ delete: false });
                setShowToast(true);
            }, 500);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    }, [createAddon, form_loading, modal, form_submission_status]);

    //headers of table
    const AddonHeading = [
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Price",
            selector: (row) => row.price,
            sortable: true,
            hide: 370,
        },

        {
            name: "Addon Category",
            selector: (row) => row?.addon_category ? row?.addon_category?.name : "Addon Category Not Available",
            sortable: true,
            hide: "sm",
        },
        {
            name: "Status",
            selector: (row) => row.is_active,
            compact: true,
            style: { paddingRight: "20px" },

            cell: (row) => {
                return (
                    <div className="user-card mt-2 mb-2" id={row.id}>
                        <Button color={row.is_active ? "success" : "danger"}
                            onClick={(e) => handleAddonStatus(e, row.id)}
                        >
                            {row.is_active ? <Icon name="stop-circle-fill" /> : <Icon name="play-circle-fill" />}
                        </Button>
                    </div>
                );
            },
        },
        {
            name: "Actions",

            compact: true,
            grow: 1,
            style: { paddingRight: "20px" },
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <DropdownTrans row={row} />

                </div>

            ),
            sortable: false,
        },
    ];

    const DropdownTrans = ({ row }) => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu right>
                    <ul className="link-list-plain">
                        <li>
                            <DropdownItem tag="a" onClick={() => openEditModal(row)}>
                                <Icon name="pen" />
                                Edit
                            </DropdownItem>
                        </li>
                        <li>
                            <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(e) => { openDeleteModal(row) }}
                            >
                                <Icon name="trash" />
                                Delete
                            </DropdownItem>
                        </li>

                    </ul>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };



    const allAddonCategories = addonCategories?.map((category) => (
        { value: category.id, label: category.name }

    ));

    return (
        <React.Fragment>

            <Head title="Addons "></Head>

            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Addons
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {addons?.length} Addons .</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <ToastExample show={showToast} />
                                <div className="toggle-expand-content" >
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <Button color="dark" className=""
                                                onClick={() => openBulkUploadModal()}
                                            >
                                                <span>Bulk CSV Upload</span> <Icon name="icon ni ni-upload"></Icon>
                                            </Button>
                                        </li>
                                        <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon"
                                                onClick={() => openAddModal()}
                                            >
                                                <Icon name="plus"></Icon>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                {/* datatable */}
                <ReactDataTable
                    data={addons}
                    columns={AddonHeading}

                    pagination
                />

            </Content>


            <Modal
                isOpen={modal.bulk}
                toggle={() => setModal({ bulk: false })}
                className="modal-dialog-centered"
            >
                <ModalBody className='pt-3 pb-2'>
                    <button className="close" onClick={(ev) => { ev.preventDefault(); onFormCancel(); }}  >
                        <Icon name="cross-sm"></Icon>
                    </button>
                    <div className="p-2">
                        <h5 className="title">CSV BULK UPLOAD ADDON</h5>
                        <div className='mt-2'>
                            <Form className="row gy-4 pt-4" onSubmit={(e) => onBulkSubmit(e)} encType="multipart/formData">
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">CSV FILE</label>
                                        <input
                                            class="form-control"
                                            type="file"
                                            accept=".csv"
                                            name="addon_csv"
                                            required
                                            onChange={(e) => {
                                                setBulkFile(e.target.files[0])
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <a href={WEBSITE_BASE_URL + "/assets/docs/addon-sample.csv"} className="btn btn-lg btn-dark w-100 justify-center" target="_blank" download>
                                        Download Sample CSV
                                    </a>
                                </Col>
                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 pt-4">
                                        <li>
                                            <Button type="submit" color="primary" size="lg" className="btn-block">
                                                {form_loading ? (
                                                    <>
                                                        <Spinner size="sm" color="light" />Uploading File
                                                    </>
                                                ) : (
                                                    <React.Fragment> Upload File </React.Fragment>
                                                )}
                                            </Button>
                                        </li>
                                        <li>
                                            <button onClick={(ev) => { ev.preventDefault(); onFormCancel(); }} className="link link-light"  >
                                                Cancel
                                            </button>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>

                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* add modal */}
            <Modal
                isOpen={modal.add || modal.edit}
                toggle={() => setModal({ add: false })}
                className="modal-dialog-centered"
                size="lg"
            >
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">{modal.edit ? "Editing Addon  - " + editAddon?.name : "Add Addon"}</h5>
                        <div className="mt-4">
                            <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label"> Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            placeholder="Enter Addon  Name"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Price</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            min="0"
                                            name="price"
                                            placeholder="Enter a price"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.price && <span className="invalid">{errors.price.message}</span>}
                                    </FormGroup>
                                </Col>


                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Addon Category </label>
                                        <div className="form-control-wrap">
                                            <Controller
                                                name="addon_category_id"
                                                control={control}
                                                render={({ onChange, value, ref }) => (
                                                    <RSelect
                                                        options={allAddonCategories}
                                                        onChange={(val) => onChange(val.value)}
                                                        placeholder="Select Addon Category"
                                                        defaultValue={allAddonCategories.find((c) => c.value === value)}
                                                    />
                                                )}
                                                ref={register({ required: "This field is required" })}
                                            />
                                            {errors.addon_category_id && (
                                                <span className="invalid text-danger text-italic">Please choose a Addon Category </span>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>


                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button type="submit" color="primary" size="lg" className="btn-block">
                                                {form_loading ? (
                                                    <>
                                                        <Spinner size="sm" color="light" /> Creating New Addon
                                                    </>
                                                ) : (
                                                    <React.Fragment>{modal.edit ? "Update Addon " : "Add New Addon "}</React.Fragment>
                                                )}
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    onFormCancel();
                                                }}
                                                className="link link-light"
                                            >
                                                Cancel
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>



            {/* delete modal */}
            <Modal
                isOpen={modal.delete}
                toggle={() => setModal({ delete: false })}
                className="modal-dialog-centered"
            // size="lg"
            >
                <ModalBody className="pt-3 pb-2">
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">
                            Delete Addon Category <Badge color="primary">{deleteingAddon?.name}</Badge> ?

                        </h5>
                        <div className="mt-2">
                            <span>This Addon will be deleted. Do you really want to delete this Addon ?</span>
                        </div>
                        <div className="mt-3">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4">
                                <li>
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onFormSubmit();
                                        }}
                                        color="danger"
                                        className="px-4 btn-block"
                                    >
                                        {form_loading ? (
                                            <>
                                                <Spinner size="sm" color="light" />
                                                Deleting
                                            </>
                                        ) : (
                                            <React.Fragment>Delete</React.Fragment>
                                        )}
                                    </Button>
                                </li>
                                <li>
                                    <a
                                        href="#cancel"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            onFormCancel();
                                        }}
                                        className="link link-light"
                                    >
                                        Cancel
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default Addons
