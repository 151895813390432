import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Card, Col, Form, FormGroup, } from 'reactstrap';
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Button, Row, RSelect } from '../../../../components/Component';
import Content from '../../../../layout/content/Content';
import Head from '../../../../layout/head/Head';
import { useDispatch, useSelector } from 'react-redux';
import { getItemAddonCategory, updateItemAddonCategory } from '../../../../redux/admin/item/action';
import { useParams } from 'react-router-dom';

const ItemAddonCategory = () => {
    const dispatch = useDispatch(); //defining dispatch
    let { id } = useParams();

    useEffect(() => {
        const formData = new FormData();
        formData.append('item_id', id);
        dispatch(getItemAddonCategory(formData));
    }, []);

    const getSelectors = (state) => ({
        items: state.item.items,
        itemAddonCategory: state.item.item_addon_category,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,
    });

    const { items, itemAddonCategory, form_loading, form_submission_status, } = useSelector(getSelectors);
    const [addonCategories, setAddonCategories] = useState([]);  // state defined
    const { handleSubmit, control } = useForm();
    //submit function
    const onFormSubmit = (ev) => {
        const formData = new FormData();
        let addonCategoryIds= JSON.stringify(addonCategories)
        formData.append('item_id', itemAddonCategory?.item?.id);
        formData.append('addonCategoryIds', addonCategoryIds);
        dispatch(updateItemAddonCategory(formData));
    }

    const getAddonCategory = () => {
        const AddonCategory = itemAddonCategory?.addoncategories?.map((category) => (
            { value: category.id, label: category.name }
        ));
        return AddonCategory
    }

    const selectedAddonCategory = () => {
        const AddonCategory = itemAddonCategory?.selectedCategories?.map((category) => (
            { value: category.id, label: category.name }
        ));
        return AddonCategory?.length > 0 ? AddonCategory : null
    }

    return (
        <React.Fragment>
            <Head title="Items"></Head>
            {/* content */}
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Item Addon Category
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <Row className="g-gs">
                        <Col xxl="12">
                            <Card>
                                <div className="nk-ecwg nk-ecwg3 pb-3">
                                    <div className="card-inner pb-0">
                                        <div className="card-title-group">
                                            <div className="card-title">
                                                <h6 className="title">Adding Addon Categories</h6>
                                            </div>
                                        </div>
                                        <div className="nk-data data-list">
                                            <Form className="gy-3" onSubmit={handleSubmit(onFormSubmit)} >
                                                <Row className="g-3 align-center">
                                                    <Col lg="5">
                                                        <FormGroup>
                                                            <label className="form-label" htmlFor="user-name">
                                                                Item Name
                                                            </label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="7">
                                                        <FormGroup>
                                                            <div className="form-control-wrap">
                                                                <input
                                                                    type="text"
                                                                    id="user-name"
                                                                    name='name'
                                                                    value={itemAddonCategory?.item?.name}
                                                                    className="form-control"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="g-3 align-center">
                                                    <Col lg="5">
                                                        <FormGroup>
                                                            <label className="form-label" htmlFor="user-name">
                                                                Addon Categories
                                                            </label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="7">
                                                        <FormGroup>
                                                            <div className="form-control-wrap">
                                                                <Controller
                                                                    name="status"
                                                                    control={control}
                                                                    render={({ onChange, value, ref }) => (
                                                                        <RSelect
                                                                            isMulti
                                                                            options={getAddonCategory()}
                                                                            onChange={(val) => setAddonCategories(val.map((c) => c.value))}
                                                                            defaultValue={selectedAddonCategory()}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="g-3 align-center mt-3">
                                                    <Col lg="12">
                                                        <ul className="align-center flex-wrap flex-sm-nowrap justify-end gx-4 gy-2">
                                                            <li>
                                                                <Button type="submit" color="primary" size="lg" className="btn-block">
                                                                    <React.Fragment> Update </React.Fragment>
                                                                </Button>
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                            </Form>

                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Block>
            </Content>
        </React.Fragment>
    );
}

export default ItemAddonCategory
