import { GET_ALL_ADDON, GET_ALL_ADDON_CATEGORY } from "./actionType";


const initialState = {
    addonCategories: [],
    addons: [],

};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_ADDON_CATEGORY:
            return { ...state, addonCategories: action.payload };
        case GET_ALL_ADDON:
            return { ...state, addons: action.payload };
        default:
            return state;
    }
}
