import { GET_ALL_WAREHOUSES_URL, CREATE_WAREHOUSE_URL, UPDATE_WAREHOUSE_URL, DELETE_WAREHOUSE_URL, TOGGLE_WAREHOUSE_URL } from "../../../config";
import axiosClient from "../../../helpers/axios-client";
import { CLOSE_FORM_LOADING, FORM_SUBMISSION_STATUS } from "../../common/actionTypes";
import { GET_ALL_WAREHOUSE } from "./actionType";



//Warehouse
export const getAllWarehouses = () => (dispatch) => {
    return axiosClient.post(GET_ALL_WAREHOUSES_URL).then((response) => {
        if (response && response.data.success) {
            const warehouses = response.data.data;
            return dispatch({ type: GET_ALL_WAREHOUSE, payload: warehouses });
        }
    }).catch(function (error) {
        console.log(error);
    });
};



export const createWarehouse = (data) => (dispatch) => {
    const config = {
        headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', }
    }
    return axiosClient.post(CREATE_WAREHOUSE_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING, });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    }).catch(error => {
        console.log(error);
    });

};

export const updateWarehouse = (data) => (dispatch) => {
    const config = {
        headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', }
    }
    return axiosClient.post(UPDATE_WAREHOUSE_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING, });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const toggleWarehouse = (id) => (dispatch) => {
    return axiosClient.post(TOGGLE_WAREHOUSE_URL, { id });
};

export const deleteWarehouse = (id) => (dispatch) => {
    return axiosClient.post(DELETE_WAREHOUSE_URL, { id }).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING, });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};
