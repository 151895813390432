import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, Spinner, UncontrolledDropdown } from 'reactstrap';
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, ReactDataTable, RSelect } from '../../../components/Component';
import { WEBSITE_BASE_URL } from '../../../config';
import ToastExample from '../../../helpers/toast';
import Content from '../../../layout/content/Content';
import Head from '../../../layout/head/Head';
import { nonActiveFormSubmissionStatus, openFormLoading } from '../../../redux/common/actions';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { getAllItemsWithStock, toggleItem, updateItemWithStock, } from '../../../redux/admin/item/action';
import noimage from '../../../assets/images/noimage.png'

//

const StockProducts = () => {
    const dispatch = useDispatch(); //defining dispatch

    useEffect(() => {
        dispatch(getAllItemsWithStock());
    }, []);

    const getSelectors = (state) => ({
        stock_items: state.item.stock_items,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,
    });

    const { sub_categories, stock_items, warehouse, form_loading, form_submission_status, } = useSelector(getSelectors);
    const [editingItem, setEditingItem] = useState([]);   //edit Item state
    const [formData, setFormData] = useState([]);   //form data state defined
    const [stock, setStock] = useState(null);   //form data state defined
    const [id, setId] = useState(null);   //form data state defined

    //modal state
    const [modal, setModal] = useState({
        add: false,
    });

    useEffect(() => {
        dispatch(getAllItemsWithStock());
    }, []);

    const openModal = (item) => {
        setModal({ add: true });
        dispatch(nonActiveFormSubmissionStatus());
        setEditingItem(item);
        setStock(item.stock)
        setId(item.id)
    };

    //submit function
    const onFormSubmit = (e) => {
        e.preventDefault();
        dispatch(openFormLoading());
        const new_data = new FormData();
        new_data.append('stock', stock);
        new_data.append('id', id)
        dispatch(updateItemWithStock(new_data));
    };

       // toggle
       const handleStatus = (e, id) => {
        dispatch(toggleItem(id));
        setTimeout(() => {
            dispatch(getAllItemsWithStock());
        }, 500);
    };

    useEffect(() => {
        if (modal.add == true && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllItemsWithStock());
                setModal({ add: false });
            }, 500);
        }
    }, [form_loading, modal, form_submission_status]);

    //headers of datatable
    const tableHeadings = [
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Image",
            selector: (row) => row?.images?.length > 0 ? row?.images[0].id : 'No Item Found',
            compact: true,
            style: { paddingRight: "20px" },
            cell: (row) => {
                return (
                    <div className="user-card mt-2 mb-2" id={row.id}>
                        {row?.images?.length > 0 ?
                            <img src={WEBSITE_BASE_URL + row?.images[0].image} alt="item" style={{ width: '6rem', borderRadius: "5px" }} />
                            :
                            <div className='d-flex flex-column align-items-center ml-3'>
                                <img src={noimage} alt="item" style={{ height: '5vh', width: '5vh' }} />
                                <span className='m-1' style={{ color: '#330033', fontSize: '9px' }}>No image found</span>
                            </div>
                        }
                    </div>
                );
            },
        },
        {
            name: "Warehouse",
            selector: (row) => row?.warehouse ? row?.warehouse?.name : 'No Warehouse Found',
            sortable: true
        },

        {
            name: "Stock",
            selector: (row) => row?.stock,
            sortable: true
        },
        {
            name: "Status",
            selector: (row) => row.is_active,
            compact: true,
            style: { paddingRight: "20px" },

            cell: (row) => {
                return (
                    <div className="user-card mt-2 mb-2" id={row.id}>
                        <Button color={row.is_active ? "success" : "danger"}
                            onClick={(e) => handleStatus(e, row.id)}
                        >
                            {row.is_active ? <Icon name="stop-circle-fill" /> : <Icon name="play-circle-fill" />}
                        </Button>
                    </div>
                );
            },
        },

        {
            name: "Actions",
            selector: (row) => row.is_surge,
            compact: true,
            grow: 1,
            style: { paddingRight: "20px" },
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <DropdownTrans
                        row={row}
                    />
                </div>
            ),
            sortable: false,
        },
    ];
    const [sm, updateSm] = useState(false);


    //action dropdown
    const DropdownTrans = ({ row }) => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu right>
                    <ul className="link-list-plain">
                        <li>
                            <DropdownItem tag="a"
                                onClick={() => openModal(row)}
                            >
                                <Icon name="pen" /> Add Stock
                            </DropdownItem>
                        </li>
                        {/* <li>
                            <DropdownItem tag="a"
                                // onClick={() => openScheduleModal(row)}
                                href={"/schedule-item/" + row.id} >
                                <Icon name="clock" />Item Schedule
                            </DropdownItem>
                        </li>
                        <li>
                            <DropdownItem tag="a"
                                href={"/item-addon-category/" + row.id} >
                                <Icon name="link" />Item Addon Category
                            </DropdownItem>
                        </li>
                        <li>
                            <DropdownItem tag="a"
                                onClick={() => openDeleteModal(row)}
                            >
                                <Icon name="trash" />Delete
                            </DropdownItem>
                        </li> */}
                    </ul>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    return (
        <React.Fragment>
            <Head title="Items"></Head>
            {/* content */}
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Limited Stocks
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {stock_items?.length} Items.</p>
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <ReactDataTable
                    data={stock_items}
                    columns={tableHeadings}
                    expandableRows
                    pagination
                />
            </Content>



            <Modal
                isOpen={modal.add}
                toggle={() => setModal({ add: false })}
                className="modal-dialog-centered"
            >
                <ModalBody className='pt-3 pb-2'>
                    <button className="close" onClick={(ev) => { ev.preventDefault(); }}  >
                        <Icon name="cross-sm"></Icon>
                    </button>
                    <div className="p-2">
                        <h5 className="title">UPDATE STOCK</h5>
                        <div className='mt-2'>
                            <Form className="row gy-4 pt-4" onSubmit={(e) => onFormSubmit(e)} encType="multipart/formData">
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Stock</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="stock"
                                            min={0}
                                            placeholder="Enter Stock"
                                            value={stock}
                                            onChange={(e) => { setStock(e.target.value) }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <ul className="align-center justify-end flex-wrap flex-sm-nowrap gx-4 gy-2 pt-4">
                                        <li>
                                            <button onClick={(ev) => { ev.preventDefault(); }} className="link link-light"  >
                                                Cancel
                                            </button>
                                        </li>
                                        <li>
                                            <Button type="submit" color="primary" size="lg" className="btn-block">
                                                {form_loading ? (
                                                    <>
                                                        <Spinner size="sm" color="light" />Updating
                                                    </>
                                                ) : (
                                                    <React.Fragment> Update  </React.Fragment>
                                                )}
                                            </Button>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>

                        </div>
                    </div>
                </ModalBody>
            </Modal>


            {/* add and edit modal */}
            {/* <Modal
                isOpen={modal.add || modal.edit}
                toggle={() => setModal({ add: false })}
                className="modal-dialog-centered"
                size="lg"
            >
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">{modal.edit ? "Editing Item - " + editingItem?.name : "Add Item"}</h5>
                        <div className="mt-4">
                            <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/formData">
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Item Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            placeholder="Enter Item Name"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Image {imageCount ? "( 5 images )" : null}</label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            disabled={dispableImage()}
                                            onChange={(event) => {
                                                uploadSingleFile(event);
                                                event.target.value = null;
                                            }}
                                        />
                                        {errors.image && <span className="invalid">{errors.image.message}</span>}
                                    </FormGroup>
                                </Col>

                                {modal.edit && editingItem?.images.length > 0 &&
                                    editingItem?.images.map((item, index) => {
                                        return (
                                            <Col md="4">
                                                <FormGroup>
                                                    <div className='mt-2 user-select-none' key={index} style={{ boxShadow: "0px 0px 20px 8px #00000015", borderRadius: '5px' }}>
                                                        <img src={WEBSITE_BASE_URL + item.image} alt="" style={{ width: '100%', height: '10rem', borderRadius: '5px 5px 0 0' }} />
                                                        <button className='w-100' type="button" style={{ borderRadius: ' 0 0 5px 5px', backgroundColor: "#c9c9c9", border: 'none' }} onClick={() => deleteImage(editingItem.id, item.id)}>
                                                            <span style={{ fontSize: "15px", color: '#fff', fontWeight: '600' }}>delete</span>
                                                        </button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        );
                                    })}
                                {file.length > 0 &&
                                    file.map((item, index) => {
                                        return (
                                            <Col md="4">
                                                <FormGroup>
                                                    <div className='mt-2 user-select-none' key={item} style={{ boxShadow: "0px 0px 20px 8px #00000015", borderRadius: '5px' }}>
                                                        <img src={item.image} alt="" style={{ width: '100%', height: '10rem', borderRadius: '5px 5px 0 0' }} />
                                                        <button className='w-100' type="button" style={{ borderRadius: ' 0 0 5px 5px', backgroundColor: "#c9c9c9", border: 'none' }} onClick={() => deleteFile(index)}>
                                                            <span style={{ fontSize: "15px", color: '#fff', fontWeight: '600' }}>delete</span>
                                                        </button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        );
                                    })}
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Description</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="description"
                                            placeholder="Enter Description"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.description && <span className="invalid">{errors.description.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Specification</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="specification"
                                            placeholder="Enter Specification"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.specification && <span className="invalid">{errors.specification.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Warehouse</label>
                                        <div className="form-control-wrap">
                                            <Controller
                                                name="warehouse_id"
                                                control={control}
                                                render={({ onChange, value, ref }) => (
                                                    <RSelect
                                                        options={listWarehouse}
                                                        onChange={(val) => {
                                                            onChange(val.value)
                                                        }}
                                                        placeholder={"Select Warehouse"}
                                                        defaultValue={listWarehouse.find((warehouse) => warehouse.value === value)}
                                                    />
                                                )}
                                            />
                                            {errors.warehouse_id && (
                                                <span className="invalid" style={{ color: '#e85347', fontSize: '11px', fontStyle: 'italic' }}>Please choose a Store</span>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Sub Category</label>
                                        <div className="form-control-wrap">
                                            <Controller
                                                name="sub_category_id"
                                                control={control}
                                                render={({ onChange, value, ref }) => (
                                                    <RSelect
                                                        options={listSubCategory}
                                                        placeholder={"Select Sub Category"}
                                                        onChange={(val) => onChange(val.value)}
                                                        defaultValue={listSubCategory?.find((cat) => cat.value === value)}
                                                    />
                                                )}
                                            />
                                            {errors.sub_category_id && (
                                                <span className="invalid" style={{ color: '#e85347', fontSize: '11px', fontStyle: 'italic' }}>Please choose a Category</span>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">{discount ? 'Selling Price' : 'Price'}</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="sell_price"
                                            min={0}
                                            placeholder={discount ? "Enter Selling Price" : "Enter Price"}
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.price && <span className="invalid">{errors.price.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md={discount ? '4' : '0'}>
                                    <div style={discount ? { display: 'block' } : { display: 'none' }}>
                                        <FormGroup>
                                            <label className="form-label">Market Price</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="market_price"
                                                min={0}
                                                placeholder="Enter Market Price"
                                                ref={register()}
                                            />
                                        </FormGroup>
                                    </div>
                                </Col>
                                <div className='mt-4 ml-3' onClick={() => setDiscount(!discount)}>
                                    <div className='pt-1' >
                                        <Button className="btn-icon py-1 px-2 btn-dim" color="primary" size="rg" style={{ pointerEvents: 'none' }}>
                                            {discount ? <Icon name="minus-sm" /> : <><Icon name="plus-sm" />Add Discount</>}
                                        </Button>
                                    </div>
                                </div>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Quantity(minimum)</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="min_quantity"
                                            min={0}
                                            placeholder="Enter Minimum Quantity"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.min_quantity && <span className="invalid">{errors.min_quantity.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Quantity(maximum)</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="max_quantity"
                                            min={0}
                                            placeholder="Enter Maximum Quantity"
                                            ref={register()}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Stock</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="stock"
                                            min={0}
                                            placeholder="Enter Stock"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.stock && <span className="invalid">{errors.stock.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup >
                                        <div className="custom-control-sm custom-switch">
                                            <input type="radio" className="custom-control-input" id="popular" checked={popular} onClick={() => setPopular(!popular)} />
                                            <label className="custom-control-label user-select-none" htmlFor="popular">Is Popular </label>
                                        </div>
                                    </FormGroup>
                                    <FormGroup >
                                        <div className="custom-control-sm custom-switch">
                                            <input type="radio" className="custom-control-input" id="express_delivery" checked={expressDelivery} onClick={() => setExpressDelivery(!expressDelivery)} />
                                            <label className="custom-control-label user-select-none" htmlFor="express_delivery">Is Express Delivery </label>
                                        </div>
                                    </FormGroup>
                                    <FormGroup >
                                        <div className="custom-control-sm custom-switch">
                                            <input type="radio" className="custom-control-input" id="recommend" checked={recommended} onClick={() => setRecommended(!recommended)} />
                                            <label className="custom-control-label user-select-none" htmlFor="recommend">Is Recommended </label>
                                        </div>
                                    </FormGroup>
                                    <FormGroup >
                                        <div className="custom-control-sm custom-switch">
                                            <input type="radio" className="custom-control-input" id="veg" checked={veg} onClick={() => setVeg(!veg)} />
                                            <label className="custom-control-label user-select-none" htmlFor="veg">Is Veg </label>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button type="submit" color="primary" size="lg" className="btn-block">
                                                {form_loading ? (
                                                    <>
                                                        <Spinner size="sm" color="light" /> {modal.edit ? "Updating Item" : "Creating New Item"}
                                                    </>
                                                ) : (
                                                    <React.Fragment>{modal.edit ? "Update Item" : "Add New Item"}</React.Fragment>
                                                )}
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    onFormCancel();
                                                }}
                                                className="link link-light"
                                            >
                                                Cancel
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal> */}

            {/* delete modal */}
            {/* <Modal
                isOpen={modal.delete}
                toggle={() => setModal({ delete: false })}
                className="modal-dialog-centered"
            >
                <ModalBody className='pt-3 pb-2'>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Delete Item{' '}
                            <Badge color="primary">
                                {deletingItem?.name}
                            </Badge>
                            {' '}
                            ?
                        </h5>
                        <div className='mt-2'>
                            <span>This Item will be deleted. Do you really want to delete this item ?</span>
                        </div>
                        <div className="mt-3">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4">
                                <li>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        onFormSubmit();
                                    }} color="danger" className="px-4 btn-block">
                                        {form_loading ? (
                                            <>
                                                <Spinner size="sm" color="light" />Deleting
                                            </>
                                        ) : (
                                            <React.Fragment>Delete</React.Fragment>
                                        )}
                                    </Button>
                                </li>
                                <li>
                                    <a
                                        href="#cancel"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            onFormCancel();
                                        }}
                                        className="link link-light"
                                    >
                                        Cancel
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ModalBody>
            </Modal> */}

        </React.Fragment>
    );
}

export default StockProducts
