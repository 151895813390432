import React, { useEffect, useMemo, useState } from 'react'
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, Spinner, UncontrolledDropdown } from 'reactstrap';
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, ReactDataTable, RSelect, UserAvatar } from '../../../../components/Component';
import ToastExample from '../../../../helpers/toast';
import Content from '../../../../layout/content/Content';
import Head from '../../../../layout/head/Head';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAllRoles, nonActiveFormSubmissionStatus, openFormLoading } from '../../../../redux/common/actions';
import * as yup from "yup";
import { string } from "yup";
import { createUser, deleteUser, getAllUsers } from '../../../../redux/admin/user/action';
import { getAllCities } from '../../../../redux/admin/cities/actions';
import { findUpper } from '../../../../utils/Utils';
import { IMAGE_BASE_URL, WEBSITE_BASE_URL } from '../../../../config';



const Users = () => {

    // get data from redux state
    const getSelectors = (state) => ({
        cities: state.cities.cities,
        warehouses: state.warehouse.warehouse,
        roles: state.common.roles,
        users: state.users.all_users,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,
    });

    const { cities, warehouses, roles, users, form_loading, form_submission_status, } = useSelector(getSelectors);
    const dispatch = useDispatch();

    // modal state
    const [modal, setModal] = useState({
        edit: false,
        add: false,
        delete: false,

    });
    const [formData, setFormData] = useState([]);
    const [data, setData] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [isDeliveryPartner, setIsDeliveryPartner] = useState(false); // check user is a delivery partner
    const [addUser, setAddUser] = useState([]); // state to add and edit
    const [deleteingUser, setDeleteingUser] = useState([]); // for deleting addon
    const [cityId, setCityId] = useState(null); // for deleting addon
    const [warehouseId, setWarehouseId] = useState(null);


    // function to close the form modal
    const onFormCancel = () => {
        setModal({ edit: false, add: false, delete: false });
        setIsDeliveryPartner(false)
        resetForm();
    };
    // function to reset the form
    const resetForm = () => {
        setFormData({
            name: "",
            phone: "",
            email: "",
            password: "",
            role: "",
            image: "",
            nick_name: "",
            age: "",
            city_id: "",
            gender: "",
            description: "",
            commission_rate: "",
            most_accept_delivery_limit: "",
            licence_number: "",
            adhaar_number: "",


        });
    };
    // form validation schema
    const userSchema = yup.object({
        name: string().required(),
        phone: string()
            .matches(/^[6-9][0-9]{9}$/, 'Invalid phone number')
            .required('Phone number is required'),
        image: yup.mixed()
            .test("fileSize", "File too large", (value) => {

                if (value[0]) {
                    return value[0].size <= 200 * 1024; // 1 MB
                }
                return true;
            })
            .test("fileFormat", "Unsupported format", (value) => {
                if (value[0]) {
                    return ["image/jpeg", "image/png"].includes(value[0].type);
                }
                return true;
            }),
        email: string().required(),
        password: string().required(),
        role: string().required(),
        nick_name: isDeliveryPartner ? string().required() : string(),
        age: isDeliveryPartner ? yup.number().required() : string(),
        city_id: isDeliveryPartner ? string().required() : string(),
        gender: isDeliveryPartner ? string().required() : string(),
        description: isDeliveryPartner ? string().required() : string(),
        commission_rate: isDeliveryPartner ? string().required() : string(),
        most_accept_delivery_limit: isDeliveryPartner ? string().required() : string(),
        licence_number: isDeliveryPartner ? string().required() : string(),
        adhaar_number: isDeliveryPartner ? string().required() : string(),


    });

    // schema to edit form data
    const defaultValues = useMemo(
        () => ({
            id: "",
            name: "",
            phone: "",
            email: "",
            password: "",
            role: "",
            image: "",
            nick_name: "",
            age: "",
            city_id: "",
            gender: "",
            description: "",
            commission_rate: "",
            most_accept_delivery_limit: "",
            licence_number: "",
            adhaar_number: "",


        }),
        [addUser]
    );

    const methods = useForm({
        resolver: yupResolver(userSchema),
        defaultValues: defaultValues,
    });

    const {
        register,
        handleSubmit,
        setError,
        watch,
        control,
        formState: { errors },
        unregister,
        reset,
    } = methods;

    // get all users  onload
    useEffect(() => {
        dispatch(getAllUsers())
        dispatch(getAllRoles())
        dispatch(getAllCities())

    }, [])

    // add
    const openAddModal = () => {
        setAddUser([]);
        dispatch(nonActiveFormSubmissionStatus());
        setTimeout(() => {
            setModal({ add: true });
        }, 100);
    };

    // delete
    const openDeleteModal = (user) => {
        setDeleteingUser(user)
        dispatch(nonActiveFormSubmissionStatus());
        setTimeout(() => {
            setModal({ delete: true });
        }, 100);
    };

    // useEffect which watches the formsubmission of delete and create.
    useEffect(() => {
        if (modal.add == true && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllUsers());
                setData(users);
                setModal({ add: false });
                setShowToast(true);
            }, 500);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
        if (modal.delete == true && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllUsers());
                setData(users);
                setModal({ delete: false });
                setShowToast(true);
            }, 500);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }

    }, [addUser, form_loading, modal, form_submission_status]);

    // submit data to api
    const onFormSubmit = (submitData) => {
        dispatch(openFormLoading());

        if (modal.add) {
            // submit data is appended to formdata for sending image
            const form_data = new FormData();
            form_data.append('name', submitData?.name);
            form_data.append('password', submitData?.password);
            form_data.append('email', submitData?.email);
            form_data.append('phone', submitData?.phone);
            form_data.append('role', submitData?.role);
            if (submitData?.image?.length) {

                form_data.append('image', submitData?.image[0]);
            }

            // data for delivert partner user only
            if (isDeliveryPartner) {
                form_data.append('nick_name', submitData?.nick_name);
                form_data.append('age', submitData?.age);
                form_data.append('city_id', submitData?.city_id);
                form_data.append('gender', submitData?.gender);
                form_data.append('description', submitData?.description);
                form_data.append('commission_rate', submitData?.commission_rate);
                form_data.append('most_accept_delivery_limit', submitData?.most_accept_delivery_limit);
                form_data.append('licence_number', submitData?.licence_number);
                form_data.append('adhaar_number', submitData?.adhaar_number);
            }

            dispatch(createUser(form_data));
        }
        else if (modal.delete) {
            dispatch(deleteUser(deleteingUser?.id));
        }
    };

    const UserHeading = [
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            cell: (row) => {
                return (
                    <div className="user-card">
                        {/* {row.image &&
                            <img src={WEBSITE_BASE_URL + row.image}
                                className="user-image-round my-2"
                                alt='howin_user'
                            />
                        } */}
                        <div className=" ">
                            {row.name}
                        </div>
                    </div>

                );
            },
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            hide: 370,
        },
        {
            name: "Phone",
            selector: (row) => row.phone,
            sortable: true,
            hide: 370,
        },
        {
            name: "Status",
            selector: (row) => row.is_banned,
            compact: true,
            style: { paddingRight: "20px" },

            cell: (row) => {
                return (
                    <Badge className="badge-dot" color={row?.is_active == '0' ? "danger" : "success"}>
                        {row?.is_active == '0' ? "Inactive" : "Active"}
                    </Badge>

                );
            },
        },
        {
            name: "Role",
            compact: true,
            style: { paddingRight: "20px" },
            cell: (row) => {
                return (
                    <Badge pill color="secondary">
                        {row?.roles && row?.roles[0] ? row?.roles[0]?.name : "User Has No Role"}
                    </Badge>

                );
            },
        },
        {
            name: "Actions",
            compact: true,
            grow: 1,
            style: { paddingRight: "20px" },
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <DropdownTrans row={row} />

                </div>

            ),
            sortable: false,
        },
    ];

    const DropdownTrans = ({ row }) => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu right>
                    <ul className="link-list-plain">
                        <li>
                            <DropdownItem tag="a" href={'/edit-user/' + row.id}>
                                <Icon name="pen" />
                                Edit
                            </DropdownItem>
                        </li>
                        <li>
                            <DropdownItem
                                tag="a"
                                onClick={(e) => { openDeleteModal(row) }}
                            >
                                <Icon name="trash" />
                                Delete
                            </DropdownItem>
                        </li>

                    </ul>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    // data formated for selectbox
    const allRoles = roles?.map((role) => (
        { value: role.id, label: role.name }

    ));

    const all_cities = cities?.map((city) => (
        { value: city.id, label: city.name }
    ));

    const all_warehouses = warehouses.filter(cp => cp.city_id == cityId).map((warehouse) => (
        { value: warehouse.id, label: warehouse.name }
    ));

    const gender = [
        { value: "MALE", label: "MALE" },
        { value: "FEMALE", label: "FEMALE" },
    ];
    return (
        <React.Fragment>

            <Head title="Users "></Head>

            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Users
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {users?.length} Users .</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <ToastExample show={showToast} />


                                <div className="toggle-expand-content" >
                                    <ul className="nk-block-tools g-3">

                                        <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon"
                                                onClick={() => openAddModal()}
                                            >
                                                <Icon name="plus"></Icon>

                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>


                {/* datatable */}
                <ReactDataTable
                    data={users}
                    columns={UserHeading}

                    pagination
                />

            </Content>


            {/* add modal */}
            <Modal
                isOpen={modal.add}
                toggle={() => {
                    setModal({ add: false })
                    setIsDeliveryPartner(false)
                }}
                className="modal-dialog-centered"
                size="lg"
            >
                <ModalBody>
                    <a
                        href="#close"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Add User</h5>
                        <div className="mt-4">
                            <Form className="row gy-4" method='POST' onSubmit={handleSubmit(onFormSubmit)}>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"

                                            placeholder="Enter name"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Image</label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            name="image"

                                            ref={register}

                                        />
                                        {errors.image && <span className="invalid">{errors.image.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Email </label>
                                        <input
                                            className="form-control"
                                            type="email"
                                            name="email"

                                            placeholder="Enter email"
                                            ref={register({
                                                required: "This field is required",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "invalid email address",
                                                },
                                            })}
                                        />
                                        {errors.email && <span className="invalid">{errors.email.message}</span>}
                                    </FormGroup>
                                </Col>

                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Password</label>
                                        <input
                                            className="form-control"
                                            type="password"
                                            name="password"

                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.password && <span className="invalid">{errors.password.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Phone</label>
                                        <input
                                            className="form-control"
                                            type="tel"
                                            name="phone"

                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Roles</label>
                                        <div className="form-control-wrap">
                                            <Controller
                                                name="role"
                                                control={control}
                                                render={({ onChange, value, ref }) => (
                                                    <RSelect
                                                        options={allRoles}
                                                        onChange={(val) => {
                                                            onChange(val.value)
                                                            if (val.value === 4) {
                                                                setIsDeliveryPartner(true);
                                                            } else {
                                                                setIsDeliveryPartner(false);

                                                            }
                                                        }}
                                                        defaultValue={allRoles.find((c) => c.value === value)}
                                                    />
                                                )}
                                                ref={register({ required: "This field is required" })}
                                            />
                                            {errors.role && (
                                                <span className="invalid text-danger text-italic">Please choose a Role </span>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>

                                {/* if only the user role is delivery partner */}
                                {isDeliveryPartner &&
                                    <React.Fragment>
                                        <div className='w-100'>
                                            <hr style={{
                                                border: "0.5px solid grey",

                                            }} />

                                            <h5 className='px-3'>Delivery Guy Details</h5>
                                        </div>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Nick Name</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="nick_name"
                                                    placeholder="Enter Nick Name"
                                                    ref={register({ required: "This field is required" })}
                                                />
                                                {errors.nick_name && <span className="invalid">{errors.nick_name.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Age</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="age"
                                                    placeholder="Enter Age"
                                                    ref={register({ required: "This field is required" })}
                                                />
                                                {errors.age && <span className="invalid">{errors.age.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">City</label>
                                                <div className="form-control-wrap">
                                                    <Controller
                                                        name="city_id"
                                                        control={control}
                                                        render={({ onChange, value, ref }) => (
                                                            <RSelect
                                                                options={all_cities}
                                                                onChange={(val) => { onChange(val.value); setCityId(val.value) }}
                                                                defaultValue={all_cities.find((c) => c.value === cityId)}
                                                            />
                                                        )}
                                                        ref={register({ required: "This field is required" })}
                                                    />
                                                    {errors.city_id && (
                                                        <span className="invalid text-danger text-italic">Please choose a city</span>
                                                    )}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Warehouse</label>
                                                <div className="form-control-wrap">
                                                    <Controller
                                                        name="city_id"
                                                        control={control}
                                                        render={({ onChange, value, ref }) => (
                                                            <RSelect
                                                                options={all_warehouses}
                                                                onChange={(val) => {onChange(val.value); setWarehouseId(val.value)}}
                                                                value={all_warehouses.filter(c => c?.value === warehouseId)}
                                                                defaultValue={all_warehouses.find((c) => c.value === warehouseId)}
                                                            />
                                                        )}
                                                        ref={register({ required: "This field is required" })}
                                                    />
                                                    {errors.city_id && (
                                                        <span className="invalid text-danger text-italic">Please choose a city</span>
                                                    )}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Gender</label>
                                                <div className="form-control-wrap">
                                                    <Controller
                                                        name="gender"
                                                        control={control}
                                                        render={({ onChange, value, ref }) => (
                                                            <RSelect
                                                                options={gender}
                                                                onChange={(val) => onChange(val.value)}
                                                                defaultValue={gender.find((c) => c.value === value)}
                                                            />
                                                        )}
                                                        ref={register({ required: "This field is required" })}
                                                    />
                                                    {errors.gender && (
                                                        <span className="invalid text-danger text-italic">Please select Your Gender</span>
                                                    )}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Description</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="description"
                                                    placeholder="Enter a description"
                                                    ref={register()}
                                                />

                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Commission Rate</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    min="0"
                                                    max="100"
                                                    name="commission_rate"
                                                    placeholder="Enter commission rate"
                                                    ref={register({ required: "This field is required" })}
                                                />
                                                {errors.commission_rate && <span className="invalid">{errors.commission_rate.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Delivery Limit</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    min="0"
                                                    name="most_accept_delivery_limit"
                                                    placeholder="Enter Limit"
                                                    ref={register({ required: "This field is required" })}
                                                />

                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Licence Number</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="licence_number"
                                                    ref={register({
                                                        required: "This field is required",
                                                    })}
                                                    placeholder="Enter Licence Number"
                                                />
                                                {errors.licence_number && <span className="invalid">{errors.licence_number.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Adhaar Number</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    minLength="12"
                                                    maxLength="12"
                                                    name="adhaar_number"
                                                    ref={register({
                                                        required: "This field is required",
                                                    })}
                                                    placeholder="Enter Adhaar Number"
                                                />
                                                {errors.adhaar_number && <span className="invalid">{errors.adhaar_number.message}</span>}
                                            </FormGroup>
                                        </Col>
                                    </React.Fragment>
                                }
                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button type="submit" color="primary" size="lg" className="btn-block">
                                                {form_loading ? (
                                                    <>
                                                        <Spinner size="sm" color="light" /> Creating New User
                                                    </>
                                                ) : (
                                                    <React.Fragment>Add New User</React.Fragment>
                                                )}
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    onFormCancel();
                                                }}
                                                className="link link-light"
                                            >
                                                Cancel
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>



            {/* delete modal */}
            <Modal
                isOpen={modal.delete}
                toggle={() => setModal({ delete: false })}
                className="modal-dialog-centered"
            // size="lg"
            >
                <ModalBody className="pt-3 pb-2">
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">
                            Delete User <Badge color="primary">{deleteingUser?.name}</Badge> ?

                        </h5>
                        <div className="mt-2">
                            <span>This User will be deleted. Do you really want to delete this User ?</span>
                        </div>
                        <div className="mt-3">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4">
                                <li>
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onFormSubmit();
                                        }}
                                        color="danger"
                                        className="px-4 btn-block"
                                    >
                                        {form_loading ? (
                                            <>
                                                <Spinner size="sm" color="light" />
                                                Deleting
                                            </>
                                        ) : (
                                            <React.Fragment>Delete</React.Fragment>
                                        )}
                                    </Button>
                                </li>
                                <li>
                                    <a
                                        href="#cancel"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            onFormCancel();
                                        }}
                                        className="link link-light"
                                    >
                                        Cancel
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default Users
