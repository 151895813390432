import axiosClient from "../../../helpers/axios-client";
import { CLOSE_FORM_LOADING, FORM_SUBMISSION_STATUS } from "../../common/actionTypes";
import {
    GET_ALL_BRANDS_URL,
    CREATE_BRAND_URL,
    UPDATE_BRAND_URL,
    TOGGLE_BRAND_URL,
    DELETE_BRAND_URL,
    BULK_UPLOAD_BRANDS_URL,

    GET_SINGLE_ITEM_URL,
    GET_ALL_ITEMS_URL,
    CREATE_ITEM_URL,
    UPDATE_ITEM_URL,
    TOGGLE_ITEM_URL,
    DELETE_ITEM_URL,
    DELETE_ITEM_IMAGE_URL,
    BULK_UPLOAD_ITEM_URL,

    GET_ALL_ITEMS_WITH_STOCK_URL,
    UPDATE_ITEM_WITH_STOCK_URL,

    GET_SINGLE_ITEM_VARIANTS_URL,
    GET_ALL_ITEM_VARIANTS_URL,
    CREATE_ITEM_VARIANT_URL,
    UPDATE_ITEM_VARIANT_URL,
    TOGGLE_ITEM_VARIANT_URL,
    DELETE_ITEM_VARIANT_URL,
    DELETE_VARIANT_IMAGE_URL,
    BULK_UPLOAD_ITEM_VARIANT_URL,

    GET_ITEM_ADDON_CATEGORY_URL,
    UPDATE_ITEM_ADDON_CATEGORY_URL,

    GET_ITEM_SCHEDULE_DATA_URL,
    UPDATE_ITEM_SCHEDULE_DATA_URL,
    TOGGLE_BRAND_POPULAR_URL,
} from "../../../config";

import {
    GET_ALL_BRANDS,
    GET_ALL_ITEMS,
    GET_SINGLE_ITEM,
    GET_ALL_ITEMS_WITH_STOCK,
    GET_ALL_ITEM_VARIANTS,
    GET_SINGLE_ITEM_VARIANTS,
    GET_ALL_ITEM_GROUPS,
    GET_ITEM_ADDON_CATEGORY,
    UPDATE_ITEM_ADDON_CATEGORY,
    GET_ITEM_SCHEDULE_DATA,
} from './actionType';


//--------------------------------Brand Start-------------------------------

export const getAllBrands = () => (dispatch) => {
    return axiosClient.post(GET_ALL_BRANDS_URL).then((response) => {
        if (response && response.data.success) {
            const categories = response.data.data;
            return dispatch({ type: GET_ALL_BRANDS, payload: categories });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const createBrand = (data) => (dispatch) => {
    const config = {
        headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', }
    }
    return axiosClient.post(CREATE_BRAND_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const updateBrand = (data, id) => (dispatch) => {
    const config = {
        headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' }
    }

    return axiosClient.post(UPDATE_BRAND_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};

export const toggleBrand = (id) => (dispatch) => {
    return axiosClient.post(TOGGLE_BRAND_URL, { id });
};

export const toggleBrandPopular = (id) => (dispatch) => {
    return axiosClient.post(TOGGLE_BRAND_POPULAR_URL, { id });
};

export const deleteBrand = (id) => (dispatch) => {
    return axiosClient.post(DELETE_BRAND_URL, { id }).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const bulkUploadBrands = (data) => (dispatch) => {
    const config = { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', } }
    return axiosClient.post(BULK_UPLOAD_BRANDS_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};
//--------------------------------Brand End-------------------------------


//--------------------------------Item Start-------------------------------

export const getAllItems = () => (dispatch) => {
    return axiosClient
        .post(GET_ALL_ITEMS_URL)
        .then((response) => {
            if (response && response.data.success) {
                const items = response.data.data;
                return dispatch({ type: GET_ALL_ITEMS, payload: items });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const createItem = (data) => (dispatch) => {
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    }
    return axiosClient
        .post(CREATE_ITEM_URL, data, config)
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });
            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
        });
};

export const updateItem = (data) => (dispatch) => {
    console.log(data, 'actionnnn')
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    }
    return axiosClient
        .post(UPDATE_ITEM_URL, data, config)
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });
            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
        });
};

export const toggleItem = (id) => (dispatch) => {
    return axiosClient.post(TOGGLE_ITEM_URL, {
        id,
    });
};

export const deleteItem = (id) => (dispatch) => {
    return axiosClient.post(DELETE_ITEM_URL, {
        id,
    }).then((response) => {
        dispatch({
            type: CLOSE_FORM_LOADING,
        });
        dispatch({
            type: FORM_SUBMISSION_STATUS,
            payload: true,
        });
    });
};

export const deleteItemImage = (data) => (dispatch) => {
    return axiosClient.post(DELETE_ITEM_IMAGE_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING, });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};

export const bulkUploadItem = (data) => (dispatch) => {
    const config = { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', } }
    return axiosClient.post(BULK_UPLOAD_ITEM_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};


// VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV Stock VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV
export const getAllItemsWithStock = () => (dispatch) => {
    return axiosClient
        .post(GET_ALL_ITEMS_WITH_STOCK_URL)
        .then((response) => {
            if (response && response.data.success) {
                const items = response.data.data;
                return dispatch({ type: GET_ALL_ITEMS_WITH_STOCK, payload: items });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const updateItemWithStock = (data) => (dispatch) => {
    return axiosClient
        .post(UPDATE_ITEM_WITH_STOCK_URL, data)
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });
            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
        });
};
//--------------------------------Item End-------------------------------



//--------------------------------Item Variants Start-------------------------------

export const getAllItemVariants = () => (dispatch) => {
    return axiosClient
        .post(GET_ALL_ITEM_VARIANTS_URL)
        .then((response) => {
            if (response && response.data.success) {
                const items = response.data.data;
                return dispatch({ type: GET_ALL_ITEM_VARIANTS, payload: items });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getSingleItemVariants = (formData) => (dispatch) => {
    return axiosClient
        .post(GET_SINGLE_ITEM_VARIANTS_URL,formData)
        .then((response) => {
            if (response && response.data.success) {
                const items = response.data.data;
                return dispatch({ type: GET_SINGLE_ITEM_VARIANTS, payload: items });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const createItemVariant = (data) => (dispatch) => {
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    }
    return axiosClient
        .post(CREATE_ITEM_VARIANT_URL, data, config)
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });
            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
        });
};

export const updateItemVariant = (data) => (dispatch) => {
    console.log(data, 'actionnnn')
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    }
    return axiosClient
        .post(UPDATE_ITEM_VARIANT_URL, data, config)
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });
            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
        });
};

export const toggleItemVariant = (id) => (dispatch) => {
    return axiosClient.post(TOGGLE_ITEM_VARIANT_URL, {
        id,
    });
};

export const deleteItemVariant = (id) => (dispatch) => {
    return axiosClient.post(DELETE_ITEM_VARIANT_URL, { id }).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING, });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};


export const deleteVariantImage = (data) => (dispatch) => {
    return axiosClient.post(DELETE_VARIANT_IMAGE_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING, });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};

export const bulkUploadItemVariant = (data) => (dispatch) => {
    const config = { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', } }
    return axiosClient.post(BULK_UPLOAD_ITEM_VARIANT_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};
//--------------------------------Item Variants End-------------------------------


// -------------------------------Item Addon Category Start-----------------------------------

export const getItemAddonCategory = (formData) => (dispatch) => {
    return axiosClient
        .post(GET_ITEM_ADDON_CATEGORY_URL, formData)
        .then((response) => {
            if (response && response.data.success) {
                const item = response.data;
                return dispatch({ type: GET_ITEM_ADDON_CATEGORY, payload: item });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const updateItemAddonCategory = (formData) => (dispatch) => {
    return axiosClient
        .post(UPDATE_ITEM_ADDON_CATEGORY_URL, formData)
        .then((response) => {
            if (response && response.data.success) {
                const item = response.data;
                return dispatch({ type: UPDATE_ITEM_ADDON_CATEGORY, payload: item });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};


// -------------------------------Item Addon Category End-----------------------------------


export const getItemScheduleData = (id) => (dispatch) => {
    return axiosClient
        .post(GET_ITEM_SCHEDULE_DATA_URL, { id })
        .then((response) => {
            if (response && response.data.success) {
                const data = response.data.data;
                return dispatch({ type: GET_ITEM_SCHEDULE_DATA, payload: data });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}
export const updateItemScheduleData = (id, schedule_data) => (dispatch) => {
    return axiosClient
        .post(UPDATE_ITEM_SCHEDULE_DATA_URL, { id, schedule_data })
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });
            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
        });
};

export const getSingleItem = (id) => (dispatch) => {
    return axiosClient
        .post(GET_SINGLE_ITEM_URL, { id })
        .then((response) => {
            if (response && response.data.success) {
                const item = response.data.data;
                return dispatch({ type: GET_SINGLE_ITEM, payload: item });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};
