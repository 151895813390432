import React from "react";
import { Route, Redirect } from "react-router-dom";
import store from "../redux/store";
import { Provider } from "react-redux";

const auth = localStorage.getItem("accessToken");

const PrivateRoute = ({ exact, component: Component, ...rest }) => (
  <Root>
    <Route
      exact={exact ? true : false}
      rest
      render={(props) =>
        auth ? (
          <Component {...props} {...rest}></Component>
        ) : (
            <>
          <Redirect to={`${process.env.PUBLIC_URL}/auth-login`}></Redirect>
          <Redirect to={`${process.env.PUBLIC_URL}/sign-up`}></Redirect>
          <Redirect to={`${process.env.PUBLIC_URL}/auth-reset`}></Redirect>
            </>
        )
      }
    ></Route>
  </Root>
);

const Root = ({ children, initialState = {} }) => <Provider store={store(initialState)}>{children}</Provider>;
export default PrivateRoute;
