import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Button, Card, CardBody, CardHeader, CardText, CardTitle, Col, Form, Row } from 'reactstrap'
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle } from '../../../../../components/Component'
import { WEBSITE_BASE_URL } from '../../../../../config'
import ToastExample from '../../../../../helpers/toast'
import Content from '../../../../../layout/content/Content'
import Head from '../../../../../layout/head/Head'

import { getSingleUser, manageDeliveryPartner, manageStoreOwner } from '../../../../../redux/admin/user/action'
import { nonActiveFormSubmissionStatus, openFormLoading } from '../../../../../redux/common/actions'
import "./managedelivery.css"
import StoreCard from './StoreCard'
import { getAllWarehouses } from '../../../../../redux/admin/warehouse/action'

function ManageDeliveryStore() {

    const dispatch = useDispatch();

    const getSelectors = (state) => ({
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,
        user: state.users.single_user,
        warehouse: state.warehouse.warehouse,
    });

    const { user, warehouse, form_loading, form_submission_status } = useSelector(getSelectors);
    console.log("warehouses", warehouse)

    const { id } = useParams();

    // toggle manage btn
    const [active, setActive] = useState(false)

    // to store all saved storeids of the delivery partner
    const [activeIds, setActiveIds] = useState([])

    // to store user selected values
    const [selected, setSelected] = useState([])


    const [showToast, setShowToast] = useState(false)

    // get all stores and user data
    useEffect(() => {
        dispatch(getAllWarehouses()).then((response) => {
            setActiveIds(user?.warehouse_ids ?? [])
            setSelected(user?.warehouse_ids?? [])
        })
        dispatch(getSingleUser(id))

    }, [])

    const toggleManage = () => {
        dispatch(nonActiveFormSubmissionStatus());
        setActive(!active)

    }

    useEffect(() => {
        if (active == true && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getSingleUser(id))
                setShowToast(true)
            }, 500);

            setTimeout(() => {

                setShowToast(false)
            }, 3000);
        }
    }, [active, form_loading, form_submission_status])

    const handleSelectedStore = (childData,status) => {

            if(status){

                setSelected([...selected,childData]);
            }else{
                setSelected(selected?.filter((id) => id !== childData))
            }
    };


    const onFormSubmit = (e) => {
        e.preventDefault();
        dispatch(openFormLoading());

        dispatch(manageDeliveryPartner(id,selected))

    }
    return (
      
        <React.Fragment>
            <Head title="Manage Delivery Partners"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Manage Delivery Partners
                            </BlockTitle>

                        </BlockHeadContent>
                        <BlockHeadContent>
                            <ToastExample show={showToast} />

                        </BlockHeadContent>

                    </BlockBetween>
                </BlockHead>
                <Card className="card-bordered">
                    <CardBody className="card-inner">
                        <CardTitle tag="h5">{user?.name} - Delivery Partner</CardTitle>
                        <Button color="primary" onClick={() => toggleManage()}>Manage</Button>
                    </CardBody>
                </Card>
                {active &&
                    <Block>
                        <Row>
                            <div className="col-12">
                                <Form onSubmit={(e) => onFormSubmit(e)}>
                                    <Row className='mt-4'>
                                        {warehouse?.map((store) => (
                                            <StoreCard key={store.id} store={store} activeIds={activeIds} sentData={handleSelectedStore} />
                                        ))}
                                    </Row>
                                    <div className='d-flex justify-content-end'>
                                        <Button color="primary" size='lg' type='submit'>Update</Button>
                                    </div>
                                </Form>
                            </div>
                        </Row>
                    </Block>

                }
            </Content>

        </React.Fragment>
    )
}

export default ManageDeliveryStore
