import UserPic from "../../../../images/avatar/b-sm.jpg";

export const recentOrderData = [
  {
    order: "#95954",
    img: null,
    initial: "AB",
    theme: "purple-dim",
    name: "Abu Bin Ishtiak",
    date: "02/11/2020",
    ref: "SUB-2309232",
    amount: "4596.75",
    status: "Paid",
  },
  {
    order: "#95850",
    img: null,
    initial: "DE",
    theme: "primary-dim",
    name: "Desire Edwards",
    date: "02/02/2020",
    ref: "SUB-2309154",
    amount: "596.75",
    status: "Cancelled",
  },
  {
    order: "#95810",
    img: UserPic,
    initial: "DE",
    theme: "danger-dim",
    name: "Desire Edwards",
    date: "02/01/2020",
    ref: "SUB-2309143",
    amount: "199.99",
    status: "Paid",
  },
  {
    order: "#95810",
    img: null,
    initial: "NL",
    theme: "purple-dim",
    name: "Naomi Lawrence",
    date: "01/29/2020",
    ref: "SUB-2305984",
    amount: "1099.99",
    status: "Paid",
  },
  {
    order: "#95135",
    img: null,
    initial: "NL",
    theme: "success-dim",
    name: "Naomi Lawrence",
    date: "01/29/2020",
    ref: "SUB-2305564",
    amount: "1099.99",
    status: "Due",
  },
];
