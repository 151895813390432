import { COLLECT_DELIVERY_COLLECTION_URL, GET_ALL_COMPLETED_STORE_PAYOUTS_URL, GET_ALL_DELIVERY_COLLECTION_LOGS_URL, GET_ALL_DELIVERY_COLLECTION_URL, GET_ALL_PENDING_STORE_PAYOUTS_URL, GET_SINGLE_USER_DELIVERY_COLLECTION_LOGS_URL, RELEASE_STORE_PAYOUT_URL } from "../../../config";
import axiosClient from "../../../helpers/axios-client";
import { CLOSE_FORM_LOADING, FORM_SUBMISSION_STATUS } from "../../common/actionTypes";
import { DELIVERY_COLLECTION_COLLECTED, GET_ALL_COMPLETED_STORE_PAYOUTS, GET_ALL_DELIVERY_COLLECTION, GET_ALL_DELIVERY_COLLECTION_LOGS, GET_ALL_PENDING_STORE_PAYOUTS, GET_SINGLE_USER_DELIVERY_COLLECTION_LOGS, PAYOUT_REALEASED } from "./actionType";


//all store payout
export const getStorePayouts = (start, end) => (dispatch) => {
    return axiosClient
        .post(GET_ALL_PENDING_STORE_PAYOUTS_URL, { start, end })
        .then((response) => {
            if (response && response.data.success) {
                const payouts = response.data.data;
                return dispatch({ type: GET_ALL_PENDING_STORE_PAYOUTS, payload: payouts });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};


// release payout
export const releasePayout = (orderIds, payout_amount, store_id, message) => (dispatch) => {

    return axiosClient
        .post(RELEASE_STORE_PAYOUT_URL, { orderIds, payout_amount, store_id, message })
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });
            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
            if (response && response.data.success) {
                const data = response.data;
                return dispatch({ type: PAYOUT_REALEASED, payload: data });
            }
        });
};


//all completed store payout
export const getAllCompletedStorePayouts = () => (dispatch) => {
    return axiosClient
        .post(GET_ALL_COMPLETED_STORE_PAYOUTS_URL,)
        .then((response) => {
            if (response && response.data.success) {
                const completed = response.data.data;
                return dispatch({ type: GET_ALL_COMPLETED_STORE_PAYOUTS, payload: completed });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

//all delivery collection
export const getAllDeliveryCollection = (city_id, from, to) => (dispatch) => {
    return axiosClient
        .post(GET_ALL_DELIVERY_COLLECTION_URL, { city_id, from, to })
        .then((response) => {
            if (response && response.data.success) {
                const completed = response.data;
                return dispatch({ type: GET_ALL_DELIVERY_COLLECTION, payload: completed });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};


// collect delivery collection

export const collectDeliveryCollection = (user_id, from, to, message) => (dispatch) => {
    return axiosClient
        .post(COLLECT_DELIVERY_COLLECTION_URL, { user_id, from, to, message })
        .then((response) => {
            dispatch({
                type: CLOSE_FORM_LOADING,
            });
            dispatch({
                type: FORM_SUBMISSION_STATUS,
                payload: true,
            });
            if (response && response.data.success) {
                const data = response.data;
                return dispatch({ type: DELIVERY_COLLECTION_COLLECTED, payload: data });
            }
        });
};


//all delivery collection log
export const getAllDeliveryCollectionLogs = (city_id, from, to) => (dispatch) => {
    return axiosClient
        .post(GET_ALL_DELIVERY_COLLECTION_LOGS_URL, { city_id, from, to })
        .then((response) => {
            if (response && response.data.success) {
                const completed = response.data;
                return dispatch({ type: GET_ALL_DELIVERY_COLLECTION_LOGS, payload: completed });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

//get single user delivery collection log
export const getSingleUserDeliveryCollectionLogs = (id) => (dispatch) => {
    return axiosClient
        .post(GET_SINGLE_USER_DELIVERY_COLLECTION_LOGS_URL, { id })
        .then((response) => {
            if (response && response.data.success) {
                const completed = response.data;
                return dispatch({ type: GET_SINGLE_USER_DELIVERY_COLLECTION_LOGS, payload: completed });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};
