import { GET_ALL_CUSTOMERS, GET_ALL_DELIVERY_PARTNERS, GET_ALL_STORE_OWNER, GET_ALL_USERS, GET_ALL_USER_ORDERS, GET_ALL_USER_REFERAL, GET_ALL_USER_TRANSACTION, GET_SINGLE_USER } from "./actionType";

const initialState = {
    all_users: [],
    single_user: {},
    orders: [],
    refferals: [],
    transactions: [],
    store_owners: [],
    customers: [],
    delivery_partners: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_USERS:
            return { ...state, all_users: action.payload };
        case GET_SINGLE_USER:
            return { ...state, single_user: action.payload };
        case GET_ALL_USER_ORDERS:
            return { ...state, orders: action.payload };
        case GET_ALL_USER_REFERAL:
            return { ...state, refferals: action.payload };
        case GET_ALL_USER_TRANSACTION:
            return { ...state, transactions: action.payload };
        case GET_ALL_STORE_OWNER:
            return { ...state, store_owners: action.payload };
        case GET_ALL_CUSTOMERS:
            return { ...state, customers: action.payload };
        case GET_ALL_DELIVERY_PARTNERS:
            return { ...state, delivery_partners: action.payload };
        default:
            return state;
    }
}
