// export const WEBSITE_API_URL = "http://127.0.0.1:8000/api";   //for localhost route uses
// export const WEBSITE_BASE_URL = "http://127.0.0.1:8000";   //for localhost route uses
// export const IMAGE_BASE_URL = "http://127.0.0.1:8000/public";   //for live route uses

export const WEBSITE_API_URL = "https://sylcon.howincloud.com/public/api";   //for live route uses
export const WEBSITE_BASE_URL = "https://sylcon.howincloud.com/public/";   //for live route uses
export const IMAGE_BASE_URL = "https://sylcon.howincloud.com/public";   //for live route uses


// ------------------------------ Start COMMON -----------------------------

export const GET_ALL_ROLES_URL = '/get-all-roles';
export const STORE_IMPERSONATE_URL = '/impersonate';

export const LOGIN_URL = '/login';
export const REGISTER_URL = '/register';

// ------------------------------ Start COMMON -----------------------------


// ------------------------------ Start City -----------------------------

export const GET_ALL_CITIES_URL = '/get-all-cities';
export const CREATE_CITY_URL = '/create-city';
export const UPDATE_CITY_URL = '/update-city';
export const TOGGLE_CITY_URL = '/toggle-city';
export const TOGGLE_CITY_SURGE_URL = '/toggle-city-surge';
export const GET_CITY_USERS_URL = '/get-all-city-users';
export const UPDATE_CITY_USERS_URL = '/update-city-users';
export const REMOVE_ALL_CITY_USERS_URL = '/remove-all-city-users';
export const GET_CITY_WAREHOUSE_URL = '/get-city-warehouse';

// ------------------------------ End City -----------------------------


// ------------------------------ Start Warehouse -----------------------------

export const GET_ALL_WAREHOUSES_URL = '/get-all-warehouses';
export const CREATE_WAREHOUSE_URL = '/create-warehouse';
export const UPDATE_WAREHOUSE_URL = '/update-warehouse';
export const TOGGLE_WAREHOUSE_URL = '/toggle-warehouse';
export const DELETE_WAREHOUSE_URL = '/delete-warehouse';

// ------------------------------ End Warehouse -----------------------------


// ------------------------------ Start Main Category -----------------------------

export const GET_ALL_MAIN_CATEGORIES_URL = '/get-all-main-categories';
export const CREATE_MAIN_CATEGORY_URL = '/create-main-category';
export const UPDATE_MAIN_CATEGORY_URL = '/update-main-category';
export const TOGGLE_MAIN_CATEGORY_URL = '/toggle-main-category';
export const DELETE_MAIN_CATEGORY_URL = '/delete-main-category';

// ------------------------------ End Main Category -----------------------------


// ------------------------------ Start Category -----------------------------

export const GET_ALL_CATEGORIES_URL = '/get-all-categories';
export const CREATE_CATEGORY_URL = '/create-category';
export const UPDATE_CATEGORY_URL = '/update-category';
export const TOGGLE_CATEGORY_URL = '/toggle-category';
export const DELETE_CATEGORY_URL = '/delete-category';

// ------------------------------ End Category -----------------------------


// ------------------------------ Start Sub Category -----------------------------

export const GET_ALL_SUB_CATEGORIES_URL = '/get-all-sub-categories';
export const CREATE_SUB_CATEGORY_URL = '/create-sub-category';
export const UPDATE_SUB_CATEGORY_URL = '/update-sub-category';
export const TOGGLE_SUB_CATEGORY_URL = '/toggle-sub-category';
export const DELETE_SUB_CATEGORY_URL = '/delete-sub-category';

// ------------------------------ End Sub Category -----------------------------


// ------------------------------ Start Brand -----------------------------

export const GET_ALL_BRANDS_URL = '/get-all-brands';
export const CREATE_BRAND_URL = '/create-brand';
export const UPDATE_BRAND_URL = '/update-brand';
export const TOGGLE_BRAND_URL = '/toggle-brand';
export const TOGGLE_BRAND_POPULAR_URL = '/toggle-popular-brand';
export const DELETE_BRAND_URL = '/delete-brand';

// ------------------------------ End Brand -----------------------------


// ------------------------------ Start Item -----------------------------

export const GET_ALL_ITEMS_URL = '/get-all-items';
export const GET_ITEM_SCHEDULE_DATA_URL = '/get-item-schedule-data';
export const UPDATE_ITEM_SCHEDULE_DATA_URL = '/update-item-schedule-data';
export const GET_SINGLE_ITEM_URL = '/get-single-item';
export const CREATE_ITEM_URL = '/create-item';
export const UPDATE_ITEM_URL = '/update-item';
export const TOGGLE_ITEM_URL = '/toggle-item';
export const DELETE_ITEM_URL = '/delete-item';
export const DELETE_ITEM_IMAGE_URL = '/delete-item-image';


export const GET_ALL_ITEMS_WITH_STOCK_URL = '/get-all-items-with-stock';
export const UPDATE_ITEM_WITH_STOCK_URL = '/update-item-with-stock';

// ------------------------------ End Item -----------------------------


// ------------------------------ Start Item Variants-----------------------------

export const GET_SINGLE_ITEM_VARIANTS_URL = '/get-single-item-variants';
export const GET_ALL_ITEM_VARIANTS_URL = '/get-all-item-variants';
export const CREATE_ITEM_VARIANT_URL = '/create-item-variant';
export const UPDATE_ITEM_VARIANT_URL = '/update-item-variant';
export const TOGGLE_ITEM_VARIANT_URL = '/toggle-item-variant';
export const DELETE_ITEM_VARIANT_URL = '/delete-item-variant';
export const DELETE_VARIANT_IMAGE_URL = '/delete-item-variant-image';

// ------------------------------ End Item Variants-----------------------------


// ------------------------------ Start Item Addon Category-----------------------------

export const GET_ITEM_ADDON_CATEGORY_URL = '/get-item-addon-category';
export const UPDATE_ITEM_ADDON_CATEGORY_URL = '/update-item-addon-category';

// ------------------------------ Start Item Addon Category-----------------------------


// ------------------------------ Start Bulk Upload-----------------------------

export const BULK_UPLOAD_BRANDS_URL = '/bulk-upload-brands';
export const BULK_UPLOAD_MAIN_CATEGORIES_URL = '/bulk-upload-main-categories';
export const BULK_UPLOAD_SUB_CATEGORIES_URL = '/bulk-upload-sub-categories';
export const BULK_UPLOAD_CATEGORIES_URL = '/bulk-upload-categories';
export const BULK_UPLOAD_ITEM_URL = '/bulk-upload-items';
export const BULK_UPLOAD_ITEM_VARIANT_URL = '/bulk-upload-item-variants';
export const BULK_UPLOAD_ADDON_CATEGORY_URL = '/bulk-upload-addon-category';
export const BULK_UPLOAD_ADDON_URL = '/bulk-upload-addon';

// ------------------------------ End Bulk Upload-----------------------------


// ------------------------------ Start Addon Categories ---------------------

export const GET_ALL_ADDON_CATEGORY_URL = '/get-all-addon-categories';
export const TOGGLE_ADDON_CATEGORY_URL = '/toggle-addon-category';
export const CREATE_ADDON_CATEGORY_URL = '/create-addon-category';
export const UPDATE_ADDON_CATEGORY_URL = '/update-addon-category';
export const DELETE_ADDON_CATEGORY_URL = '/delete-addon-category';

// ------------------------------ End Addon Categories ---------------------


// ------------------------------ Start Addon -----------------------------

export const GET_ALL_ADDON_URL = '/get-all-addons';
export const TOGGLE_ADDON_URL = '/toggle-addon';
export const CREATE_ADDON_URL = '/create-addon';
export const UPDATE_ADDON_URL = '/update-addon';
export const DELETE_ADDON_URL = '/delete-addon';

// ------------------------------ End Addon -----------------------------


// users management
export const GET_ALL_USERS_URL = '/get-all-users';
export const GET_SINGLE_USER_URL = '/get-single-user';
export const CREATE_USERS_URL = '/create-user';
export const UPDATE_USER_BANK_URL = '/update-user-bank-details';
export const UPDATE_USER_ROLE_URL = '/update-user-role';
export const GET_ALL_USER_ORDERS_URL = '/get-user-orders';
export const GET_ALL_USER_REFERAL_URL = '/get-user-refferal';
export const GET_ALL_USER_TRANSACTION_URL = '/get-user-transaction';
export const UPDATE_USER_WALLET_URL = '/add-money-user-wallet';
export const UPDATE_USERS_URL = '/update-user';
export const DELETE_USERS_URL = '/delete-user';
export const GET_ALL_STORE_OWNER_URL = '/get-all-store-owners';
export const GET_ALL_CUSTOMERS_URL = '/get-all-customers';
export const UPDATE_VENDOR_STORE_URL = '/assign-store-to-vender';
export const GET_ALL_DELIVERY_PARTNERS_URL = '/get-all-delivery-partners';
export const UPDATE_DELIVERY_PARTNER_URL = '/update-delivery-guy-details';
export const UPDATE_DELIVERY_PARTNER_STORE_URL = '/update-delivery-guy-store';

//  banner
export const GET_ALL_BANNERS_URL = '/get-all-banners';
export const CREATE_BANNER_URL = '/create-banner';
export const UPDATE_BANNER_URL = '/update-banner';
export const DELETE_BANNER_URL = '/delete-banner';
export const TOGGLE_BANNER_URL = '/toggle-banner';

//  Sliders
export const GET_ALL_SLIDERS_URL = '/get-all-sliders';
export const CREATE_SLIDER_URL = '/create-slider';
export const UPDATE_SLIDER_URL = '/update-slider';
export const DELETE_SLIDER_URL = '/delete-slider';
export const TOGGLE_SLIDER_URL = '/toggle-slider';

//  Coupons
export const GET_ALL_COUPONS_URL = '/get-all-coupons';
export const CREATE_COUPON_URL = '/create-coupon';
export const UPDATE_COUPON_URL = '/update-coupon';
export const DELETE_COUPON_URL = '/delete-coupon';
export const TOGGLE_COUPON_URL = '/toggle-coupon';

// scratch card
export const GET_ALL_SCRATCH_CARDS_URL = '/get-all-scratch-cards';
export const CREATE_SCRATCH_CARD_URL = '/create-scratch-card';
export const UPDATE_SCRATCH_CARD_URL = '/update-scratch-card';
export const DELETE_SCRATCH_CARD_URL = '/delete-scratch-card';
export const TOGGLE_SCRATCH_CARD_URL = '/toggle-scratch-card';

//scratch card winners
export const GET_ALL_SCRATCH_CARD_WINNERS_URL = '/get-scratch-card-winners';

//Order
export const GET_LIVE_ORDERS_URL = "/get-live-orders";
export const GET_ALL_ORDERS_URL = "/get-all-orders";
export const GET_SINGLE_ORDER_URL = "/get-single-order";
export const GET_ORDER_DELIVERY_PARTNERS_URL = '/get-order-delivery-partners';
export const ACCEPT_ORDER_URL = '/accept-order';
export const CANCEL_ORDER_URL = '/cancel-order';
export const UPDATE_ORDER_STATUS_URL = '/update-order-status';
export const ASSIGN_DELIVERY_GUY_TO_ORDER_URL = '/assign-delivery-guy-to-order';

// roles
export const GET_ALL_PERMISSION_URL = "/get-all-permissions";
export const CREATE_PERMISSION_URL = '/create-permission';
export const DELETE_PERMISSION_URL = '/delete-permission';
export const TOGGLE_PERMISSION_URL = '/update-permission-role';

// permission
export const UPDATE_SIDE_BAR_PERMISSION_URL = '/update-side-bar-permission';



// messsages
export const GET_ALL_MESSAGES_URL = "/get-all-messages";
export const CREATE_MESSAGE_URL = '/create-message';
export const UPDATE_MESSAGE_URL = '/update-message';
export const TOGGLE_MESSAGE_URL = '/toggle-message';
export const DELETE_MESSAGE_URL = '/delete-message';

// reports
export const GET_ALL_PENDING_STORE_PAYOUTS_URL = '/get-pending-store-payouts';
export const RELEASE_STORE_PAYOUT_URL = '/release-store-payout';
export const GET_ALL_COMPLETED_STORE_PAYOUTS_URL = '/get-completed-store-payouts';
export const GET_ALL_DELIVERY_COLLECTION_URL = '/delivery_collection';
export const COLLECT_DELIVERY_COLLECTION_URL = '/delivery-collection/collect';
export const GET_ALL_DELIVERY_COLLECTION_LOGS_URL = '/delivery_collection_log';
export const GET_SINGLE_USER_DELIVERY_COLLECTION_LOGS_URL = '/delivery-collection-log/single-user';


//dashboard
export const GET_DASHBOARD_DATA_URL = '/get-dashboard-data';
