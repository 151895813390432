import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, Spinner, UncontrolledDropdown } from 'reactstrap';
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, ReactDataTable, RSelect } from '../../../../components/Component';
import { WEBSITE_BASE_URL } from '../../../../config';
import ToastExample from '../../../../helpers/toast';
import Content from '../../../../layout/content/Content';
import Head from '../../../../layout/head/Head';
import { nonActiveFormSubmissionStatus, openFormLoading } from '../../../../redux/common/actions';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { getAllWarehouses } from '../../../../redux/admin/warehouse/action';
import { createItem, deleteItem, getAllItems, toggleItem, updateItem, bulkUploadItem, deleteItemImage } from '../../../../redux/admin/item/action';
import noimage from '../../../../assets/images/noimage.png'
import { getAllSubCategories } from '../../../../redux/admin/inventory/action';

//

const Items = () => {
    const dispatch = useDispatch(); //defining dispatch
    useEffect(() => {
        dispatch(getAllItems());
        dispatch(getAllWarehouses());
    }, []);
    const getSelectors = (state) => ({
        items: state.item.items,
        warehouse: state.warehouse.warehouse,
        sub_categories: state.inventory.sub_categories,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,
    });
    const { sub_categories, items, warehouse, form_loading, form_submission_status, } = useSelector(getSelectors);

    const [editingItem, setEditingItem] = useState([]);   //edit Item state
    const [deletingItem, setDeletingItem] = useState([]);   //delete Item state
    const [formData, setFormData] = useState([]);   //form data state defined
    const [showToast, setShowToast] = useState(false);   //toast state
    const [data, setData] = useState([]);  //data state defined
    const [discount, setDiscount] = useState(false);  //discout state defined
    const [popular, setPopular] = useState(false);  // state defined
    const [veg, setVeg] = useState(false);  // state defined
    const [todaysDeal, setTodaysDeal] = useState(false);
    const [featured, setFeatured] = useState(false);
    const [secondSlide, setSecondSlide] = useState(false);
    const [item, setItem] = useState(false);
    const [discover, setDiscover] = useState(false);

    const [recommended, setRecommended] = useState(false);  // state defined
    const [expressDelivery, setExpressDelivery] = useState(false);  // state defined
    const [bulkFile, setBulkFile] = useState([]);  // state defined

    const [image, setImage] = useState([]);
    const [file, setFile] = useState([]);
    const [imageCount, setImageCount] = useState(false);  //discout state defined

    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    function uploadSingleFile(e) {
        setImage([...image, { image: e.target.files[0] }]);
        setFile([...file, { image: URL.createObjectURL(e.target.files[0]) }]);
    }

    function deleteFile(e) {
        const s = file.filter((item, index) => index !== e);
        setFile(s);
        const si = image.filter((item, index) => index !== e);
        setImage(si);
        console.log(s, si, 'file')
    }

    function deleteImage(item_id, image_id) {
        const new_data = new FormData();
        new_data.append('item_id', item_id);
        new_data.append('image_id', image_id);
        dispatch(deleteItemImage(new_data));
    }

    //modal state
    const [modal, setModal] = useState({
        add: false,
        edit: false,
        schedule: false,
        delete: false,
        bulk: false
    });

    useEffect(() => {
        dispatch(getAllItems());
        dispatch(getAllSubCategories());
        dispatch(getAllWarehouses());
    }, []);

    //to open add modal
    const openAddModal = () => {
        setEditingItem(' ');
        setDiscount(false);
        setImage([]);
        setFile([]);
        setPopular(false)
        setTodaysDeal(false);
        setFeatured(false);
        setSecondSlide(false);
        setItem(false);
        setDiscover(false);
        setVeg(false)
        setRecommended(false)
        setExpressDelivery(false)
        dispatch(nonActiveFormSubmissionStatus());
        setTimeout(() => {
            setModal({ add: true });
        }, 100);
    };

    //to open edit modal
    const openEditModal = (item) => {
        setModal({ edit: true });
        setImage([]);
        setFile([]);
        setPopular(item.is_popular === 1 ? true : false)
        // setTodaysDeal(item.is_todaysDeal === 1 ? true : false)
        setTodaysDeal(item.is_todaysDeal === 1 ? true : false)
        setFeatured(item.is_featured === 1 ? true : false)
        setSecondSlide(item.is_secondSlide === 1 ? true : false)
        setItem(item.is_item === 1 ? true : false);
        setDiscover(item.is_discover === 1 ? true : false);
        setVeg(item.is_veg === 1 ? true : false)
        setRecommended(item.is_recommended === 1 ? true : false)
        setExpressDelivery(item.is_express_delivery === 1 ? true : false)
        dispatch(nonActiveFormSubmissionStatus());
        setDiscount(true)
        setEditingItem(item);
    };

    //to open delete modal
    const openScheduleModal = (item) => {
        setModal({ schedule: true });
        dispatch(nonActiveFormSubmissionStatus());
        // setDeletingItem(item);
    };

    //to open bulk modal
    const openBulkUploadModal = (item) => {
        setModal({ bulk: true });
        dispatch(nonActiveFormSubmissionStatus());
        // setDeletingItem(item);
    };

    //to open delete modal
    const openDeleteModal = (item) => {
        setModal({ delete: true });
        dispatch(nonActiveFormSubmissionStatus());
        setDeletingItem(item);
    };

    const dispableImage = () => {
        let disable = true;
        if (modal.edit && editingItem.images.length > 0) {
            disable = (editingItem.images.length + file.length) <= 4 ? false : true
        } else {
            disable = file.length <= 4 ? false : true
        }
        return disable
    };

    //store lists
    const listWarehouse = warehouse?.map((store) => (
        { value: store.id, label: store.name }
    ));

    const listSubCategory = sub_categories?.map((category) => (
        { value: category.id, label: category.name }
    ));

    //form validation with yup
    const itemSchema = yup.object({
        name: yup.string().required(),
        description: yup.string().required(),
        specification: yup.string().required(),
        warehouse_id: yup.string().required(),
        sub_category_id: yup.string().required(),
        sell_price: yup.string().required(),
        market_price: yup.string().required(),
        min_quantity: yup.string().required(),
        max_quantity: yup.string().required(),
        stock: yup.string().required(),
    });

    //form values
    const defaultValues = useMemo(
        () => ({
            id: editingItem?.id || "",
            name: editingItem?.name || "",
            description: editingItem?.description || "",
            specification: editingItem?.specification || "",
            warehouse_id: editingItem?.warehouse_id || "",
            sub_category_id: editingItem?.sub_category_id || "",
            sell_price: editingItem?.sell_price || "",
            market_price: editingItem?.market_price || "",
            min_quantity: editingItem?.min_quantity || "",
            max_quantity: editingItem?.max_quantity || "",
            stock: editingItem?.stock || "",
        }),
        [editingItem]
    );

    const methods = useForm({
        resolver: yupResolver(itemSchema),
        defaultValues: defaultValues,
    });

    //useform methods
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control
    } = methods;

    //submit function
    const onFormSubmit = (submitData) => {
        dispatch(openFormLoading());
        console.log(submitData, popular, recommended, veg, todaysDeal, featured, secondSlide, item, discover, 'submitdata');
        const new_data = new FormData();
        new_data.append('name', submitData?.name);
        if (image.length > 0) {
            image.forEach((image_file) => {
                new_data.append('images[]', image_file.image);
            })
        }
        new_data.append('warehouse_id', submitData?.warehouse_id);
        new_data.append('sub_category_id', submitData?.sub_category_id);
        new_data.append('description', submitData?.description);
        new_data.append('specification', submitData?.specification);
        new_data.append('sell_price', submitData?.sell_price);
        new_data.append('market_price', submitData?.market_price);
        new_data.append('min_quantity', submitData?.min_quantity);
        new_data.append('max_quantity', submitData?.max_quantity);
        new_data.append('stock', submitData?.stock);
        new_data.append('is_express_delivery', expressDelivery);
        new_data.append('is_popular', popular);
        new_data.append('is_todaysDeal', todaysDeal);
        new_data.append('is_featured', featured);
        new_data.append('is_secondSlide', secondSlide);
        new_data.append('is_item', item);
        new_data.append('is_discover', discover);




        new_data.append('is_recommended', recommended);
        new_data.append('is_veg', veg);
        if (modal.add) {
            dispatch(createItem(new_data));
        } else if (modal.edit) {
            new_data.append('id', editingItem?.id)
            dispatch(updateItem(new_data));
        } else if (modal.delete) {
            dispatch(deleteItem(deletingItem?.id));
        }
        resetForm();
    };

    const onBulkSubmit = (e) => {
        e.preventDefault();
        console.log(bulkFile)
        if (bulkFile) {
            dispatch(openFormLoading());
            const new_data = new FormData();
            new_data.append('item_csv', bulkFile);
            dispatch(bulkUploadItem(new_data));
            setModal({ bulk: false })
            dispatch(getAllItems());
        }
    };

    const resetForm = () => {
        setFormData({
            name: "",
            description: "",
            specification: "",
            warehouse_id: "",
            sub_category_id: "",
            sell_price: "",
            market_price: "",
            min_quantity: "",
            max_quantity: "",
            stock: "",
        });
    };

    //to close modal
    const onFormCancel = () => {
        setModal({ add: false, edit: false, schedule: false, delete: false });
        document.getElementsByClassName("invalid").innerHTML = "";
        resetForm();
        setEditingItem(" ")
        setDiscount(false);
    };

    // toggle
    const handleStatus = (e, id) => {
        dispatch(toggleItem(id));
        setTimeout(() => {
            dispatch(getAllItems());
            setShowToast(true);
        }, 500);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    useEffect(() => {
        if (modal.add == true && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllItems());
                setData(items);
                setModal({ add: false });
                setShowToast(true);
            }, 500);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }

        if (modal.edit == true && editingItem && form_loading == false && form_submission_status == true) {
            dispatch(getAllItems());
            setData(items);
            setModal({ edit: false });
            setShowToast(true);
            reset(defaultValues);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        } else {
            reset(defaultValues);
        }

        if (modal.delete == true && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllItems());
                setData(items);
                setModal({ delete: false });
                setShowToast(true);
            }, 500);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    }, [createItem, form_loading, modal, form_submission_status]);

    //headers of datatable
    const tableHeadings = [
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Warehouse",
            selector: (row) => row?.warehouse ? row?.warehouse?.name : 'No Warehouse Found',
            sortable: true
        },

        {
            name: "Sub Category",
            selector: (row) => row?.sub_category ? row?.sub_category?.name : 'No Category Found',
            sortable: true
        },

        {
            name: "Image",
            selector: (row) => row?.images?.length > 0 ? row?.images[0].id : 'No Item Found',
            compact: true,
            style: { paddingRight: "20px" },
            cell: (row) => {
                return (
                    <div className="user-card mt-2 mb-2" id={row.id}>
                        {row?.images?.length > 0 ?
                            <img src={WEBSITE_BASE_URL + row?.images[0].image} alt="item" style={{ width: '6rem', borderRadius: "5px" }} />
                            :
                            <div className='d-flex flex-column align-items-center ml-3'>
                                <img src={noimage} alt="item" style={{ height: '5vh', width: '5vh' }} />
                                <span className='m-1' style={{ color: '#330033', fontSize: '9px' }}>No image found</span>
                            </div>
                        }
                    </div>
                );
            },
        },

        {
            name: "Status",
            selector: (row) => row.is_active,
            compact: true,
            style: { paddingRight: "20px" },

            cell: (row) => {
                return (
                    <div className="user-card mt-2 mb-2" id={row.id}>
                        <Button color={row.is_active ? "success" : "danger"}
                            onClick={(e) => handleStatus(e, row.id)}
                        >
                            {row.is_active ? <Icon name="stop-circle-fill" /> : <Icon name="play-circle-fill" />}
                        </Button>
                    </div>
                );
            },
        },

        {
            name: "Actions",
            selector: (row) => row.is_surge,
            compact: true,
            grow: 1,
            style: { paddingRight: "20px" },
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <DropdownTrans
                        row={row}
                    />
                </div>
            ),
            sortable: false,
        },
    ];
    const [sm, updateSm] = useState(false);


    //action dropdown
    const DropdownTrans = ({ row }) => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu right>
                    <ul className="link-list-plain">
                        <li>
                            <DropdownItem tag="a"
                                onClick={() => openEditModal(row)}
                            >
                                <Icon name="pen" />Edit
                            </DropdownItem>
                        </li>
                        <li>
                            <DropdownItem tag="a"
                                // onClick={() => openScheduleModal(row)}
                                href={"/schedule-item/" + row.id} >
                                <Icon name="clock" />Item Schedule
                            </DropdownItem>
                        </li>
                        {console.log(row,'row')}
                        {row?.addoncategories?.length > 0 || row?.item_variants?.length > 0 ? (
                            row?.addoncategories?.length > 0 ? (
                                <li>
                                    <DropdownItem tag="a"
                                        href={"/item-addon-category/" + row.id} >
                                        <Icon name="link" />Item Addon Category
                                    </DropdownItem>
                                </li>
                            ) : row?.item_variants?.length > 0 ? (
                                <li>
                                    <DropdownItem tag="a"
                                        href={"/single-item-variants/" + row.id} >
                                        <Icon name="link" />Item Variants
                                    </DropdownItem>
                                </li>
                            ) : null
                        ) : <>
                            <li>
                                <DropdownItem tag="a"
                                    href={"/item-addon-category/" + row.id} >
                                    <Icon name="link" />Item Addon Category
                                </DropdownItem>
                            </li>
                            <li>
                                <DropdownItem tag="a"
                                    href={"/single-item-variants/" + row.id} >
                                    <Icon name="link" />Item Variants
                                </DropdownItem>
                            </li>
                        </>
                        }

                        <li>
                            <DropdownItem tag="a"
                                onClick={() => openDeleteModal(row)}
                            >
                                <Icon name="trash" />Delete
                            </DropdownItem>
                        </li>
                    </ul>
                </DropdownMenu>
            </UncontrolledDropdown >
        );
    };

    return (
        <React.Fragment>
            <Head title="Items"></Head>
            {/* content */}
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Item Lists
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {items?.length} Items.</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <ToastExample
                                    show={showToast}
                                />
                                <div className="toggle-expand-content">
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <Button color="dark" className=""
                                                onClick={() => openBulkUploadModal()}
                                            >
                                                <span>Bulk CSV Upload</span> <Icon name="icon ni ni-upload"></Icon>
                                            </Button>
                                        </li>
                                        <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon"
                                                onClick={() => openAddModal()}
                                            >
                                                <Icon name="plus"></Icon>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <ReactDataTable
                    data={items}
                    columns={tableHeadings}
                    expandableRows
                    pagination
                />
            </Content>



            <Modal
                isOpen={modal.bulk}
                toggle={() => setModal({ bulk: false })}
                className="modal-dialog-centered"
            >
                <ModalBody className='pt-3 pb-2'>
                    <button className="close" onClick={(ev) => { ev.preventDefault(); onFormCancel(); }}  >
                        <Icon name="cross-sm"></Icon>
                    </button>
                    <div className="p-2">
                        <h5 className="title">CSV BULK UPLOAD ITEMS</h5>
                        <div className='mt-2'>
                            <Form className="row gy-4 pt-4" onSubmit={(e) => onBulkSubmit(e)} encType="multipart/formData">
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">CSV FILE</label>
                                        <input
                                            class="form-control"
                                            type="file"
                                            accept=".csv"
                                            name="item_csv"
                                            required
                                            onChange={(e) => {
                                                setBulkFile(e.target.files[0])
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <a href={WEBSITE_BASE_URL + "/assets/docs/item-sample.csv"} className="btn btn-lg btn-dark w-100 justify-center" target="_blank" download>
                                        Download Sample CSV
                                    </a>
                                </Col>
                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 pt-4">
                                        <li>
                                            <Button type="submit" color="primary" size="lg" className="btn-block">
                                                {form_loading ? (
                                                    <>
                                                        <Spinner size="sm" color="light" />Uploading File
                                                    </>
                                                ) : (
                                                    <React.Fragment> Upload File </React.Fragment>
                                                )}
                                            </Button>
                                        </li>
                                        <li>
                                            <button onClick={(ev) => { ev.preventDefault(); onFormCancel(); }} className="link link-light"  >
                                                Cancel
                                            </button>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>

                        </div>
                    </div>
                </ModalBody>
            </Modal>


            {/* add and edit modal */}
            <Modal
                isOpen={modal.add || modal.edit}
                toggle={() => setModal({ add: false })}
                className="modal-dialog-centered"
                size="lg"
            >
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">{modal.edit ? "Editing Item - " + editingItem?.name : "Add Item"}</h5>
                        <div className="mt-4">
                            <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/formData">
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Item Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            placeholder="Enter Item Name"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Image {imageCount ? "( 5 images )" : null}</label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            disabled={dispableImage()}
                                            onChange={(event) => {
                                                uploadSingleFile(event);
                                                event.target.value = null;
                                            }}
                                        />
                                        {errors.image && <span className="invalid">{errors.image.message}</span>}
                                    </FormGroup>
                                </Col>

                                {modal.edit && editingItem?.images.length > 0 &&
                                    editingItem?.images.map((item, index) => {
                                        return (
                                            <Col md="4">
                                                <FormGroup>
                                                    <div className='mt-2 user-select-none' key={index} style={{ boxShadow: "0px 0px 20px 8px #00000015", borderRadius: '5px' }}>
                                                        <img src={WEBSITE_BASE_URL + item.image} alt="" style={{ width: '100%', height: '10rem', borderRadius: '5px 5px 0 0',objectFit:'contain' }} />
                                                        <button className='w-100' type="button" style={{ borderRadius: ' 0 0 5px 5px', backgroundColor: "#c9c9c9", border: 'none' }} onClick={() => deleteImage(editingItem.id, item.id)}>
                                                            <span style={{ fontSize: "15px", color: '#fff', fontWeight: '600' }}>delete</span>
                                                        </button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        );
                                    })}
                                {file.length > 0 &&
                                    file.map((item, index) => {
                                        return (
                                            <Col md="4">
                                                <FormGroup>
                                                    <div className='mt-2 user-select-none' key={item} style={{ boxShadow: "0px 0px 20px 8px #00000015", borderRadius: '5px' }}>
                                                        <img src={item.image} alt="" style={{ width: '100%', height: '10rem', borderRadius: '5px 5px 0 0' }} />
                                                        <button className='w-100' type="button" style={{ borderRadius: ' 0 0 5px 5px', backgroundColor: "#c9c9c9", border: 'none' }} onClick={() => deleteFile(index)}>
                                                            <span style={{ fontSize: "15px", color: '#fff', fontWeight: '600' }}>delete</span>
                                                        </button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        );
                                    })}
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Description</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="description"
                                            placeholder="Enter Description"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.description && <span className="invalid">{errors.description.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Specification</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="specification"
                                            placeholder="Enter Specification"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.specification && <span className="invalid">{errors.specification.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Warehouse</label>
                                        <div className="form-control-wrap">
                                            <Controller
                                                name="warehouse_id"
                                                control={control}
                                                render={({ onChange, value, ref }) => (
                                                    <RSelect
                                                        options={listWarehouse}
                                                        onChange={(val) => {
                                                            onChange(val.value)
                                                        }}
                                                        placeholder={"Select Warehouse"}
                                                        defaultValue={listWarehouse.find((warehouse) => warehouse.value === value)}
                                                    />
                                                )}
                                            />
                                            {errors.warehouse_id && (
                                                <span className="invalid" style={{ color: '#e85347', fontSize: '11px', fontStyle: 'italic' }}>Please choose a Store</span>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Sub Category</label>
                                        <div className="form-control-wrap">
                                            <Controller
                                                name="sub_category_id"
                                                control={control}
                                                render={({ onChange, value, ref }) => (
                                                    <RSelect
                                                        options={listSubCategory}
                                                        placeholder={"Select Sub Category"}
                                                        onChange={(val) => onChange(val.value)}
                                                        defaultValue={listSubCategory?.find((cat) => cat.value === value)}
                                                    />
                                                )}
                                            />
                                            {errors.sub_category_id && (
                                                <span className="invalid" style={{ color: '#e85347', fontSize: '11px', fontStyle: 'italic' }}>Please choose a Category</span>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">{discount ? 'Selling Price' : 'Price'}</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="sell_price"
                                            min={0}
                                            step="0.01"
                                            placeholder={discount ? "Enter Selling Price" : "Enter Price"}
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.price && <span className="invalid">{errors.price.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md={discount ? '4' : '0'}>
                                    <div style={discount ? { display: 'block' } : { display: 'none' }}>
                                        <FormGroup>
                                            <label className="form-label">Market Price</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="market_price"
                                                min={0}
                                                step="0.01"
                                                placeholder="Enter Market Price"
                                                ref={register()}
                                            />
                                        </FormGroup>
                                    </div>
                                </Col>
                                <div className='mt-4 ml-3' onClick={() => setDiscount(!discount)}>
                                    <div className='pt-1' >
                                        <Button className="btn-icon py-1 px-2 btn-dim" color="primary" size="rg" style={{ pointerEvents: 'none' }}>
                                            {discount ? <Icon name="minus-sm" /> : <><Icon name="plus-sm" />Add Discount</>}
                                        </Button>
                                    </div>
                                </div>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Quantity(minimum)</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="min_quantity"
                                            min={0}
                                            placeholder="Enter Minimum Quantity"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.min_quantity && <span className="invalid">{errors.min_quantity.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Quantity(maximum)</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="max_quantity"
                                            min={0}
                                            placeholder="Enter Maximum Quantity"
                                            ref={register()}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Stock</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="stock"
                                            min={0}
                                            placeholder="Enter Stock"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.stock && <span className="invalid">{errors.stock.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup >
                                        <div className="custom-control-sm custom-switch">
                                            <input type="radio" className="custom-control-input" id="popular" checked={popular} onClick={() => setPopular(!popular)} />
                                            <label className="custom-control-label user-select-none" htmlFor="popular">Is Popular </label>
                                        </div>
                                    </FormGroup>
                                    <FormGroup >
                                        <div className="custom-control-sm custom-switch">
                                            <input type="radio" className="custom-control-input" id="express_delivery" checked={expressDelivery} onClick={() => setExpressDelivery(!expressDelivery)} />
                                            <label className="custom-control-label user-select-none" htmlFor="express_delivery">Is Express Delivery </label>
                                        </div>
                                    </FormGroup>
                                    <FormGroup >
                                        <div className="custom-control-sm custom-switch">
                                            <input type="radio" className="custom-control-input" id="recommend" checked={recommended} onClick={() => setRecommended(!recommended)} />
                                            <label className="custom-control-label user-select-none" htmlFor="recommend">Is Recommended </label>
                                        </div>
                                    </FormGroup>
                                    <FormGroup >
                                        <div className="custom-control-sm custom-switch">
                                            <input type="radio" className="custom-control-input" id="veg" checked={veg} onClick={() => setVeg(!veg)} />
                                            <label className="custom-control-label user-select-none" htmlFor="veg">Is Veg </label>
                                        </div>
                                    </FormGroup>


                                    <FormGroup >
                                        <div className="custom-control-sm custom-switch">
                                            <input type="radio" className="custom-control-input" id="todaysDeal" checked={todaysDeal} onClick={() => setTodaysDeal(!todaysDeal)} />
                                            <label className="custom-control-label user-select-none" htmlFor="todaysDeal">Is Today's Deal </label>
                                        </div>
                                    </FormGroup>

                                    <FormGroup >
                                        <div className="custom-control-sm custom-switch">
                                            <input type="radio" className="custom-control-input" id="featured" checked={featured} onClick={() => setFeatured(!featured)} />
                                            <label className="custom-control-label user-select-none" htmlFor="featured">Is Featured </label>
                                        </div>
                                    </FormGroup>

                                    <FormGroup >
                                        <div className="custom-control-sm custom-switch">
                                            <input type="radio" className="custom-control-input" id="secondSlide" checked={secondSlide} onClick={() => setSecondSlide(!secondSlide)} />
                                            <label className="custom-control-label user-select-none" htmlFor="secondSlide">Is Second Slide </label>
                                        </div>
                                    </FormGroup>

                                    <FormGroup >
                                        <div className="custom-control-sm custom-switch">
                                            <input type="radio" className="custom-control-input" id="item" checked={item} onClick={() => setItem(!item)} />
                                            <label className="custom-control-label user-select-none" htmlFor="item">Is Items </label>
                                        </div>
                                    </FormGroup>

                                    <FormGroup >
                                        <div className="custom-control-sm custom-switch">
                                            <input type="radio" className="custom-control-input" id="discover" checked={discover} onClick={() => setDiscover(!discover)} />
                                            <label className="custom-control-label user-select-none" htmlFor="discover">Is Discover </label>
                                        </div>
                                    </FormGroup>

                                </Col>
                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button type="submit" color="primary" size="lg" className="btn-block">
                                                {form_loading ? (
                                                    <>
                                                        <Spinner size="sm" color="light" /> {modal.edit ? "Updating Item" : "Creating New Item"}
                                                    </>
                                                ) : (
                                                    <React.Fragment>{modal.edit ? "Update Item" : "Add New Item"}</React.Fragment>
                                                )}
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    onFormCancel();
                                                }}
                                                className="link link-light"
                                            >
                                                Cancel
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* delete modal */}
            <Modal
                isOpen={modal.delete}
                toggle={() => setModal({ delete: false })}
                className="modal-dialog-centered"
            >
                <ModalBody className='pt-3 pb-2'>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Delete Item{' '}
                            <Badge color="primary">
                                {deletingItem?.name}
                            </Badge>
                            {' '}
                            ?
                        </h5>
                        <div className='mt-2'>
                            <span>This Item will be deleted. Do you really want to delete this item ?</span>
                        </div>
                        <div className="mt-3">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4">
                                <li>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        onFormSubmit();
                                    }} color="danger" className="px-4 btn-block">
                                        {form_loading ? (
                                            <>
                                                <Spinner size="sm" color="light" />Deleting
                                            </>
                                        ) : (
                                            <React.Fragment>Delete</React.Fragment>
                                        )}
                                    </Button>
                                </li>
                                <li>
                                    <a
                                        href="#cancel"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            onFormCancel();
                                        }}
                                        className="link link-light"
                                    >
                                        Cancel
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* schedule modal */}
            <Modal
                isOpen={modal.schedule}
                size='lg'
                toggle={() => setModal({ schedule: false })}
                className="modal-dialog-centered"
            >
                <ModalBody className='pt-3 pb-2'>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Schedule Item{' '}
                            <Badge color="primary">
                                {/* {deletingItem?.name} */}
                            </Badge>
                        </h5>
                        <div className='mt-2'>
                            <div className=''>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <Button
                                        className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                                        onClick={() => updateSm(!sm)}
                                    >
                                        <Icon name="more-v" />
                                    </Button>
                                    <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                                        <ul className="nk-block-tools g-3">
                                            <li>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="a" className="dropdown-toggle btn btn-white btn-dim btn-outline-light">
                                                        <Icon className="d-none d-sm-inline" name="calender-date" />
                                                        <span>
                                                            <span className="d-none d-md-inline"></span>Monday
                                                        </span>
                                                        <Icon className="dd-indc" name="chevron-right" />
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        <ul className="link-list-opt no-bdr">
                                                            <li>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                    }}
                                                                >
                                                                    <span>Last 30 days</span>
                                                                </DropdownItem>
                                                            </li>
                                                            <li>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                    }}
                                                                >
                                                                    <span>Last 6 months</span>
                                                                </DropdownItem>
                                                            </li>
                                                            <li>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                    }}
                                                                >
                                                                    <span>Last 3 weeks</span>
                                                                </DropdownItem>
                                                            </li>
                                                        </ul>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </li>
                                            <li className="nk-block-tools-opt">
                                                <Button color="primary">
                                                    <Icon name="reports" />
                                                    <span>Reports</span>
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* <UncontrolledDropdown>
                  <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <ul className="link-list-plain">
                      <li>
                        <DropdownItem tag="a" >
                          <Icon name="clock" />Monday
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem tag="a" >
                          <Icon name="clock" />Tuesday
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem tag="a" >
                          <Icon name="clock" />Wednesday
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem tag="a" >
                          <Icon name="clock" />Thursday
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem tag="a" >
                          <Icon name="clock" />Friday
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem tag="a" >
                          <Icon name="clock" />Saturday
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem tag="a" >
                          <Icon name="clock" />Sunday
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                            </div>
                        </div>
                        <div className="mt-3">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4">
                                <li>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        onFormSubmit();
                                    }} color="primary" className="px-4 btn-block">
                                        {form_loading ? (
                                            <>
                                                <Spinner size="sm" color="light" />Updating
                                            </>
                                        ) : (
                                            <React.Fragment>Update</React.Fragment>
                                        )}
                                    </Button>
                                </li>
                                <li>
                                    <a
                                        href="#cancel"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            onFormCancel();
                                        }}
                                        className="link link-light"
                                    >
                                        Cancel
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default Items
