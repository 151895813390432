import axiosClient from "../../../helpers/axios-client";
import { CLOSE_FORM_LOADING, FORM_SUBMISSION_STATUS } from "../../common/actionTypes";
import {
    GET_ALL_MAIN_CATEGORIES_URL,
    CREATE_MAIN_CATEGORY_URL,
    UPDATE_MAIN_CATEGORY_URL,
    TOGGLE_MAIN_CATEGORY_URL,
    DELETE_MAIN_CATEGORY_URL,
    BULK_UPLOAD_MAIN_CATEGORIES_URL,

    GET_ALL_CATEGORIES_URL,
    CREATE_CATEGORY_URL,
    UPDATE_CATEGORY_URL,
    TOGGLE_CATEGORY_URL,
    DELETE_CATEGORY_URL,
    BULK_UPLOAD_CATEGORIES_URL,

    GET_ALL_SUB_CATEGORIES_URL,
    CREATE_SUB_CATEGORY_URL,
    UPDATE_SUB_CATEGORY_URL,
    TOGGLE_SUB_CATEGORY_URL,
    DELETE_SUB_CATEGORY_URL,
    BULK_UPLOAD_SUB_CATEGORIES_URL,

} from "../../../config";

import {
    GET_ALL_MAIN_CATEGORIES,
    GET_ALL_CATEGORIES,
    GET_ALL_SUB_CATEGORIES,
} from './actionType';


//------------------------------------- Main Cartegory Start----------------------------------------

export const getAllMainCategories = () => (dispatch) => {
    return axiosClient.post(GET_ALL_MAIN_CATEGORIES_URL).then((response) => {
        if (response && response.data.success) {
            const categories = response.data.data;
            return dispatch({ type: GET_ALL_MAIN_CATEGORIES, payload: categories });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const createMainCategory = (data) => (dispatch) => {
    const config = {
        headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', }
    }
    return axiosClient.post(CREATE_MAIN_CATEGORY_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING, });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};

export const updateMainCategory = (data, id) => (dispatch) => {
    const config = {
        headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', }
    }
    return axiosClient.post(UPDATE_MAIN_CATEGORY_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING, });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};

export const toggleMainCategory = (id) => (dispatch) => {
    return axiosClient.post(TOGGLE_MAIN_CATEGORY_URL, { id });
};

export const deleteMainCategory = (id) => (dispatch) => {
    return axiosClient.post(DELETE_MAIN_CATEGORY_URL, { id }).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING, });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};

export const bulkUploadMainCategories = (data) => (dispatch) => {
    const config = { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', } }
    return axiosClient.post(BULK_UPLOAD_MAIN_CATEGORIES_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};
//------------------------------------- Main Cartegory End----------------------------------------


//------------------------------------- Cartegory Start----------------------------------------
export const getAllCategories = () => (dispatch) => {
    return axiosClient.post(GET_ALL_CATEGORIES_URL).then((response) => {
        if (response && response.data.success) {
            const categories = response.data.data;
            return dispatch({ type: GET_ALL_CATEGORIES, payload: categories });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const createCategory = (data) => (dispatch) => {
    const config = {
        headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' }
    }
    return axiosClient.post(CREATE_CATEGORY_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const updateCategory = (data, id) => (dispatch) => {
    const config = {
        headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' }
    }
    return axiosClient.post(UPDATE_CATEGORY_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const toggleCategory = (id) => (dispatch) => {
    return axiosClient.post(TOGGLE_CATEGORY_URL, { id });
};

export const deleteCategory = (id) => (dispatch) => {
    return axiosClient.post(DELETE_CATEGORY_URL, { id }).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING, });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const bulkUploadCategories = (data) => (dispatch) => {
    const config = { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', } }
    return axiosClient.post(BULK_UPLOAD_CATEGORIES_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};
//------------------------------------- Cartegory End----------------------------------------


//------------------------------------- Sub Cartegory Start----------------------------------------

export const getAllSubCategories = () => (dispatch) => {
    return axiosClient.post(GET_ALL_SUB_CATEGORIES_URL).then((response) => {
        if (response && response.data.success) {
            const categories = response.data.data;
            return dispatch({ type: GET_ALL_SUB_CATEGORIES, payload: categories });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const createSubCategory = (data) => (dispatch) => {
    const config = {
        headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' }
    }
    return axiosClient.post(CREATE_SUB_CATEGORY_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const updateSubCategory = (data, id) => (dispatch) => {
    const config = {
        headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' }
    }
    return axiosClient.post(UPDATE_SUB_CATEGORY_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const toggleSubCategory = (id) => (dispatch) => {
    return axiosClient.post(TOGGLE_SUB_CATEGORY_URL, { id });
};

export const deleteSubCategory = (id) => (dispatch) => {
    return axiosClient.post(DELETE_SUB_CATEGORY_URL, { id }).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const bulkUploadSubCategories = (data) => (dispatch) => {
    const config = { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', } }
    return axiosClient.post(BULK_UPLOAD_SUB_CATEGORIES_URL, data, config).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true, });
    });
};
//------------------------------------- Sub Cartegory End----------------------------------------
