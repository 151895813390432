import React, { useEffect, useState } from 'react'

import { Card, Col } from 'reactstrap'
import { WEBSITE_BASE_URL } from '../../../../../../config';
import ProgressiveImage from "react-progressive-image";
// import noStoreImg from "../../../../../../assets/images/noStoreImg.png";

function StoreCard(props) {
    const { store, activeIds, sentData } = props;
    const [toggle, setToggle] = useState(false)


    useEffect(() => {
        setToggle(activeIds?.includes(store?.id))

    }, [])

    const handleClick = (id) => {
        setToggle(!toggle)
        sentData(id, !toggle);
    };

    return (
      
        <Col lg="3" md="3">
            <Card className='rounded-pill mt-3' >
                <div id='special'>
                    <input className="inputCheck" data-name={store?.name}
                        name="user_restaurants[]" value={"cb" + store?.id}
                        style={{ visibility: 'hidden' }} checked={toggle} type="checkbox" id={"cb" + store?.id} onClick={() => handleClick(store?.id)} />
                    <label htmlFor={"cb" + store?.id} className="text-center" id="store-card-label">
                        <ProgressiveImage
                            delay={20}
                            src={store?.image}
                            // placeholder={noStoreImg}  
                            >
                            {(src, loading) => (
                                <img src={src}
                                    alt={store?.name} className=" "
                                    style={{ borderRadius: "10px", height: "150px", width: "150px", objectFit: "contain" }} />
                            )}
                        </ProgressiveImage>
                        <h5 className="text-center font-bold mb-2 mt-3">
                            {store?.name}
                        </h5>
                    </label>

                </div>
            </Card>
        </Col>
    )
}

export default StoreCard
