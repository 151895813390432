import axios from "axios";
import { WEBSITE_API_URL } from "../config/index";
const axiosClient = axios.create({
  baseURL: WEBSITE_API_URL,
});

axiosClient.interceptors.request.use((config) => {
  // console.log(config,'config');
  const token = localStorage.getItem("accessToken");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    try {
        const { response } = error;
        if (response.status == 401) {
          // localStorage.removeItem("token");
        }
        throw error;
    } catch (error) {
        console.log(error);
    }
   
    // return Promise.reject(error);
  }
);
export default axiosClient;
