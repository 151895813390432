import { GET_ALL_PERMISSION_HEAD } from "./actionType";

const initialState = {
    permissions: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_PERMISSION_HEAD:
            return { ...state, permissions: action.payload };
        default:
            return state;
    }
}
