import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, Spinner, UncontrolledDropdown } from "reactstrap";
import { string } from "yup";
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Button, Icon, RSelect, ReactDataTable } from "../../../components/Component";
import ToastExample from "../../../helpers/toast";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { getAllWarehouses, toggleWarehouse, createWarehouse, updateWarehouse, deleteWarehouse } from "../../../redux/admin/warehouse/action";
import * as yup from "yup";
import { nonActiveFormSubmissionStatus, openFormLoading } from "../../../redux/common/actions";
import noimage from '../../../assets/images/noimage.png'
import { getAllCities } from "../../../redux/admin/cities/actions";
import { WEBSITE_BASE_URL } from "../../../config";


const Warehouse = () => {
    const dispatch = useDispatch(); //defining dispatch
    const [isDynamic, setIsDynamic] = useState(false);

    useEffect(() => {
        dispatch(getAllWarehouses());
        dispatch(getAllCities());
    }, []);

    const getSelectors = (state) => ({
        cities: state.cities.cities,
        warehouses: state.warehouse.warehouse,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,
    });

    const { warehouses, cities, form_loading, form_submission_status } = useSelector(getSelectors);
    const [showToast, setShowToast] = useState(false); //toast state

    const [editingWarehouse, setEditWarehouse] = useState([]); //edit Warehouse state

    const [deletingWarehouse, setDeletingWarehouse] = useState([]); //delete Warehouse state

    const [formData, setFormData] = useState([]); //form data state defined

    const [data, setData] = useState([]); //data state defined

    //modal state
    const [modal, setModal] = useState({
        edit: false,
        add: false,
        delete: false,
    });

    //to open add modal
    const openAddModal = () => {
        setEditWarehouse([]);
        dispatch(nonActiveFormSubmissionStatus());
        setTimeout(() => {
            setModal({ add: true });
        }, 100);
    };

    //to open edit modal
    const openEditModal = (warehouse) => {
        setModal({ edit: true });
        if (warehouse.delivery_charge_type == "DYNAMIC") {
            setIsDynamic(true);
        } else {
            setIsDynamic(false);
        }
        if (warehouse.is_freedelivery) {
            setFreedelivery(true);
        } else {
            setFreedelivery(false);
        }
        dispatch(nonActiveFormSubmissionStatus());
        setEditWarehouse(warehouse);
    };

    //to open delete modal
    const openDeleteModal = (warehouse) => {
        setModal({ delete: true });
        dispatch(nonActiveFormSubmissionStatus());
        setDeletingWarehouse(warehouse);
    };

    const warehouseSchema = yup.object({
        name: string().required(),
        // image: yup.mixed()
        //     .test("fileSize", "File Size is too large", (value) => {
        //         if (value[0]) {
        //             return value[0].size <= 200 * 1024;
        //         }
        //         return true;
        //     })
        //     .test("fileFormat", "Unsupported File Format. Choose jpeg, jpg, png format", (value) => {
        //         if (value[0]) {
        //             return ["image/jpeg", "image/png", "image/jpg"].includes(value[0].type);
        //         }
        //         return true;
        //     }).notRequired(),
        phone: string().required(),
        description: string(),
        city_id: string().required(),
        delivery_radius_in_km: string().required(),
        approx_delivery_time: string().required(),
        surge_fee: string(),
        // rating: string(),
        address: string().required(),
        latitude: string().required(),
        longitude: string().required(),
        radius: string(),
        pincode: string(),
        // delivery_type: string().required(),
        delivery_charge_type: string().required(),
        delivery_charge: isDynamic ? string() : string().required(),
        base_delivery_charge: isDynamic ? string().required() : string(),
        base_delivery_distance: isDynamic ? string().required() : string(),
        extra_delivery_charge: isDynamic ? string().required() : string(),
        extra_delivery_distance: isDynamic ? string().required() : string(),
    });

    //form values
    const defaultValues = useMemo(
        () => ({
            id: editingWarehouse?.id || "",
            name: editingWarehouse?.name || "",
            latitude: editingWarehouse?.latitude || "",
            longitude: editingWarehouse?.longitude || "",
            radius: editingWarehouse?.radius || "",
            phone: editingWarehouse?.phone || "",
            city_id: editingWarehouse?.city_id || "",
            // image: "",
            description: editingWarehouse?.description || "",
            // rating: editingWarehouse?.rating || "",
            delivery_radius_in_km: editingWarehouse?.delivery_radius_in_km || "",
            approx_delivery_time: editingWarehouse?.approx_delivery_time || "",
            surge_fee: editingWarehouse?.surge_fee || "",
            address: editingWarehouse?.address || "",
            pincode: editingWarehouse?.pincode || "",
            delivery_charge_type: editingWarehouse?.delivery_charge_type || "FIXED",
            delivery_charge: editingWarehouse?.delivery_charge || "",
            base_delivery_charge: editingWarehouse?.base_delivery_charge || "",
            base_delivery_distance: editingWarehouse?.base_delivery_distance || "",
            extra_delivery_charge: editingWarehouse?.extra_delivery_charge || "",
            extra_delivery_distance: editingWarehouse?.extra_delivery_distance || "",
        }),
        [editingWarehouse]
    );

    const methods = useForm({
        resolver: yupResolver(warehouseSchema),
        defaultValues: defaultValues,
    });

    const {
        register,
        handleSubmit,
        setError,
        control,
        formState: { errors },
        unregister,
        reset,
    } = methods;

    const listCity = cities?.map((city) => (
        { value: city.id, label: city.name }
    ));

    const [freedelivery, setFreedelivery] = useState(false);

    //submit function to category
    const onFormSubmit = (submitData) => {
        dispatch(openFormLoading());
        console.log(submitData, 'submitData')
        const new_data = new FormData();
        new_data.append('name', submitData?.name);
        // if (submitData?.image?.length > 0) {
        //     new_data.append('image', submitData?.image[0])
        // }
        new_data.append('city_id', submitData?.city_id);
        new_data.append('phone', submitData?.phone);
        new_data.append('description', submitData?.description);
        // new_data.append('rating', submitData?.rating ?? null);
        new_data.append('delivery_radius_in_km', submitData?.delivery_radius_in_km ?? null);
        new_data.append('approx_delivery_time', submitData?.approx_delivery_time ?? null);
        new_data.append('surge_fee', submitData?.surge_fee ?? null);
        new_data.append('delivery_charge_type', submitData?.delivery_charge_type ?? null);
        if (isDynamic) {
            new_data.append('base_delivery_charge', submitData?.base_delivery_charge ?? null);
            new_data.append('base_delivery_distance', submitData?.base_delivery_distance ?? null);
            new_data.append('extra_delivery_charge', submitData?.extra_delivery_charge ?? null);
            new_data.append('extra_delivery_distance', submitData?.extra_delivery_distance ?? null);
        } else {
            new_data.append('delivery_charge', submitData?.delivery_charge ?? null);
        }
        new_data.append('radius', submitData?.radius ?? null);
        new_data.append('address', submitData?.address ?? null);
        new_data.append('latitude', submitData?.latitude ?? null);
        new_data.append('longitude', submitData?.longitude ?? null);
        new_data.append('pincode', submitData?.pincode ?? null);
        new_data.append('is_freedelivery', freedelivery ? 1 : 0);
        if (modal.edit) {
            setTimeout(() => {
                new_data.append('id', editingWarehouse?.id);
                dispatch(updateWarehouse(new_data));
            }, 50);
        } else if (modal.add) {
            dispatch(createWarehouse(new_data));
        } else if (modal.delete) {
            dispatch(deleteWarehouse(deletingWarehouse?.id));
        }
        resetForm();
    };

    //submit toast
    useEffect(() => {
        if (modal.add == true && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllWarehouses());
                setData(warehouses);
                setModal({ add: false });
                setShowToast(true);
            }, 500);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }

        if (modal.edit == true && editingWarehouse && form_loading == false && form_submission_status == true) {
            dispatch(getAllWarehouses());
            setData(warehouses);
            setModal({ edit: false });
            setShowToast(true);
            reset(defaultValues);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        } else {
            reset(defaultValues);
        }

        if (modal.delete == true && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllWarehouses());
                setData(warehouses);
                setModal({ delete: false });
                setShowToast(true);
            }, 500);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    }, [createWarehouse, form_loading, modal, form_submission_status]);

    const deliveryChargeType = [
        { value: "FIXED", label: "Fixed" },
        { value: "DYNAMIC", label: "Dynamic" },
    ];

    // to reset the form
    const resetForm = () => {
        setFormData({
            id: "",
            name: "",
            phone: "",
            city_id: "",
            // image: "",
            description: "",
            // rating: "",
            delivery_radius_in_km: "",
            approx_delivery_time: "",
            surge_fee: "",
            // delivery_type: "",
            delivery_charge_type: "FIXED",
            base_delivery_charge: "",
            base_delivery_distance: "",
            extra_delivery_charge: "",
            extra_delivery_distance: "",
            delivery_charge: "",
            radius: "",
            address: "",
            latitude: "",
            longitude: "",
            pincode: "",
        });
    };

    //to close modal
    const onFormCancel = () => {
        setModal({ edit: false, add: false, delete: false });
        document.getElementsByClassName("invalid").innerHTML = "";
        resetForm();
    };

    //Warehouse toggle
    const handleWarehouseStatus = (e, id) => {
        dispatch(toggleWarehouse(id));
        setTimeout(() => {
            dispatch(getAllWarehouses());
            setShowToast(true);
        }, 500);

        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const __deliveryChrgeType = (type) => {
        if (type == "DYNAMIC") {
            setIsDynamic(true);
        } else {
            setIsDynamic(false);
        }
    }
    //headers of datatable
    const tableHeadings = [
        {
            name: "Id",
            selector: (row) => row.id,
            sortable: true,
        },
        // {
        //     name: "Image",
        //     selector: (row) => row.image,
        //     compact: true,
        //     style: { paddingRight: "20px" },
        //     cell: (row) => {
        //         return (
        //             <div className="user-card mt-2 mb-2" id={row.id}>
        //                 {row?.image ?
        //                     <img src={WEBSITE_BASE_URL + row.image} alt="warehouse" style={{ width: '6rem', borderRadius: "5px" }} />
        //                     :
        //                     <div className='d-flex flex-column align-items-center ml-3'>
        //                         {console.log(WEBSITE_BASE_URL + row.image, 'WEBSITE_BASE_URL + row.image')}
        //                         <img src={noimage} alt="warehouse" style={{ height: '5vh', width: '5vh' }} />
        //                         <span className='m-1' style={{ color: '#330033', fontSize: '9px' }}>No image found</span>
        //                     </div>
        //                 }
        //             </div>
        //         );
        //     },
        // },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "City",
            selector: (row) => row.city?.name ?? "--",
            sortable: true,
        },
        {
            name: "Phone",
            selector: (row) => row.phone,
            sortable: true,
        },


        {
            name: "Status",
            selector: (row) => row.is_active,
            compact: true,
            style: { paddingRight: "20px" },

            cell: (row) => {
                return (
                    <div className="user-card mt-2 mb-2" id={row.id}>
                        <Button color={row.is_active ? "success" : "danger"} onClick={(e) => handleWarehouseStatus(e, row.id)}>
                            {row.is_active ? <Icon name="stop-circle-fill" /> : <Icon name="play-circle-fill" />}
                        </Button>
                    </div>
                );
            },
        },

        {
            name: "Actions",
            selector: (row) => row.is_surge,
            compact: true,
            grow: 1,
            style: { paddingRight: "20px" },
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <DropdownTrans
                        row={row}
                    />
                </div>
            ),
            sortable: false,
        },
    ];

    //action dropdown
    const DropdownTrans = ({ row }) => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu right>
                    <ul className="link-list-plain">
                        <li>
                            <DropdownItem tag="a" onClick={() => openEditModal(row)}>
                                <Icon name="pen" />
                                Edit
                            </DropdownItem>
                        </li>
                        <li>
                            <DropdownItem tag="a" onClick={() => openDeleteModal(row)}>
                                <Icon name="trash" />
                                Delete
                            </DropdownItem>
                        </li>
                    </ul>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    return (
        <React.Fragment>
            <Head title="Warehouse"></Head>
            {/* add and edit modal */}
            <Modal
                isOpen={modal.add || modal.edit}
                toggle={() => setModal({ add: false })}
                className="modal-dialog-centered"
                size="lg"
            >
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">{modal.edit ? "Editing Warehouse - " + editingWarehouse?.name : "Add Warehouse"}</h5>
                        <div className="mt-4">

                            <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/formData">
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Warehouse Name <span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            placeholder="Enter Warehouse Name"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                                    </FormGroup>
                                </Col>
                                {/* <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Image <span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            name="image"
                                            ref={register({
                                                required: false,
                                            })}
                                        />
                                        {errors.image && <span className="invalid">{errors.image.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="12" style={modal.edit ? { display: 'block' } : { display: 'none' }}>
                                    <FormGroup>
                                        <label className="form-label">Current Image </label>
                                        <div className="my-2" id={editingWarehouse.id}>
                                            {editingWarehouse?.image ?
                                                <>
                                                    <img src={WEBSITE_BASE_URL + editingWarehouse?.image} alt="warehouse" style={{ width: '8rem', borderRadius: '5px' }} />
                                                </>
                                                :
                                                <>
                                                    <img src={noimage} alt="warehouse" style={{ height: '7vh', width: '7vh' }} />
                                                    <span className='m-1' style={{ color: '#330033', fontSize: '9px' }}>No image found</span>
                                                </>
                                            }
                                        </div>
                                    </FormGroup>
                                </Col> */}

                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Phone <span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            className="form-control"
                                            type="tel"
                                            name="phone"
                                            placeholder="Enter Phone Number"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Description</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="description"
                                            placeholder="Enter a description"
                                            ref={register()}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">City <span style={{ color: 'red' }}>*</span></label>
                                        <div className="form-control-wrap">
                                            <Controller
                                                name="city_id"
                                                control={control}
                                                render={({ onChange, value, ref }) => (
                                                    <RSelect
                                                        options={listCity}
                                                        onChange={(val) => {
                                                            onChange(val.value)
                                                        }}
                                                        defaultValue={listCity.find((c) => c.value === value)}
                                                    />
                                                )}
                                            />
                                            {errors.city_id && (
                                                <span className="invalid text-danger text-italic">Please choose a city</span>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>

                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Delivery Radius in Km <span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="delivery_radius_in_km"
                                            placeholder="Enter Delivery Radius in Km"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.delivery_radius_in_km && <span className="invalid">{errors.delivery_radius_in_km.message}</span>}
                                    </FormGroup>
                                </Col>

                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Approx Delivery Time <span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="approx_delivery_time"
                                            placeholder="Enter Approx Delivery Time"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.approx_delivery_time && <span className="invalid">{errors.approx_delivery_time.message}</span>}
                                    </FormGroup>
                                </Col>

                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Surge Fee</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="surge_fee"
                                            placeholder="Enter Surge Fee"
                                            ref={register()}
                                        />
                                    </FormGroup>
                                </Col>
                                {/* <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Rating</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            min="1"
                                            max="5"
                                            name="rating"
                                            placeholder="Enter Rating"
                                            ref={register()}
                                        />
                                    </FormGroup>
                                </Col> */}
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Address <span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="address"
                                            placeholder="Enter Address"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.address && <span className="invalid">{errors.address.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Latitude <span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="latitude"
                                            placeholder="Enter Warehouse Latitude"
                                            ref={register({
                                                required: "This field is required",
                                            })}
                                        />
                                        {errors.latitude && <span className="invalid">{errors.latitude.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Longitude <span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="longitude"
                                            placeholder="Enter Warehouse Longitude"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.longitude && <span className="invalid">{errors.longitude.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Radius</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="radius"
                                            min="0"
                                            ref={register()}
                                            placeholder="Enter Warehouse maximum delivery radius"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Pin Code </label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="pincode"
                                            min="0"
                                            ref={register()}
                                            placeholder="Enter Pin Code"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Delivery Charge Type <span style={{ color: 'red' }}>*</span></label>
                                        <div className="form-control-wrap">
                                            <Controller
                                                name="delivery_charge_type"
                                                control={control}
                                                render={({ onChange, value, ref }) => (
                                                    <RSelect
                                                        options={deliveryChargeType}
                                                        onChange={(val) => {
                                                            onChange(val.value)
                                                            __deliveryChrgeType(val.value)
                                                        }}
                                                        defaultValue={deliveryChargeType.find((c) => c.value === value)}
                                                        placeholder="Enter Delivery Charge Type"
                                                    />
                                                )}
                                                ref={register({ required: "This field is required" })}
                                            />
                                            {errors.delivery_charge_type && (
                                                <span className="invalid text-danger text-italic">Please choose delivery charge type</span>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>
                                {isDynamic ? (
                                    <React.Fragment>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Base Delivery Charge <span style={{ color: 'red' }}>*</span></label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="base_delivery_charge"
                                                    onChange={(e) => {
                                                    }}
                                                    ref={register({
                                                        required: "This field is required",
                                                    })}
                                                    placeholder="Enter Base Delivery Charge"
                                                />
                                                {errors.base_delivery_charge && (
                                                    <span className="invalid">Base Delivery Charge is required</span>
                                                )}
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Base Delivery Distance <span style={{ color: 'red' }}>*</span></label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="base_delivery_distance"
                                                    onChange={(e) => {
                                                    }}
                                                    ref={register({
                                                        required: "This field is required",
                                                    })}
                                                    placeholder="Enter Base Delivery Distance"
                                                />
                                                {errors.base_delivery_distance && (
                                                    <span className="invalid">Base Delivery Distance is required</span>
                                                )}
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Extra Delivery Charge <span style={{ color: 'red' }}>*</span></label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="extra_delivery_charge"
                                                    onChange={(e) => {
                                                    }}
                                                    ref={register({
                                                        required: "This field is required",
                                                    })}
                                                    placeholder="Enter Extra Delivery Charge"
                                                />
                                                {errors.extra_delivery_charge && (
                                                    <span className="invalid">Extra Delivery Charge is required</span>
                                                )}
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Extra Delivery Distance <span style={{ color: 'red' }}>*</span></label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="extra_delivery_distance"
                                                    onChange={(e) => {
                                                    }}
                                                    ref={register({
                                                        required: "This field is required",
                                                    })}
                                                    placeholder="Enter Extra Delivery Distance"
                                                />
                                                {errors.base_delivery_distance && <span className="invalid">Extra Distance is required</span>}
                                            </FormGroup>
                                        </Col>
                                    </React.Fragment>
                                ) : (
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-label">Delivery Charge <span style={{ color: 'red' }}>*</span></label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="delivery_charge"
                                                onChange={(e) => {
                                                }}
                                                ref={register({
                                                    required: "This field is required",
                                                })}
                                                placeholder="Enter Delivery Charge"
                                            />
                                            {errors.delivery_charge && <span className="invalid">Delivery Charge is required</span>}
                                        </FormGroup>
                                    </Col>
                                )}
                                <Col md="12">
                                    <FormGroup >
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" checked={freedelivery} id="is_freedelivery" onChange={() => {
                                                setFreedelivery(!freedelivery);
                                            }} />
                                            <label className="custom-control-label user-select-none" htmlFor="is_freedelivery"> Is Free Delivery </label>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col size="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button type="submit" color="primary" size="lg" className="btn-block">
                                                {form_loading ? (
                                                    <>
                                                        <Spinner size="sm" color="light" /> {modal.edit ? "Updating Warehouse" : "Creating New Warehouse"}
                                                    </>
                                                ) : (
                                                    <React.Fragment>{modal.edit ? "Update Warehouse" : "Add New Warehouse"}</React.Fragment>
                                                )}
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    onFormCancel();
                                                }}
                                                className="link link-light"
                                            >
                                                Cancel
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* content */}
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Warehouse Lists
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {warehouses?.length} warehouse.</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <ToastExample
                                    show={showToast}
                                />
                                <div className="toggle-expand-content">
                                    <ul className="nk-block-tools g-3">
                                        <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon"
                                                onClick={() => openAddModal()}
                                            >
                                                <Icon name="plus"></Icon>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <ReactDataTable
                    data={warehouses}
                    columns={tableHeadings}
                    expandableRows
                    pagination
                />
            </Content>

            {/* delete modal */}
            <Modal
                isOpen={modal.delete}
                toggle={() => setModal({ delete: false })}
                className="modal-dialog-centered"
            // size="lg"
            >
                <ModalBody className="pt-3 pb-2">
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">
                            Delete Warehouse <Badge color="primary">{deletingWarehouse?.name}</Badge> ?
                        </h5>
                        <div className="mt-2">
                            <span>This warehouse will be deleted. Do you really want to delete this warehouse ?</span>
                        </div>
                        <div className="mt-3">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4">
                                <li>
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onFormSubmit();
                                        }}
                                        color="danger"
                                        className="px-4 btn-block"
                                    >
                                        {form_loading ? (
                                            <>
                                                <Spinner size="sm" color="light" />
                                                Deleting
                                            </>
                                        ) : (
                                            <React.Fragment>Delete</React.Fragment>
                                        )}
                                    </Button>
                                </li>
                                <li>
                                    <a
                                        href="#cancel"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            onFormCancel();
                                        }}
                                        className="link link-light"
                                    >
                                        Cancel
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default Warehouse;
