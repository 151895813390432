import {
    ACCEPT_ORDER_URL,
    ASSIGN_DELIVERY_GUY_TO_ORDER_URL,
    CANCEL_ORDER_URL,
    GET_ALL_ORDERS_URL,
    GET_LIVE_ORDERS_URL,
    GET_ORDER_DELIVERY_PARTNERS_URL,
    GET_SINGLE_ORDER_URL,
    UPDATE_ORDER_STATUS_URL,
} from "../../../config";
import axiosClient from "../../../helpers/axios-client";
import { CLOSE_FORM_LOADING, FORM_SUBMISSION_STATUS } from "../../common/actionTypes";
// import { CLOSE_FORM_LOADING, FORM_SUBMISSION_STATUS } from "../../common/actionTypes";
import { GET_ALL_ORDERS, GET_LIVE_ORDERS, GET_ORDER_DELIVERY_PARTNERS, GET_SINGLE_ORDER } from "./actionType";

export const getLiveOrders = (data) => (dispatch) => {
    return axiosClient.post(GET_LIVE_ORDERS_URL, data).then((response) => {
        if (response && response.data.success) {
            const orders = response.data;
            return dispatch({ type: GET_LIVE_ORDERS, payload: orders });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const getAllOrders = (data) => (dispatch) => {
    return axiosClient.post(GET_ALL_ORDERS_URL, data).then((response) => {
        if (response && response.data.success) {
            const orders = response.data.data;
            return dispatch({ type: GET_ALL_ORDERS, payload: orders });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const getSingleOrder = (id) => (dispatch) => {
    return axiosClient.post(GET_SINGLE_ORDER_URL, { id }).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        if (response && response.data.success) {
            const orders = response.data;
            return dispatch({ type: GET_SINGLE_ORDER, payload: orders });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const getOrderDeliveryPartners = (id) => (dispatch) => {
    return axiosClient.post(GET_ORDER_DELIVERY_PARTNERS_URL, { id }).then((response) => {
        if (response && response.data.success) {
            const orderpartners = response.data.data;
            return dispatch({ type: GET_ORDER_DELIVERY_PARTNERS, payload: orderpartners });
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const assignDeliveryGuyToOrder = (data) => (dispatch) => {
    return axiosClient.post(ASSIGN_DELIVERY_GUY_TO_ORDER_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const updateOrderStatus = (data) => (dispatch) => {
    return axiosClient.post(UPDATE_ORDER_STATUS_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const acceptOrder = (data) => (dispatch) => {
    return axiosClient.post(ACCEPT_ORDER_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};

export const cancelOrder = (data) => (dispatch) => {
    return axiosClient.post(CANCEL_ORDER_URL, data).then((response) => {
        dispatch({ type: CLOSE_FORM_LOADING });
        dispatch({ type: FORM_SUBMISSION_STATUS, payload: true });
    });
};
