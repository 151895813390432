import React, { useEffect } from 'react'
import { Badge, Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, ReactDataTable } from '../../../../components/Component'
import { WEBSITE_BASE_URL } from '../../../../config'
import ToastExample from '../../../../helpers/toast'
import Content from '../../../../layout/content/Content'
import Head from '../../../../layout/head/Head'
import moment from 'moment'
import { getAllCustomers, getAllStoreOwner } from '../../../../redux/admin/user/action'
import { useDispatch, useSelector } from 'react-redux'



function Customer() {

    const dispatch = useDispatch();
    const getSelectors = (state) => ({
        users: state.users.customers,
    });
    const { users } = useSelector(getSelectors);

    useEffect(() => {
        dispatch(getAllCustomers())
    }, [])

    const UserHeading = [
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            cell: (row) => {
                return (
                    <div className="user-card">
                        {/* {row.image &&
                            <img src={WEBSITE_BASE_URL + row.image}
                                className="user-image-round my-2"
                                alt='howin_user'
                            />
                        } */}

                        <div className=" ">
                            {row.name}
                        </div>
                    </div>
                );
            },
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            hide: 370,
        },
        {
            name: "Phone",
            selector: (row) => row.phone,
            sortable: true,
            hide: 370,
        },
        {
            name: "Status",
            selector: (row) => row.is_banned,
            compact: true,
            style: { paddingRight: "20px" },

            cell: (row) => {
                return (
                    <Badge className="badge-dot" color={row?.is_active == '0' ? "danger" : "success"}>
                        {row?.is_active == '0' ? "Inactive" : "Active"}
                    </Badge>
                );
            },
        },
        {
            name: "Role",

            compact: true,
            style: { paddingRight: "20px" },

            cell: (row) => {
                return (
                    <Badge pill color="secondary">
                        {row?.roles && row?.roles[0] ? row?.roles[0]?.name : "User Has No Role"}
                    </Badge>

                );
            },
        }, {
            name: "Track Record",
            compact: true,
            style: { paddingRight: "20px" },

            cell: (row) => {
                return (
                    <Badge color="outline-dark">
                        Sliver
                    </Badge>

                );
            },
        },
        {
            name: "Actions",
            compact: true,
            grow: 1,
            style: { paddingRight: "20px" },
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <DropdownTrans row={row} />

                </div>

            ),
            sortable: false,
        },
    ];

    const DropdownTrans = ({ row }) => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu right>
                    <ul className="link-list-plain">
                        <li>
                            <DropdownItem tag="a" href={'/edit-user/' + row.id}>
                                <Icon name="pen" />
                                Edit
                            </DropdownItem>
                        </li>


                    </ul>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    return (
        <React.Fragment>
            <Head title="Customers "></Head>

            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Customers
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {users.length} Customers .</p>
                            </BlockDes>
                        </BlockHeadContent>

                    </BlockBetween>
                </BlockHead>


                {/* datatable */}
                <ReactDataTable
                    data={users}
                    columns={UserHeading}

                    pagination
                />

            </Content>
        </React.Fragment>
    )
}

export default Customer
