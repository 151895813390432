import React, { useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import UserAvatar from "../../../../components/user/UserAvatar";
import { useSelector } from "react-redux";

const User = () => {
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen((prevState) => !prevState);
    const name = localStorage.getItem('userName');
    const email = localStorage.getItem('email');
    const role = localStorage.getItem('role');
    const handleSignout = () => {
        localStorage.removeItem("accessToken");
    };
    const getSelectors = (state) => ({
        user: state.users.single_user,
    });
    const { user } = useSelector(getSelectors);
    return (
        <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
            <DropdownToggle
                tag="a"
                href="#toggle"
                className="dropdown-toggle"
                onClick={(ev) => {
                    ev.preventDefault();
                }}
            >
                <div className="user-toggle">
                    {/* <UserAvatar icon="user-alt" className="sm" /> */}
                    <div className="user-info d-none d-md-block">
                        <div className="user-status">{role}</div>
                        <div className="user-name dropdown-indicator">{name ? name.toUpperCase() : "User"}</div>
                    </div>
                </div>
            </DropdownToggle>
            <DropdownMenu right className="dropdown-menu-md dropdown-menu-s1">
                <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                    <div className="user-card sm">

                        <div className="user-info">
                            <span className="lead-text">{name ? name.toUpperCase() : "User"}</span>
                            <span className="sub-text">{email ? email : "user@gmail.com"}</span>
                        </div>
                    </div>
                </div>
                <div className="dropdown-inner">
                    <LinkList>
                        <a href={`${process.env.PUBLIC_URL}/auth-login`} onClick={handleSignout}>
                            <Icon name="signout"></Icon>
                            <span>Sign Out</span>
                        </a>
                    </LinkList>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

export default User;
