import React, { useState} from "react";
import {Toast,ToastHeader,ToastBody} from "reactstrap"
import { Icon } from "../components/Component";
const ToastExample = ({show}) => {
    const [isOpen , setisOpen] = useState(false);
    const toggle = () => {setisOpen(!isOpen)};
    return (
      <Toast isOpen={show}>
        <ToastHeader
          toggle={toggle}
          close={
            <>
              <button className="close" onClick={toggle}>
                <Icon name="cross-sm" />
              </button>
            </>
          }
        >
          <span className="text-primary" style={{ fontWeight:'600' }}>Success</span>
        </ToastHeader>
        <ToastBody>Operation Successful.</ToastBody>
      </Toast>
    )
  }


  export default ToastExample