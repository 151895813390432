export const GET_ALL_BRANDS = 'GET_ALL_BRANDS';
export const GET_ALL_ITEMS = 'GET_ALL_ITEMS';
export const GET_ALL_ITEM_VARIANTS = 'GET_ALL_ITEM_VARIANTS';
export const GET_ITEM_SCHEDULE_DATA = 'GET_ITEM_SCHEDULE_DATA';
export const GET_SINGLE_ITEM = 'GET_SINGLE_ITEM'
export const GET_ALL_ITEM_GROUPS = 'GET_ALL_ITEM_GROUPS';
export const GET_ITEM_ADDON_CATEGORY = 'GET_ITEM_ADDON_CATEGORY';
export const UPDATE_ITEM_ADDON_CATEGORY = 'UPDATE_ITEM_ADDON_CATEGORY';
export const GET_ALL_ITEMS_WITH_STOCK = 'GET_ALL_ITEMS_WITH_STOCK';
export const GET_SINGLE_ITEM_VARIANTS = 'GET_SINGLE_ITEM_VARIANTS';
