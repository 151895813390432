import React from "react";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle } from "../../components/Component";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Link } from "react-router-dom";
import { BsFillArrowRightCircleFill } from 'react-icons/bs';

const Success = () => {
    return (
        <React.Fragment>
            <Head title="Success" />
            <PageContainer>
                <Block className="nk-block-middle nk-auth-body text-center">
                    <BlockHead>
                        <BlockContent>
                            <BlockTitle tag="h4">LOGIN SUCCESSFULL</BlockTitle>
                            <BlockDes className="text-success">
                                <p>You have successfully signed in</p>
                            </BlockDes>
                        </BlockContent>
                        <div className="form-note-s2 text-center pt-4">
                            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                                <strong>Continue <BsFillArrowRightCircleFill size={25}/>  </strong>
                            </Link>
                        </div>
                    </BlockHead>
                </Block>
                <AuthFooter/>
            </PageContainer>
        </React.Fragment>
    );
};
export default Success;
