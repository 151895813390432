import { GET_ALL_COMPLETED_STORE_PAYOUTS, GET_ALL_DELIVERY_COLLECTION, GET_ALL_DELIVERY_COLLECTION_LOGS, GET_ALL_PENDING_STORE_PAYOUTS, GET_SINGLE_USER_DELIVERY_COLLECTION_LOGS } from "./actionType";

const initialState = {
    pending_payouts: [],
    completed_payouts: [],
    delivery_collections: {},
    collection_logs: {},
    single_user_logs: {},

};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_PENDING_STORE_PAYOUTS:
            return { ...state, pending_payouts: action.payload };
        case GET_ALL_COMPLETED_STORE_PAYOUTS:
            return { ...state, completed_payouts: action.payload };
        case GET_ALL_DELIVERY_COLLECTION:
            return { ...state, delivery_collections: action.payload };
        case GET_ALL_DELIVERY_COLLECTION_LOGS:
            return { ...state, collection_logs: action.payload };
        case GET_SINGLE_USER_DELIVERY_COLLECTION_LOGS:
            return { ...state, single_user_logs: action.payload };
        default:
            return state;
    }
}
