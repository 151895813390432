export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_SINGLE_USER = 'GET_SINGLE_USER';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_BANK_UPDATED = 'USER_BANK_UPDATED';
export const USER_ROLE_UPDATED = 'USER_ROLE_UPDATED';
export const GET_ALL_USER_ORDERS = 'GET_ALL_USER_ORDERS';
export const GET_ALL_USER_REFERAL = 'GET_ALL_USER_REFERAL';
export const GET_ALL_USER_TRANSACTION = 'GET_ALL_USER_TRANSACTION';
export const UPDATE_USER_WALLET = 'UPDATE_USER_WALLET';
export const GET_ALL_STORE_OWNER = 'GET_ALL_STORE_OWNER';
export const GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS';
export const GET_ALL_DELIVERY_PARTNERS = 'GET_ALL_DELIVERY_PARTNERS';
export const UPDATE_DELIVERY_PARTNER = 'UPDATE_DELIVERY_PARTNER';
