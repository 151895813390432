import React, { useState, useEffect, useContext, useMemo } from "react";
import Head from "../../../layout/head/Head";
import {
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    UncontrolledDropdown,
    Modal,
    ModalBody,
    DropdownItem,
    Form,
    Spinner,
} from "reactstrap";
import {
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    BlockDes,
    BackTo,
    PreviewCard,
    ReactDataTable,
    BlockBetween,
    Button,
    Icon,
    Col,
    RSelect,
} from "../../../components/Component";
import { DataTableData, dataTableColumns2, userData, deliveryChargeType } from "./cityData";
import Content from "../../../layout/content/Content";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    createCity,
    getAllCities,
    updateCity,
    toggleCity,
    toggleCitySurger,
    getCityUsers,
    updateCityUsers,
} from "../../../redux/admin/cities/actions";
import * as yup from "yup";
import { string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { nonActiveFormSubmissionStatus, openFormLoading } from "../../../redux/common/actions";
import ToastExample from "../../../helpers/toast";

const Cities = () => {
    const getSelectors = (state) => ({
        cities: state.cities.cities,
        city_users: state.cities.city_users,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,
    });

    const { cities, form_loading, form_submission_status, city_users } = useSelector(getSelectors);
    // console.log(cities);
    const dispatch = useDispatch();

    const [sm, updateSm] = useState(false);
    const [modal, setModal] = useState({
        edit: false,
        add: false,
        cityUser: false,
    });
    const [formData, setFormData] = useState([]);
    const [data, setData] = useState([]);
    const [editId, setEditedId] = useState();
    const [showToast, setShowToast] = useState(false);
    const [isDynamic, setIsDynamic] = useState(false);
    const [editingCity, setEditingCity] = useState([]);
    const [userId, setUserId] = useState(null);

    // function to close the form modal
    const onFormCancel = () => {
        setModal({ edit: false, add: false, cityUser: false });
        resetForm();
    };

    const citySchema = yup.object({
        name: string().required(),
        latitude: string().required(),
        longitude: string().required(),
        radius: string().required(),
        delivery_charge_type: string().required(),
        delivery_charge: !isDynamic ? string().required() : string(),
        base_delivery_charge: isDynamic ? string().required() : string(),
        base_delivery_distance: isDynamic ? string().required() : string(),
        extra_delivery_charge: isDynamic ? string().required() : string(),
        extra_delivery_distance: isDynamic ? string().required() : string(),
    });

    const cityUserSchema = yup.object().shape({
        city_assigned_users: ""
    });


    const defaultValues = useMemo(
        () => ({
            id: editingCity?.id || "",
            name: editingCity?.name || "",
            latitude: editingCity?.latitude || "",
            longitude: editingCity?.longitude || "",
            radius: editingCity?.radius || "",
            delivery_charge_type: editingCity?.delivery_charge_type || "",
            delivery_charge: editingCity?.delivery_charge || "",
            base_delivery_charge: editingCity?.base_delivery_charge || "",
            base_delivery_distance: editingCity?.base_delivery_distance || "",
            avatarUrl: editingCity?.avatarUrl || "",
            extra_delivery_charge: editingCity?.extra_delivery_charge,
            extra_delivery_distance: editingCity?.extra_delivery_distance,
        }),
        [editingCity]
    );

    const methods = useForm({
        resolver: modal.add || modal.edit ? yupResolver(citySchema) : yupResolver(cityUserSchema),
        defaultValues: modal.add || modal.edit ? defaultValues : cityUserSchema,
    });

    const {
        register,
        handleSubmit,
        setError,
        watch,
        control,
        formState: { errors },
        unregister,
        reset,
    } = methods;
    const watchDeliveryType = watch("delivery_charge_type");
    const watchCityUsers = watch("city_assigned_users");

    // function to reset the form
    const resetForm = () => {
        setFormData({
            name: "",
            email: "",
            balance: "",
            phone: "",
            status: "Active",
        });
    };

    useEffect(() => {
        dispatch(getAllCities());
    }, []);

    // submit function to add a new item
    const onFormSubmit = (submitData) => {
        dispatch(openFormLoading());

        if (modal.edit) {
            dispatch(updateCity(submitData, editingCity?.id));
        } else if (modal.add) {
            dispatch(createCity(submitData));
        } else if (modal.cityUser) {
            dispatch(updateCityUsers(submitData, editingCity.id))
            console.log(submitData);
        }
    };

    const openAddModal = () => {
        setEditingCity([]);
        dispatch(nonActiveFormSubmissionStatus());
        setTimeout(() => {
            setModal({ add: true });
        }, 100);
    };

    const openEditModal = (city) => {
        setModal({ edit: true });
        dispatch(nonActiveFormSubmissionStatus());
        setEditingCity(city);
    };

    const openCityUserModal = (city) => {
        setUserId(city.id)
        setEditingCity(city);
        dispatch(nonActiveFormSubmissionStatus());
        dispatch(getCityUsers(city.id)).then((response) => {
            if (response && response.payload && response.payload.success) {
                setUserId(null)
                setModal({ cityUser: true });
            } else {
                setUserId(null)
            }
        }).catch((err) => {
            const response = err.response;
            console.log(response, 'response')
        });
    };

    // useEffect which watches the delivery type of edit and create.
    useEffect(() => {
        if (watchDeliveryType == "DYNAMIC") {
            setIsDynamic(true);
        } else {
            setIsDynamic(false);
        }
    }, [watchDeliveryType]);

    // useEffect which watches the formsubmission of edit and create.
    useEffect(() => {
        if (modal.add == true && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllCities());
                setData(cities);
                setModal({ add: false });
                setShowToast(true);
            }, 500);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }

        if (modal.edit == true && editingCity && form_loading == false && form_submission_status == true) {
            dispatch(getAllCities());
            setData(cities);
            setModal({ edit: false });
            setShowToast(true);
            reset(defaultValues);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        } else {
            reset(defaultValues);
        }
    }, [createCity, form_loading, modal, form_submission_status]);

    //turn off city function
    const handleCityStatus = (e, id) => {
        dispatch(toggleCity(id));
        setTimeout(() => {
            dispatch(getAllCities());
            setShowToast(true);
        }, 500);

        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    //turn off city surger function
    const handleCitySurgeStatus = (e, id) => {
        dispatch(toggleCitySurger(id));
        setTimeout(() => {
            dispatch(getAllCities());
            setShowToast(true);
        }, 500);

        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    // useEffect(() => {
    //   console.log(watchCityUsers,'watchCityUsers');
    // }, [watchCityUsers])


    //headers of table
    const cityTableHeadings = [
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Latitude",
            selector: (row) => row.latitude,
            sortable: true,
            hide: 370,
        },
        {
            name: "Longitude",
            selector: (row) => row.longitude,
            sortable: true,
            hide: "sm",
        },
        {
            name: "Delivery Type",
            selector: (row) => row.delivery_charge_type,
            sortable: true,
            hide: "sm",
        },
        {
            name: "Status",
            selector: (row) => row.is_active,
            compact: true,
            style: { paddingRight: "20px" },

            cell: (row) => {
                return (
                    <div className="user-card mt-2 mb-2" id={row.id}>
                        <Button color={row.is_active ? "success" : "danger"} onClick={(e) => handleCityStatus(e, row.id)}>
                            {row.is_active ? <Icon name="stop-circle-fill" /> : <Icon name="play-circle-fill" />}
                        </Button>
                    </div>
                );
            },
        },
        // {
        //     name: "Surge",
        //     selector: (row) => row.is_surge,
        //     compact: true,
        //     style: { paddingRight: "20px" },

        //     cell: (row) => {
        //         return (
        //             <div className="user-card mt-2 mb-2" id={row.id}>
        //                 <Button color={row.is_surge ? "success" : "danger"} onClick={(e) => handleCitySurgeStatus(e, row.id)}>
        //                     {row.is_surge ? <Icon name="stop-circle-fill" /> : <Icon name="play-circle-fill" />}
        //                 </Button>
        //             </div>
        //         );
        //     },
        // },
        {
            name: "City Users",
            selector: (row) => row.is_surge,
            compact: true,
            grow: 1,
            style: { paddingRight: "20px" },
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <Button onClick={() => openCityUserModal(row)} color="primary" className="btn-sm">
                        {userId == row.id ? (
                            <>
                                <Spinner size="sm" color="light" /> View Users
                            </>
                        ) : "View Users"}
                    </Button>
                </div>
            ),
            sortable: false,
        },
        {
            name: "Actions",
            selector: (row) => row.is_surge,
            compact: true,
            grow: 1,
            style: { paddingRight: "20px" },
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <DropdownTrans openEditModal={openEditModal} row={row} />
                </div>
            ),
            sortable: false,
        },
    ];

    const DropdownTrans = ({ row }) => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu right>
                    <ul className="link-list-plain">
                        <li>
                            <DropdownItem tag="a" onClick={() => openEditModal(row)}>
                                <Icon name="pen" />
                                Edit
                            </DropdownItem>
                        </li>
                        <li>
                            <DropdownItem
                                tag="a"
                                onClick={(ev) => ev.preventDefault()}  >
                                <Icon name="trash" />
                                Delete
                            </DropdownItem>
                        </li>
                    </ul>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    const getCityUserDropDown = (users) => {
        const allUsers = users?.map((city) => (
            { value: city.id, label: city.name }
        ));
        return allUsers;
    };

    const getSelectedCityUsers = (city_users) => {
        const allUsers = [];
        const assignedUsers = [];
        let selected = city_users.users.forEach((user) => {
            let selected_user = city_users.userIds.includes(user.id)
            if (selected_user) {
                allUsers.push(user);
            }
        });

        allUsers?.forEach((element) => {
            const dataObj = {
                value: element.id,
                label: element.name,
            };
            assignedUsers.push(dataObj);
        });
        return assignedUsers;
    };

    return (
        <React.Fragment>
            <Head title="Cities"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                City Lists
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {cities?.length} cities.</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <ToastExample show={showToast} />

                                <Button
                                    className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                                    onClick={() => updateSm(!sm)}
                                >
                                    <Icon name="menu-alt-r"></Icon>
                                </Button>
                                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                                    <ul className="nk-block-tools g-3">
                                        <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon" onClick={() => openAddModal()}>
                                                <Icon name="plus"></Icon>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <ReactDataTable
                    data={cities}
                    columns={cityTableHeadings}
                    //  expandableRows
                    pagination
                />

                <Modal
                    isOpen={modal.add || modal.edit}
                    toggle={() => setModal({ add: false })}
                    className="modal-dialog-centered"
                    size="lg"
                >
                    <ModalBody>
                        <a
                            href="#cancel"
                            onClick={(ev) => {
                                ev.preventDefault();
                                onFormCancel();
                            }}
                            className="close"
                        >
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <div className="p-2">
                            <h5 className="title">{modal.edit ? "Editing city - " + editingCity?.name : "Add City"}</h5>
                            <div className="mt-4">
                                <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-label">City Name</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="name"
                                                placeholder="Enter City name"
                                                ref={register({ required: "This field is required" })}
                                            />
                                            {errors.name && <span className="invalid">{errors.name.message}</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-label">Radius</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="radius"
                                                ref={register({
                                                    required: "This field is required",
                                                })}
                                                placeholder="Enter city maximum delivery radius"
                                            />
                                            {errors.radius && <span className="invalid">{errors.radius.message}</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-label">Latitude </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="latitude"
                                                placeholder="Enter City Latitude"
                                                ref={register({
                                                    required: "This field is required",
                                                })}
                                            />
                                            {errors.latitude && <span className="invalid">{errors.latitude.message}</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-label">Longitude</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="longitude"
                                                placeholder="Enter City Longitude"
                                                ref={register({ required: "This field is required" })}
                                            />
                                            {errors.longitude && <span className="invalid">{errors.longitude.message}</span>}
                                        </FormGroup>
                                    </Col>

                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-label">Delivery Charge Type</label>
                                            <div className="form-control-wrap">
                                                <Controller
                                                    name="delivery_charge_type"
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <RSelect
                                                            options={deliveryChargeType}
                                                            onChange={(val) => onChange(val.value)}
                                                            defaultValue={deliveryChargeType.find((c) => c.value === value)}
                                                        />
                                                    )}
                                                    ref={register({ required: "This field is required" })}
                                                />
                                                {errors.delivery_charge_type && (
                                                    <span className="invalid text-danger text-italic">Please choose delivery charge type</span>
                                                )}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {isDynamic ? (
                                        <React.Fragment>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label className="form-label">Base Delivery Charge</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="base_delivery_charge"
                                                        ref={register({
                                                            required: "This field is required",
                                                        })}
                                                    />
                                                    {errors.base_delivery_charge && (
                                                        <span className="invalid">Base Delivery Charge is required</span>
                                                    )}
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                    <label className="form-label">Base Delivery Distance</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="base_delivery_distance"
                                                        ref={register({
                                                            required: "This field is required",
                                                        })}
                                                    />
                                                    {errors.base_delivery_distance && (
                                                        <span className="invalid">Base Delivery Distance is required</span>
                                                    )}
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                    <label className="form-label">Extra Delivery Charge</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="extra_delivery_charge"
                                                        ref={register({
                                                            required: "This field is required",
                                                        })}
                                                    />
                                                    {errors.extra_delivery_charge && (
                                                        <span className="invalid">Extra Delivery Charge is required</span>
                                                    )}
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                    <label className="form-label">Extra Delivery Distance</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="extra_delivery_distance"
                                                        ref={register({
                                                            required: "This field is required",
                                                        })}
                                                    />
                                                    {errors.base_delivery_distance && <span className="invalid">Extra Distance is required</span>}
                                                </FormGroup>
                                            </Col>
                                        </React.Fragment>
                                    ) : (
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Delivery Charge</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="delivery_charge"
                                                    ref={register({
                                                        required: "This field is required",
                                                    })}
                                                />
                                                {errors.delivery_charge && <span className="invalid">Delivery Charge is required</span>}
                                            </FormGroup>
                                        </Col>
                                    )}
                                    <Col size="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button type="submit" color="primary" size="lg" className="btn-block">
                                                    {form_loading ? (
                                                        <>
                                                            <Spinner size="sm" color="light" /> {modal.edit ? "Updating City" : "Creating New City"}
                                                        </>
                                                    ) : (
                                                        <React.Fragment>{modal.edit ? "Update City" : "Add New City"}</React.Fragment>
                                                    )}
                                                </Button>
                                            </li>
                                            <li>
                                                <a href="#cancel"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        onFormCancel();
                                                    }}
                                                    className="link link-light" >
                                                    Cancel
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={modal.cityUser}
                    toggle={() => setModal({ cityUser: false })}
                    className="modal-dialog-centered"
                    size="lg"
                >
                    <ModalBody>
                        <a
                            href="#cancel"
                            onClick={(ev) => {
                                ev.preventDefault();
                                onFormCancel();
                            }}
                            className="close"
                        >
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <div className="p-2">
                            <h5 className="title">{modal.cityUser ? "Managing city users of  - " + editingCity?.name : ""}</h5>
                            <div className="mt-4">
                                <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                                    <Col md="12">
                                        <FormGroup>
                                            <label className="form-label">Choose Users</label>
                                            <div className="form-control-wrap">
                                                <Controller name="city_assigned_users" control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <RSelect
                                                            options={getCityUserDropDown(city_users?.users)}
                                                            isMulti
                                                            onChange={(val) => onChange(val.map((c) => c.value))}
                                                            defaultValue={getSelectedCityUsers(city_users)}
                                                        ></RSelect>
                                                    )}
                                                    ref={register({ required: "This field is required" })}
                                                />
                                                {errors.city_assigned_users && (
                                                    <span className="invalid text-danger text-italic">Please choose delivery charge type</span>
                                                )}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col size="8">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button type="submit" color="primary" size="lg" className="btn-block">
                                                    {form_loading ? (
                                                        <>
                                                            <Spinner size="sm" color="light" /> Updating City Users
                                                        </>
                                                    ) : (
                                                        <React.Fragment> Update City Users</React.Fragment>
                                                    )}
                                                </Button>
                                            </li>
                                            <li>
                                                <a
                                                    href="#cancel"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        onFormCancel();
                                                    }}
                                                    className="link link-light"
                                                >
                                                    Cancel
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
        </React.Fragment>
    );
};

export default Cities;
