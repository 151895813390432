import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Modal, ModalBody, Spinner, UncontrolledDropdown } from 'reactstrap';
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, ReactDataTable, RSelect } from '../../../../components/Component';
import { WEBSITE_BASE_URL } from '../../../../config';
import ToastExample from '../../../../helpers/toast';
import Content from '../../../../layout/content/Content';
import Head from '../../../../layout/head/Head';
import { createBanner, deleteBanner, getAllBanners, toggleBanner, updateBanner } from '../../../../redux/admin/promotion/action';
import { nonActiveFormSubmissionStatus, openFormLoading } from '../../../../redux/common/actions';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { getAllCities } from '../../../../redux/admin/cities/actions';
import { getAllWarehouses } from "../../../../redux/admin/warehouse/action";
import { getAllItems } from "../../../../redux/admin/item/action";
import noimage from '../../../../assets/images/noimage.png'

const Banners = () => {
    const dispatch = useDispatch(); //defining dispatch

    //function to get data from backend
    useEffect(() => {
        dispatch(getAllBanners());
        dispatch(getAllCities());
        dispatch(getAllWarehouses());
        dispatch(getAllItems());
    }, []);

    //to warehouse data from application state to a constant
    const getSelectors = (state) => ({
        banners: state.promotion.banners,
        warehouses: state.warehouse.warehouse,
        items: state.item.items,
        cities: state.cities.cities,
        form_loading: state.common.form_loading,
        form_submission_status: state.common.form_submission_status,

    });
    const { warehouses, items, banners, cities, form_loading, form_submission_status, } = useSelector(getSelectors);

    const [editingBanner, setEditingBanner] = useState([]);   //edit category state
    const [deletingBanner, setDeletingBanner] = useState([]);   //delete category state
    const [formData, setFormData] = useState([]);   //form data state defined
    const [showToast, setShowToast] = useState(false);   //toast state
    const [data, setData] = useState([]);  //data state defined
    const [bannerType, setBannerType] = useState("SINGLE_WAREHOUSE");  //isMultiple state defined
    const [cityId, setCityId] = useState("All");  //data state defined
    const [image, setImage] = useState(null);  // state defined
    const [image2, setImage2] = useState(null);  // state defined
    const [warehouseId, setWarehouseId] = useState();  // state defined
    const [warehouseIds, setWarehouseIds] = useState([]);  // state defined
    const [itemIds, setItemIds] = useState([]);  // state defined

    //modal state
    const [modal, setModal] = useState({
        edit: false,
        add: false,
        delete: false,
    });

    //to open add modal
    const openAddModal = () => {
        setEditingBanner([]);
        setImage(null);
        setImage2(null);
        setCityId("All")
        setWarehouseId(null)
        setWarehouseIds([]);
        setItemIds([]);
        dispatch(nonActiveFormSubmissionStatus());
        setTimeout(() => {
            setModal({ add: true });
        }, 100);
    };

    //to open edit modal
    const openEditModal = (category) => {
        setModal({ edit: true });
        setEditingBanner(category);
        dispatch(nonActiveFormSubmissionStatus());
        setImage(null);
        setImage2(null)
        setCityId(category.city_id)
        if (category.type === "SINGLE_WAREHOUSE") {
            setWarehouseId(category?.warehouses[0]?.id ?? null)
        } else if (category.type === "MULTI_WAREHOUSE") {
            setWarehouseIds(category.warehouses.map((cw) => (cw.id)));
        }
        setItemIds(category.items.map((ci) => (ci.id)));

        setBannerType(category.type)
    };

    //to open delete modal
    const openDeleteModal = (category) => {
        setModal({ delete: true });
        dispatch(nonActiveFormSubmissionStatus());
        setDeletingBanner(category);
    };

    //warehouse lists
    const listCities = cities?.map((city) => (
        { value: city.id, label: city.name }
    ));
    const AllWarehouse = warehouses?.filter(cp => (cityId == "All" ? true : cp.city_id == cityId)).map((warehouse) => (
        { value: warehouse.id, label: warehouse.name }
    ));
    // const AllItems = items && items?.filter(cp => (cityId == "All" ? true : cp.warehouse.city_id == cityId)).map((item) => (
    //     { value: item.id, label: item.name }
    // ));
    const AllItems = items?.filter(cp => (cityId === "All" ? true : cp?.warehouse?.city_id === cityId))?.filter(cp => (
        bannerType === "MULTI_WAREHOUSE" ? warehouseIds?.includes(cp.warehouse_id) : warehouseId === cp.warehouse_id
    ))?.map(item => ({ value: item.id, label: item.name }));

    const types = [
        { value: 'SINGLE_WAREHOUSE', label: 'Single Warehouse' },
        { value: 'MULTI_WAREHOUSE', label: 'Multi Warehouse' },
    ];

    //form validation with yup
    const categorySchema = yup.object({
        name: yup.string().required(),
        latitude: yup.string().required(),
        longitude: yup.string().required(),
        radius: yup.string().required(),
        city_id: yup.string().required(),
        type: yup.string(),
    });

    //form values
    const defaultValues = useMemo(
        () => ({

            id: editingBanner?.id || "",
            name: editingBanner?.name || "",
            latitude: editingBanner?.latitude || "",
            longitude: editingBanner?.longitude || "",
            radius: editingBanner?.radius || "",
            city_id: editingBanner?.city_id || "",
            type: editingBanner?.type || "",
        }),
        [editingBanner]
    );
    //form methods
    const methods = useForm({
        resolver: yupResolver(categorySchema),
        defaultValues: defaultValues,
    });

    //useform methods
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control
    } = methods;

    //submit function
    const onFormSubmit = (submitData) => {
        dispatch(openFormLoading());
        let warehouse_ids = JSON.stringify(warehouseIds);
        let item_ids = JSON.stringify(itemIds);
        const new_data = new FormData();
        new_data.append('id', editingBanner?.id);
        new_data.append('name', submitData?.name);
        new_data.append('token', localStorage.getItem('accessToken'));
        if (image) {
            new_data.append('image', image);
        }
        if (image2) {
            new_data.append('image2', image2);
        }
        new_data.append('radius', submitData?.radius);
        new_data.append('latitude', submitData?.latitude);
        new_data.append('longitude', submitData?.longitude);
        new_data.append('type', bannerType);
        new_data.append('city_id', submitData?.city_id);
        if (bannerType == 'SINGLE_WAREHOUSE') {
            new_data.append('warehouse_id', warehouseId);
        } else if (bannerType == 'MULTI_WAREHOUSE') {
            new_data.append('warehouse_ids', warehouse_ids);
        }
        new_data.append('item_ids', item_ids);
        if (modal.edit) {
            setTimeout(() => {
                dispatch(updateBanner(new_data));
            }, 50);
        } else if (modal.add) {
            dispatch(createBanner(new_data));
        } else if (modal.delete) {
            dispatch(deleteBanner(deletingBanner?.id));
        }
        resetForm();
    };

    // to reset the form
    const resetForm = () => {
        setFormData({
            name: "",
            image: "",
            radius: "",
            latitude: "",
            longitude: "",
            type: "",
            city_id: "",
            warehouse_id: "",
            warehouse_ids: [],
            item_ids: [],
        });
    };

    //to close modal
    const onFormCancel = () => {
        setModal({ edit: false, add: false, delete: false });
        // document.getElementByClass("invalid").innerHTML = "";
        resetForm();
    };

    //category toggle
    const handleCategoryStatus = (e, id) => {
        dispatch(toggleBanner(id));
        setTimeout(() => {
            dispatch(getAllBanners());
            setShowToast(true);
        }, 500);

        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    //submit toast
    useEffect(() => {
        if (modal.add == true && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllBanners());
                setData(banners);
                setModal({ add: false });
                setShowToast(true);
            }, 500);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }

        if (modal.edit == true && editingBanner && form_loading == false && form_submission_status == true) {
            dispatch(getAllBanners());
            setData(banners);
            setModal({ edit: false });
            setShowToast(true);
            reset(defaultValues);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        } else {
            reset(defaultValues);
        }

        if (modal.delete == true && form_loading == false && form_submission_status == true) {
            setTimeout(() => {
                dispatch(getAllBanners());
                setData(banners);
                setModal({ delete: false });
                setShowToast(true);
            }, 500);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    }, [createBanner, form_loading, modal, form_submission_status]);

    //headers of datatable
    const tableHeadings = [
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Image",
            selector: (row) => row.image,
            compact: true,
            style: { paddingRight: "20px" },
            cell: (row) => {
                return (
                    <div className="user-card mt-2 mb-2" id={row.id}>
                        {row?.image ?
                            <img src={WEBSITE_BASE_URL + row.image} alt="item" style={{ width: '6rem', borderRadius: "5px" }} />
                            :
                            <div className='d-flex flex-column align-items-center ml-3'>
                                <img src={noimage} alt="item" style={{ height: '5vh', width: '5vh' }} />
                                <span className='m-1' style={{ color: '#330033', fontSize: '9px' }}>No image found</span>
                            </div>
                        }
                    </div>
                );
            },
        },
        {
            name: "Single Image",
            selector: (row) => row.image2,
            compact: true,
            style: { paddingRight: "20px" },
            cell: (row) => {
                return (
                    <div className="user-card mt-2 mb-2" id={row.id}>
                        {row?.image2 ?
                            <img src={WEBSITE_BASE_URL + row.image2} alt="item" style={{ width: '6rem', borderRadius: "5px" }} />
                            :
                            <div className='d-flex flex-column align-items-center ml-3'>
                                <img src={noimage} alt="item" style={{ height: '5vh', width: '5vh' }} />
                                <span className='m-1' style={{ color: '#330033', fontSize: '9px' }}>No image found</span>
                            </div>
                        }
                    </div>
                );
            },
        },
        {
            name: "Latitude",
            selector: (row) => row.latitude,
            sortable: true,
        },
        {
            name: "Longitude",
            selector: (row) => row.longitude,
            sortable: true,
        },
        {
            name: "Radius",
            selector: (row) => row.radius,
            sortable: true,
        },
        {
            name: "City",
            selector: (row) => row?.city ? row?.city?.name : 'No City Found',
            sortable: true,
        },

        {
            name: "Status",
            selector: (row) => row.is_active,
            compact: true,
            style: { paddingRight: "20px" },

            cell: (row) => {
                return (
                    <div className="user-card mt-2 mb-2" id={row.id}>
                        <Button color={row.is_active ? "success" : "danger"}
                            onClick={(e) => handleCategoryStatus(e, row.id)}
                        >
                            {row.is_active ? <Icon name="stop-circle-fill" /> : <Icon name="play-circle-fill" />}
                        </Button>
                    </div>
                );
            },
        },

        {
            name: "Actions",
            selector: (row) => row.is_surge,
            compact: true,
            grow: 1,
            style: { paddingRight: "20px" },
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <DropdownTrans
                        row={row}
                    />
                </div>
            ),
            sortable: false,
        },
    ];

    //action dropdown
    const DropdownTrans = ({ row }) => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu right>
                    <ul className="link-list-plain">
                        <li>
                            <DropdownItem tag="a" onClick={() => openEditModal(row)}>
                                <Icon name="pen" />Edit
                            </DropdownItem>
                        </li>
                        <li>
                            <DropdownItem tag="a"
                                onClick={() => openDeleteModal(row)}>
                                <Icon name="trash" />Delete
                            </DropdownItem>
                        </li>
                    </ul>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    return (
        <React.Fragment>
            <Head title="Banners"></Head>
            {/* content */}
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Banners
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {banners?.length} banners.</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <ToastExample show={showToast} />
                                <div className="toggle-expand-content">
                                    <ul className="nk-block-tools g-3">
                                        <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon" onClick={() => openAddModal()}  >
                                                <Icon name="plus"></Icon>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <ReactDataTable data={banners} columns={tableHeadings} expandableRows pagination />
            </Content>
            {/* add and edit modal */}
            <Modal isOpen={modal.add || modal.edit} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg"   >
                <ModalBody>
                    <a href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }} className="close"  >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">{modal.edit ? "Editing Banner - " + editingBanner?.name : "Add Banner"}</h5>
                        <div className="mt-4">
                            <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/formData">
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Banner Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            placeholder="Enter Banner Name"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Main Banner Image </label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            name="image"
                                            onChange={(e) => {
                                                setImage(e.target.files[0])
                                            }}
                                        />
                                        {errors.image && <span className="invalid">{errors.image.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Single Banner Image </label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            name="image2"
                                            onChange={(e) => {
                                                setImage2(e.target.files[0])
                                            }}
                                        />
                                        {errors.image && <span className="invalid">{errors.image.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6" style={modal.edit ? { display: 'block' } : { display: 'none' }}>
                                    <FormGroup>
                                        <label className="form-label">Current Main Banner Image </label>
                                        <div className="" id={editingBanner.id}>
                                            {editingBanner?.image ?
                                                <img src={WEBSITE_BASE_URL + editingBanner?.image} alt="brand" style={{ width: '8rem', borderRadius: '5px' }} />
                                                :
                                                <>
                                                    <img src={noimage} alt="brand" style={{ height: '7vh', width: '7vh' }} />
                                                    <span className='m-1' style={{ color: '#330033', fontSize: '9px' }}>No image found</span>
                                                </>
                                            }
                                        </div>
                                    </FormGroup>
                                </Col>

                                <Col md="6" style={modal.edit ? { display: 'block' } : { display: 'none' }}>
                                    <FormGroup>
                                        <label className="form-label">Current Single Banner Image </label>
                                        <div className="" id={editingBanner.id}>
                                            {editingBanner?.image2 ?
                                                <img src={WEBSITE_BASE_URL + editingBanner?.image2} alt="brand" style={{ width: '8rem', borderRadius: '5px' }} />
                                                :
                                                <>
                                                    <img src={noimage} alt="brand" style={{ height: '7vh', width: '7vh' }} />
                                                    <span className='m-1' style={{ color: '#330033', fontSize: '9px' }}>No image found</span>
                                                </>
                                            }
                                        </div>
                                    </FormGroup>
                                </Col>

                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Latitude </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="latitude"
                                            placeholder="Enter City Latitude"
                                            ref={register({
                                                required: "This field is required",
                                            })}
                                        />
                                        {errors.latitude && <span className="invalid">{errors.latitude.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Longitude</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="longitude"
                                            placeholder="Enter City Longitude"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.longitude && <span className="invalid">{errors.longitude.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Radius</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="radius"
                                            ref={register({
                                                required: "This field is required",
                                            })}
                                            placeholder="Enter city maximum delivery radius"
                                        />
                                        {errors.radius && <span className="invalid">{errors.radius.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">City</label>
                                        <div className="form-control-wrap">
                                            <Controller
                                                name="city_id"
                                                control={control}
                                                render={({ onChange, value, ref }) => (
                                                    <RSelect
                                                        options={listCities}
                                                        onChange={(val) => {
                                                            onChange(val.value)
                                                            setCityId(val.value)
                                                            setWarehouseId(null)
                                                            setWarehouseIds([])
                                                            setItemIds([])
                                                        }}
                                                        defaultValue={listCities.find((city) => city.value === value)}
                                                    />
                                                )}
                                                ref={register({ required: "This field is required" })}
                                            />
                                            {errors.city_id && (
                                                <span className="invalid" style={{ color: '#e85347', fontSize: '11px', fontStyle: 'italic' }}>Please choose a Store</span>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Type</label>
                                        <div className="form-control-wrap">
                                            <Controller
                                                name="type"
                                                control={control}
                                                render={({ onChange, value, ref }) => (
                                                    <RSelect
                                                        options={types}
                                                        onChange={(val) => {
                                                            onChange(val.value)
                                                            setBannerType(val.value)
                                                        }}
                                                        defaultValue={types.find((type) => type.value === value)}
                                                    />
                                                )}
                                                ref={register({ required: "This field is required" })}
                                            />
                                            {errors.type && (
                                                <span className="invalid" style={{ color: '#e85347', fontSize: '11px', fontStyle: 'italic' }}>Please choose a Type</span>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="12" style={bannerType == "MULTI_WAREHOUSE" || bannerType == "SINGLE_WAREHOUSE" ? { display: 'block' } : { display: 'none' }}>
                                    <FormGroup>
                                        <label className="form-label">Slider Applicable Warehouse :</label>
                                        {bannerType == "MULTI_WAREHOUSE" ? (
                                            <div className="form-control-wrap">
                                                <Controller
                                                    name="warehouse_ids"
                                                    control={control}
                                                    render={({ onChange, value }) => (
                                                        <RSelect
                                                            options={AllWarehouse}
                                                            isMulti
                                                            onChange={(val) => {
                                                                const selectedIds = val.map(option => option.value);
                                                                setWarehouseIds(selectedIds);
                                                                onChange(selectedIds);
                                                            }}
                                                            placeholder="Select Warehouse"
                                                            value={AllWarehouse.filter(warehouse => warehouseIds?.includes(warehouse?.value))}
                                                            defaultValue={AllWarehouse.filter(warehouse => warehouseIds?.includes(warehouse?.value))}
                                                        ></RSelect>
                                                    )}
                                                />
                                            </div>
                                        ) : bannerType == "SINGLE_WAREHOUSE" ? (
                                            <div className="form-control-wrap">
                                                <Controller
                                                    name="warehouse_id"
                                                    control={control}
                                                    render={({ onChange, value }) => (
                                                        <RSelect
                                                            options={AllWarehouse}
                                                            onChange={(val) => {
                                                                onChange(val.value)
                                                                setWarehouseId(val.value)
                                                            }}
                                                            placeholder="Select Warehouse"
                                                            value={AllWarehouse.filter(warehouse => warehouse?.value === warehouseId)}
                                                            defaultValue={AllWarehouse.filter(warehouse => warehouse?.value === warehouseId)}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        ) : ""}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Slider Applicable Item :</label>
                                        <div className="form-control-wrap">
                                            <Controller
                                                name="item_ids"
                                                control={control}
                                                render={({ onChange, value }) => (
                                                    <RSelect
                                                        options={AllItems}
                                                        isMulti
                                                        onChange={(val) => {
                                                            const selectedIds = val.map(option => option.value);
                                                            setItemIds(selectedIds);
                                                            onChange(selectedIds);
                                                        }}
                                                        placeholder="Select Item"
                                                        value={AllItems?.filter(item => itemIds?.includes(item?.value))}
                                                        defaultValue={AllItems?.filter(item => itemIds?.includes(item?.value))}
                                                    ></RSelect>
                                                )}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button type="submit" color="primary" size="lg" className="btn-block">
                                                {form_loading ? (
                                                    <>
                                                        <Spinner size="sm" color="light" /> {modal.edit ? "Updating Banner" : "Creating New Banner"}
                                                    </>
                                                ) : (
                                                    <React.Fragment>{modal.edit ? "Update Banner" : "Add New Banner"}</React.Fragment>
                                                )}
                                            </Button>
                                        </li>
                                        <li>
                                            <a href="#cancel" onClick={(ev) => {
                                                ev.preventDefault();
                                                onFormCancel();
                                            }}
                                                className="link link-light"   >
                                                Cancel
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* delete modal */}
            <Modal
                isOpen={modal.delete}
                toggle={() => setModal({ delete: false })}
                className="modal-dialog-centered"
            // size="lg"
            >
                <ModalBody className='pt-3 pb-2'>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Delete Banner{' '}
                            <Badge color="primary">
                                {deletingBanner?.name}
                            </Badge>
                            {' '}
                            ?
                        </h5>
                        <div className='mt-2'>
                            <span>This Item category will be deleted. Do you really want to delete this category ?</span>
                        </div>
                        <div className="mt-3">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4">
                                <li>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        onFormSubmit();
                                    }} color="danger" className="px-4 btn-block">
                                        {form_loading ? (
                                            <>
                                                <Spinner size="sm" color="light" />Deleting
                                            </>
                                        ) : (
                                            <React.Fragment>Delete</React.Fragment>
                                        )}
                                    </Button>
                                </li>
                                <li>
                                    <a
                                        href="#cancel"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            onFormCancel();
                                        }}
                                        className="link link-light"
                                    >
                                        Cancel
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default Banners
