import { GET_DASHBOARD_DATA_URL } from "../../../config";
import axiosClient from "../../../helpers/axios-client";
import { GET_DASHBOARD_DATA } from "./actionType";

//recent orders
export const getDashboardData = (from, to) => (dispatch) => {
    return axiosClient
        .post(GET_DASHBOARD_DATA_URL, { from, to })
        .then((response) => {
            if (response && response.data.success) {
                const dashboard = response.data;
                return dispatch({ type: GET_DASHBOARD_DATA, payload: dashboard });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};
